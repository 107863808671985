import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "booleanFormat" })
export class BooleanFormatPipe implements PipeTransform {
	transform(value: any): string {
		let returnValue: string;
		if (typeof value === "boolean") {
			value ? (returnValue = "Yes") : (returnValue = "No");
			return returnValue;
		} else {
			return value;
		}
	}
}
