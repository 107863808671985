import { Component, OnInit } from "@angular/core";
import { User, UserManagementService } from "../../login/user-management.service";
import { SavedBullListDTO } from "../bull-list.model";
import { ListShareService } from "./list-share.service";
import { IShareUser } from "../list-share/list-share.service";
import { NgForage } from "ngforage";
import { Alert } from "../../utils/alerts/alert.component";
import { AlertService } from "../../utils/alerts/alert.service";
import { BullListRecallService } from "../bull-list-recall.service";
import { AuthService } from "../../auth/auth.service";
@Component({
	selector: "list-share-page",
	templateUrl: "./list-share-page.component.html",
	styleUrls: ["./list-share-page.component.scss"],
})
export class ListSharePageComponent implements OnInit {
	listToShare: SavedBullListDTO;
	listOfUserSavedLists: SavedBullListDTO[] = [];
	user: User;
	personToShareWith: User;

	private userList: IShareUser[] = [];
	displayUserList: IShareUser[] = [];
	constructor(
		public listShareService: ListShareService,
		public userManagementService: UserManagementService,
		private readonly ngf: NgForage,
		public alert: AlertService,
		public recall: BullListRecallService,
		public authService: AuthService
	) {}
	async ngOnInit() {
		this.user = this.authService.currentUser;
		this.listOfUserSavedLists = this.user.CustomLists.UserDefined.filter((list) => !list.IsShared).sort((a, b) => a.Name.localeCompare(b.Name));
		this.userList = await this.listShareService.getUsersToShareWith();
	}
	dismissModal() {
		this.listShareService.openListSharing.next(false);
	}

	/**
	 * Resets the list of users with whom this list is shared.
	 * Sends the update request to the API.
	 * Saves the user gback to IndexDb.
	 */
	async shareList() {
		try {
			let listOfUsers = this.displayUserList.filter((userToFilter) => userToFilter.ShareWith === true).map((user) => user.Id);
			await this.listShareService.shareListWithUsers(this.listToShare.Id, listOfUsers);
			this.user.CustomLists.UserDefined = this.user.CustomLists.UserDefined.map(list => {
				if (list.Id === this.listToShare.Id)
					list.SharedUsers = listOfUsers;
				return list;
			} );
			this.ngf.setItem("BABUser", this.user);
			this.dismissModal();
			this.alert.alerts.next({ message: "Your updates were successful" });
		} catch {
			this.alert.alerts.next({ message: "An error has occurred" });
		}
	}
	populateSharedWith() {
		let fullNameOfUser = this.user.FullName;

		this.displayUserList = this.userList;
		// setting all checkboxes to blank
		this.displayUserList.forEach((user) => (user.ShareWith = false));
		//removing user from the list to share with so they cannot share with themselves
		this.displayUserList = this.displayUserList.filter((user) => {
			let fullName = user.FirstName + " " + user.LastName;
			return fullName !== fullNameOfUser;
		});
		//Using the SharedOwners property of the list to populate the checkboxes with check marks.
		this.displayUserList.forEach((user) => {
			if (this.listToShare.SharedOwners) {
				if (this.listToShare.SharedOwners.includes(user.Id.toString())) {
					user.ShareWith = true;
				}
			} else {
				if (this.listToShare.SharedUsers.includes(user.Id.toString())) {
					user.ShareWith = true;
				}
			}
		});
	}
}
