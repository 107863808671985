import { Injectable } from '@angular/core';
import { NgForage } from "ngforage";

@Injectable({providedIn: 'root'})
export class AppService {
	constructor(
		private ngForage: NgForage
	) {}


	/**
	 * A globally accessible method to return the system defined templates list, wrapped in a promise.
	 * @suppressHidden A boolean switch to show or hide the hidden templates, in 99% of scenarios this is true.
	 * @returns An array of BullListView objects.
	 */
	public async GetSystemTemplates(suppressHidden: boolean = true) {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].TemplateViewSource.filter(tvs => {
				return (suppressHidden ? !tvs.Hidden : true);
			} ).map(tvs => {
				tvs.IsGvc = tvs.Endpoint.includes("gvc/");
				return tvs;
			});
		});
	}


	/**
	 * A globally accessible method to return any system defined templates for the provided name, wrapped in a promise.
	 * @returns A system template object, BullListView.
	 */
	public async GetSystemTemplate(templateIdentifier: string) {
		return await this.GetSystemTemplates(false).then(sysTemplates => {
			// Fetch the system list matching our request name.
			return sysTemplates.filter(st => { return st.Name == templateIdentifier || st.Id == templateIdentifier || st.Title == templateIdentifier })[0];
		});
	}


	/**
	 * A globally accessible method to return the Proof metadata, wrapped in a promise.
	 * @returns The Proof object wrapped.
	 */
	public async GetProofMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].Proof;
		});
	}


	/**
	 * A globally accessible method to return the Lactation metadata, wrapped in a promise.
	 * @returns The Lactation object wrapped.
	 */
	public async GetLactationMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].Lactation;
		});
	}


	/**
	 * A globally accessible method to return the Product Line metadata, wrapped in a promise.
	 * @returns The Product Line meta object wrapped.
	 */
	public async GetProductLineMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].ProductLine;
		});
	}


	/**
	 * A globally accessible method to return the Health Test metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetHealthTestMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].HealthTest;
		});
	}


	/**
	 * A globally accessible method to return the Health Test metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetAgritechMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].Agritech;
		});
	}


	/**
	 * A globally accessible method to return the Animal Meta metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetDesignationMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].Designation;
		});
	}


	/**
	 * A globally accessible method to return the Animal Production Status (barn movement) metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetProdStatMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].ProductionStatus;
		});
	}


	/**
	 * A globally accessible method to return the Genotype metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetGenotypeMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].Genotype;
		});
	}


	/**
	 * A globally accessible method to return the Animal Base metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetAnimalBaseMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].AnimalBase;
		});
	}


	/**
	 * A globally accessible method to return the Animal Meta metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetAnimalMetaMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].AnimalMeta;
		});
	}


	/**
	 * A globally accessible method to return the Country metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetCountryMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].Country;
		});
	}


	/**
	 * A globally accessible method to return the Marketing Product Logo metadata, wrapped in a promise.
	 * @returns The Meta object wrapped.
	 */
	public async GetProductLogoMetadata() {
		return await this.ngForage.getItem("Meta").then(metaObj => {
			return metaObj["metaObj"].ProductLogo;
		});
	}

}