import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterName' })

export class FilterNamePipe implements PipeTransform {
    transform(value: string): string {

            // if(filter[1].condition1){
        
            // }
            // if(filter[1].condition2)
            switch(value) {
                case "greaterThan":
                    return ">";
                case "equals":
                    return"="
                case "lessThan":
                    return "<"
                case "greaterThanOrEqual":
                    return "=>"
                case "lessThanOrEqual":
                    return "<="
                case "notEqual":
                    return "<>"
            }
        
    }
}




