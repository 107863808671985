import { Injectable } from "@angular/core";
import { IAlert } from "./alert.component";
import { Subject } from "rxjs";
@Injectable({
	providedIn: "root",
})
export class AlertService {
	public alerts: Subject<IAlert> = new Subject();

	sendAlert(message: string, header?: string, subTitle?: string) {
		this.alerts.next({
			message: message,
			header: header,
			subTitle: subTitle,
		});
	}
}
