import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserManagementService, UserPayload, User } from "../login/user-management.service";
import { SavedBullListDTO, BullListView, DeleteBullListDTO } from "../bull-list/bull-list.model";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForage } from "ngforage";
import { BullListRecallService } from "../bull-list/bull-list-recall.service";
import { BullService } from "../bull/bull.service";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { AlertService } from "../utils/alerts/alert.service";

import { AuthService } from "../auth/auth.service";
import { AppService } from "../app.service";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
	public user: User;
	public lists: SavedBullListDTO[];
	public userDefinedLists: SavedBullListDTO[];
	public systemDefinedLists: BullListView[];
	public isDefaultList: boolean;

	constructor(
		public authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private ngf: NgForage,
		public recall: BullListRecallService,
		private bullService: BullService,
		private progress: ProgressBarService,
		private alert: AlertService,
		private appService: AppService
	) {}
	ngOnDestroy() {}
	async ngOnInit() {

		this.route.data.subscribe(async (data: { user: User }) => {
			this.user = data.user;

			this.lists = this.user.CustomLists.UserDefined;

			// isolates the users saved lists for populating Home page Saved Lists
			this.userDefinedLists = this.user.CustomLists.UserDefined;

			this.userDefinedLists.sort((a, b) => {
				return a.Name.localeCompare(b.Name);
			});

			// change to Title instead of Name
			//populates the System Defined List dropdown
			this.systemDefinedLists = await this.appService.GetSystemTemplates(true);
			// populates templates in the List Builder
			this.recall.listOfAvailableViews = this.systemDefinedLists;
		});
	}
	// used to get rid of lists with a "blank" name that occurred early on. This can probably be depreciated.

	/**
	* Fires when selecting a saved list or template from the Home page.
	* Updated to always clear currentTemplateId and currentTemplateTitle before assignment.
	**/
	navigateToList(listName: string, isCustomList: boolean = false) {
		try {
			// Clear these as to not confuse the resolver service.
			this.recall.currentTemplateId = null;
			this.recall.currentTemplateTitle = null;
			this.router.navigateByUrl(`bulls`);

			// From the bull-list-resolver service, we derive the user-defined custom list object by the saved title.
			if (isCustomList) {
				this.recall.currentTemplateTitle = listName;
				return;
			}
			this.recall.currentTemplateId = listName;
		} catch (error) {
			this.alert.alerts.next({ message: `Something Went Wrong: ${error}` });
			this.progress.loading.next({ load: false, source: "Navigate to list Home Page end" });
		}
	}

	/**
	 * Fires when deleting a saved list from the Home Page
	 * Fetch the user from storage.
	 * Pop the deleted list from the user.
	 * Save user back to storage.
	 * Create a deep copy of the user object.
	 * Purge lists and assign only the single list to be saved.
	 * Push copy of user to the API.
	 * Note: Modified operation to use the Id, not the list name, as an identifier.
	 * @param listName The name of the list.
	**/
	async deleteList(listId: string) {
		let user: User = await this.authService.getUserFromStorage("deleting list");

		// Copy user object
		let userCopy: User = JSON.parse(JSON.stringify(user));

		for (let list of user.CustomLists.UserDefined.filter((DTO) => DTO.Id === listId)) {
			let indexOfList = user.CustomLists.UserDefined.indexOf(list);

			// Remove this list from the user
			userCopy.CustomLists.UserDefined = user.CustomLists.UserDefined.splice(indexOfList, 1);
			try {
				let serverResponse = await this.recall.deleteListFromServer(list, userCopy);
			} catch (error) {
				console.error(error);
				this.alert.alerts.next({ message: "Something went wrong trying to delete your list. Please try again or contact IT." });
			}
		}
		// Update user object and current list of lists, saving the original user object minus the deleted list.
		this.userDefinedLists = user.CustomLists.UserDefined;
		await this.ngf.setItem("BABUser", user);
	}
}
