import { Injectable } from "@angular/core";
import { HTMLInputEvent } from "../bull-list.model";

import { BullService } from "../../bull/bull.service";
import { Bull } from "../../bull/bull.model";
import { PriceService } from "../../price/price.service";

import * as XLSX from "xlsx";

import { ImportedBullsDTO, IExtraBull } from "../bull-list.model";
import { BullListRecallService } from "../bull-list-recall.service";
import { ProgressBarService } from "../../utils/loading/progress-bar.service";
import { UserManagementService } from "../../login/user-management.service";
import { Subject } from "rxjs";
import { AlertService } from "../../utils/alerts/alert.service";
import { AuthService } from "../../auth/auth.service";
import { GvcType } from "../../domain/gvc/gvc-type.model";
import { AppService } from "../../app.service";
import { NaabCodeIndexBull } from "../bull-form/bull-form-data.service";

@Injectable({
	providedIn: "root",
})
export class BullImportService {
	public importedBullsDTO: ImportedBullsDTO;
	public currentImportedFileName: string = "";
	public file: File;
	public productFilters: string[] = [];
	public enableNext: boolean = false;
	public fileList: any;
	public stagingImportedData: any;
	public importedBullList: Bull[];
	public idColumnName: string = "";
	public naabCodeSearchArray: string[] = [];
	public importing: Subject<boolean> = new Subject();
	public importedBullsCount: number;
	public importFailureArray: string[] = [];
	public currentList: string;
	public checkForRegID: RegExp = /^([A-Za-z]{2}){1}([0-9]{15}){1}$/;
	public checkForNaabCode: RegExp = /^([0-9]*){1}([A-Za-z]{2}){1}([0-9]{5}){1}$/;
	public possibleNaabSpellings: string[] = ["naab", "naabcode", "naabs", "naabcodes", "uid", "codeno", "codenos", "no.", "no", "naab code", "naab codes", "id"];
	public gvcReportTypes = GvcType;

	constructor(
		private bullServiceApI: BullService,
		private priceServiceAPI: PriceService,
		private recall: BullListRecallService,
		private progress: ProgressBarService,
		public authService: AuthService,
		private alert: AlertService,
		private appService: AppService
	) {
		this.importedBullsDTO = {
			bulls: [],
		};
	}

	openImportMessage() {
		if (this.importFailureArray.length > 0) {
			let mess: string = "";
			this.importFailureArray.forEach((id) => {
				id = id + ",\n";
				mess = mess + id;
			});
			this.alert.alerts.next({
				header: `Imported ${this.importedBullsCount} Bulls`,
				subTitle: `Failed to Import ${this.importFailureArray.length} Bulls:`,
				message: mess,
			});
		} else {
			this.alert.alerts.next({ message: `All Bulls Imported Successfully` });
		}
	}

	async addFile(event: HTMLInputEvent) {
		this.progress.loading.next({ load: true, source: "adding import file start" });
		let file = event.target.files[0];
		this.currentImportedFileName = file.name;
		let fileReader = new FileReader();
		fileReader.readAsArrayBuffer(file);
		fileReader.onload = async (e) => {
			let arrayBuffer: any = fileReader.result;
			let data = new Uint8Array(arrayBuffer);
			let arr = new Array();
			for (let i = 0; i != data.length; ++i) {
				arr[i] = String.fromCharCode(data[i]);
			}
			let bstr = arr.join("");
			let workbook = XLSX.read(bstr, { type: "binary" });
			let firstSheetName = workbook.SheetNames[0];
			let worksheet = workbook.Sheets[firstSheetName];
			let promisedData = new Promise((resolve, reject) => {
				resolve(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
			});
			this.stagingImportedData = await promisedData;
		};
		fileReader.onloadend = async (e) => {
			this.progress.loading.next({ load: false, source: "adding import file end" });

			setTimeout(() => {
				this.enableNext = true;
			}, 500);
		};
	}

	async importExternalData(bullList?: object[]) {
		this.progress.loading.next({ load: true, source: "adding import data start" });
		this.naabCodeSearchArray = [];
		this.importedBullsDTO = {
			bulls: [],
		};
		this.importedBullsCount = 0;
		let importCount = 0;
		let importFailure: string[] = [];
		let user = await this.authService.getUserFromStorage();
		let importedDataArray: object[] = bullList;
		let globalIdArrayRequest: string[] = [];
		this.productFilters = [];
		for (let { bull, index } of importedDataArray.map((bull, index) => ({ index, bull }))) {
			for (let [key, value] of Object.entries(bull)) {
				if (key.toLowerCase().trim() === this.idColumnName.toLowerCase().trim()) {
					if (this.recall.currentTemplateTitle.includes("GVC") && value.match(/^([0-9]+)([a-zA-Z]{1,2})([0-9]{1,5})$/)) {
						value = value
							.replace(/^([0]*)/, "")
							.toUpperCase()
							.trim();
						this.productFilters.push(value);
					}
					globalIdArrayRequest.push(value);
				}
			}
		}
		let globalIdArrayResponse: { gids: NaabCodeIndexBull[]; gidErrors: string[] } = await this.bullServiceApI.getAnimalGlobalIds(globalIdArrayRequest);
		if (globalIdArrayResponse.gidErrors) {
			globalIdArrayResponse.gidErrors.forEach((gidError) => {
				importFailure.push(gidError);
			});
		}
		for (let { bull, index } of importedDataArray.map((bull, index) => ({ index, bull }))) {
			let importedBull: IExtraBull = {
				importBull: true,
				bullId: "",
				columns: [],
			};
			// need to add an indicator to tell me which column was used as the identifiers
			for (let [key, value] of Object.entries(bull)) {
				importedBull.columns.push({
					importColumn: false,
					columnName: key,
					columnValue: value,
					columnType: "",
					identifierColumn: false,
				});

				if (key.toLowerCase().trim() === this.idColumnName.toLowerCase().trim()) {
					value = value
						.replace(/^([0]*)/, "")
						.toUpperCase()
						.trim();

					let keyColumn = importedBull.columns.filter((col) => col.columnName.toLowerCase().trim() === this.idColumnName.toLowerCase().trim())[0];
					keyColumn.identifierColumn = true;
					keyColumn.columnValue = value
						.replace(/^([0]*)/, "")
						.toUpperCase()
						.trim();

					// Now validates against NaabCodeIndexBull objects.
					globalIdArrayResponse.gids.forEach((gid) => {
						if (gid.Id === value || gid.NaabCodes.includes(value) || gid.RegId === value || gid.Identifier === value) {
							importedBull.bullId = gid.Id;
							if (!this.naabCodeSearchArray.includes(gid.Id)) {
								this.naabCodeSearchArray.push(gid.Id);
							}
							this.importedBullsDTO.bulls.push(importedBull);
							importCount++;
						}
					});
				}
			}
		}

		// TODO: Refactor this crazy contitional block.
		if (this.naabCodeSearchArray.length > 0) {
			this.recall.importedDataDTO = this.importedBullsDTO;

			if (this.recall.currentTemplateId === "HomeGrownList") {
				this.naabCodeSearchArray.forEach((naab) => {
					if (!this.recall.homeGrownIds.includes(naab)) {
						this.recall.homeGrownIds.push(naab);
					}
				});
				await this.bullServiceApI.homeGrownList(this.recall.currentMixins, this.recall.homeGrownIds);
			}
			else if ( this.recall.currentTemplateTitle.includes( "GVC" ) ) {
				const selectedGvc = await this.appService.GetSystemTemplate(this.recall.currentTemplateTitle);
				if (this.productFilters.length > 0)
					await this.bullServiceApI.retrieveGVCWithExtraIds(this.naabCodeSearchArray, selectedGvc.Name, this.productFilters).then((resp) => (this.recall.currentGVCList = resp.report));
				else
					await this.bullServiceApI.retrieveGVCWithExtraIds(this.naabCodeSearchArray, selectedGvc.Name).then((resp) => (this.recall.currentGVCList = resp.report));
			}
			else
				await this.bullServiceApI.createBullList(this.recall.currentMixins, this.recall.currentTemplateId, this.naabCodeSearchArray);

		} else {
			this.recall.importedDataDTO = this.importedBullsDTO;
			if (this.recall.currentTemplateId === "HomeGrownList")
				await this.bullServiceApI.homeGrownList(this.recall.currentMixins);

			else if (this.recall.currentTemplateTitle.includes( "GVC" ) ) {
				const selectedGvc = await this.appService.GetSystemTemplate(this.recall.currentTemplateTitle);
				await this.bullServiceApI.retrieveGVC(selectedGvc.Name).then((resp) => (this.recall.currentGVCList = resp.report));
			}
			else
				await this.bullServiceApI.createBullList(this.recall.currentMixins, this.recall.currentTemplateId);

		}
		this.importedBullsCount = importCount;
		this.importFailureArray = importFailure;
		this.progress.loading.next({ load: false, source: "adding import data end" });
		setTimeout(() => {
			this.openImportMessage();
		}, 500);
	}
}
