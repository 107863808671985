import { Pipe, PipeTransform } from "@angular/core";
import { Trait } from "../../bull-list/bull-form/bull-form.model";

@Pipe({ name: "traitValue" })
export class TraitValueFormatPipe implements PipeTransform {
	transform(trait: Trait) {
		if (trait.Label && trait.Label.match(/\$$|\(\$\)/)) {
			return `$ ${trait.Value}`;
		}
		if (trait.ValueRel) {
			return `${trait.Value} / ${trait.ValueRel}`;
		} else {
			return `${trait.Value}`;
		}
	}
}
