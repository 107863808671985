import { Component, OnDestroy } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { ProgressBarService } from "./progress-bar.service";
import { Subscription } from "rxjs";

@Component({
	selector: "loading",
	templateUrl: "loading.component.html",
	styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnDestroy {
	public progressSub: Subscription;
	public loaded: boolean = false;
	constructor(public loadingController: LoadingController, private progress: ProgressBarService) {
		this.progressSub = this.progress.loading.subscribe(({ load, source, message }) => {
			if (load === true) {
				this.loaded = true;
				this.presentLoading(message);
			}
			if (!load) {
				if (this.loaded) {
					this.loaded = false;
					loadingController.dismiss();
				}
			}
		});
	}

	async presentLoading(message?: string) {
		let displayMessage = message;
		if (!displayMessage) {
			displayMessage = "Please wait...";
		}
		const loading = await this.loadingController.create({
			// cssClass: 'my-custom-class',
			message: displayMessage,
			backdropDismiss: true
		});
		await loading.present();
	}
	ngOnDestroy() {
		this.progressSub.unsubscribe();
	}
}
