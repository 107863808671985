import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "dateFormat" })
export class DateFormatPipe implements PipeTransform {
	transform(value: string | Date): string | Date {
		if (value === "" || value === undefined) {
			return value;
		} else {
			return new Date(value).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
		}
	}
}
