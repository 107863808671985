import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { UserManagementService } from "../../login/user-management.service";
import { BullFormDataService } from "../../bull-list/bull-form/bull-form-data.service";
import { DomSanitizer } from "@angular/platform-browser";

//MODAL COMPONENT
@Component({
	selector: "success-modal-page",
	templateUrl: "./success-modal-page.component.html",
	styleUrls: ["./success-modal-page.component.scss"],
})
export class SuccessModalPage implements OnInit {
	constructor(private modalController: ModalController, public http: HttpClient, public userManager: UserManagementService, public formData: BullFormDataService, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		setTimeout(() => {
			this.formData.openDocumentModal.next("");
		}, 1500);
	}

}
