import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
	selector: "[dragDrop]",
})
export class DragDropDirective {
	@HostBinding("class.fileover") fileOver: boolean;
	@Output() filesUploaded: EventEmitter<any> = new EventEmitter();
	constructor() {}
	@HostListener("dragover", ["$event"]) onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.fileOver = true;
	}
	@HostListener("dragleave", ["$event"]) onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.fileOver = false;
	}
	@HostListener("drop", ["$event"]) onDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.fileOver = false;
		const files = evt.dataTransfer.files;
		if (files.length > 0) {
			this.filesUploaded.emit(files);
		}
	}
}
