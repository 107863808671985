import { Injectable, OnDestroy } from "@angular/core";
import { NgForage } from "ngforage";
import { User, CustomList } from "../login/user-management.service";
import { ActivatedRoute, Router, UrlTree } from "@angular/router";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { API_URL, ENDPOINT } from "../../environments/environment";
import { ProgressBarService } from "../utils/loading/progress-bar.service";

import { AlertService } from "../utils/alerts/alert.service";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class AuthService implements OnDestroy {
	public loggedIn: boolean = false;
	public user: BehaviorSubject<User> = new BehaviorSubject({});
	public userListsUpdated: Subject<User> = new Subject<User>();
	public currentUser: User;
	public userSub: Subscription;
	public intendedUrl: string | UrlTree;
	constructor(private ngf: NgForage, private alert: AlertService, private route: ActivatedRoute, private router: Router, private http: HttpClient, public progress: ProgressBarService) {
		this.userSub = this.user.subscribe((currentUserObs: User) => {
			this.currentUser = currentUserObs;
		});
	}
	ngOnDestroy() {
		this.userSub.unsubscribe();
	}
	getCurrentUser(): User {
		return this.user.value;
	}
	async getUserFromStorage(source?: string) {
		let keys = await this.ngf.keys();
		let foundUserObject: boolean = false;
		try {
			if (keys.length > 0) {
				for (let user of keys) {
					if ((user = "BABUser")) {
						foundUserObject = true;

						let payload: User = await this.ngf.getItem(`${user}`);
						this.user.next(payload);

						return payload;
					}
				}
				if (!foundUserObject) {
					let payload: User = null;

					// this.alert.alerts.next({ message: `No User In Storage` });
					return payload;
				}
			}
		} catch (error) {
			this.alert.alerts.next({ message: `Something Went Wrong Accessing Your Stored User Object : ${error}` });
		}
	}
	async isLoggedIn() {
		let user: User = await this.getUserFromStorage("auth service");
		if (user)
			return true;

		this.router.navigateByUrl("login");
		return false;
	}

	/**
	 * Custom lists is now a separate call and not included with the "Login" nor "Validatetoken" end-points.
	 * Attempt to grab the user out of indexDb, update it with the payload from the userSavedLists end point, then save the user.
	 * I wanted to do this as an observable that self-subscribes, but all the awaits all over this app made that impossible.
	 * @user The User object to which the lists will be applied (and saved back to IndexDb).
	**/
	applyUserSavedLists(user: User) {
		
		user["CustomLists"] = {};
		user.CustomLists["UserDefined"] = [];
		
		this.http.get<CustomList>(API_URL + ENDPOINT.userSavedLists).pipe(
			map((lists: CustomList) => { 
				user.CustomLists.UserDefined = lists.UserDefined;
				this.saveUser(user);
			})
		).subscribe();
	}

	private saveUser(user: User) {
		this.ngf.setItem("BABUser", user)
			.then(user => {
				this.userListsUpdated.next(user);
			})
			.catch(err => {
				console.error(err);
			});
	}


	login(username: string, password: string) {
		let body = {
			password: password,
			username: username,
		};

		return this.http.post<User>(API_URL + ENDPOINT.login, body);
	}


	async logout() {
		let user = localStorage.getItem("user");
		this.loggedIn = false;
		let forageKeys = await this.ngf.keys();

		if (forageKeys.includes(`${user}`)) {
			await this.ngf.removeItem(`${user}`);
		}
		this.ngf.removeItem("BABUser");
		localStorage.removeItem("token");
		localStorage.removeItem("APIKey");
		localStorage.removeItem("user");
		this.router.navigateByUrl("login");
	}


	async getUser() {
		await this.http
			.get<{ User: User }>(API_URL + ENDPOINT.validateToken)
			.toPromise()
			.then(async (value) => {
				await this.ngf.setItem("BABUser", value.User);
			});
	}
}
