import { Component, OnInit } from "@angular/core";
import { Platform, MenuController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { ActivatedRoute, Router } from "@angular/router";
import { UserManagementService, User, UserPayload } from "./user-management.service";
import { NgForage, Driver, NgForageCache, CachedItem } from "ngforage";
import { AlertService } from "../utils/alerts/alert.service";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { BullService } from "../bull/bull.service";
import { BullFormDataService } from "../bull-list/bull-form/bull-form-data.service";
import { AuthService } from "../auth/auth.service";

@Component({
	selector: "app-main-page",
	templateUrl: "./login-page.component.html",
	styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
	public selectedIndex = 0;
	public userEmail: string = "";
	public userPass: string = "";
	public menuToggled: boolean = false;
	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private bullService: BullService,
		private bullFormDataService: BullFormDataService,
		public userManager: UserManagementService,
		private router: Router,
		private readonly ngf: NgForage,
		public authService: AuthService,
		private progress: ProgressBarService,
		private alert: AlertService
	) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		});
	}

	async validateCredentials() {
		this.progress.loading.next({ load: true, source: "validateUserCreds from userManager start" });
		if (this.userEmail !== "" && this.userPass !== "") {
			this.authService.login(this.userEmail, this.userPass).subscribe(
				(user) => {

					let userListUpdated$ = this.authService.userListsUpdated;

					localStorage.setItem("token", user.AccessToken);
					localStorage.setItem("user", user.Email);
					localStorage.setItem("APIKey", user.ApiKey);

					this.authService.loggedIn = true;
					
					this.authService.applyUserSavedLists(user);

					userListUpdated$.subscribe(async (userLists) => {

						await this.bullFormDataService.getNaabCodeIndex();
						await this.bullService.getMetaObject();

						this.router.navigateByUrl("home").then((loaded: boolean) => {
							if(loaded) {
								this.progress.loading.next({ load: false, source: "validateUserCreds from userManager end" });
							}
						}).catch((err) => console.error(err));
					});
					
				},
				(err) => {

					if (err.status != 503) {
						setTimeout(() => {
							this.progress.loading.next({ load: false, source: "validateUserCreds from userManager end" });
						}, 1000);
						this.alert.alerts.next({ message: `You have reached an unexpected error please contact IT! ${err.message}` });
					} else {
						this.progress.loading.next({ load: false });
						this.alert.alerts.next({ message: `${err.error.Message}` });
					}
				}
			);
		} else {
			setTimeout(() => {
				this.progress.loading.next({ load: false, source: "validateUserCreds from userManager end" });
			}, 1000);
			this.alert.alerts.next({ message: `Both User Email and Password are required.` });
		}
	}

	ngOnInit() {}
}
