import { Component, OnInit, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { SuccessModalPage } from "./success-modal-page.component";
import { Subscription } from "rxjs";
import { BullFormDataService } from "../../bull-list/bull-form/bull-form-data.service";

//COMPONENT THAT CONTROLS MODAL
@Component({
	selector: "success-modal",
	templateUrl: "./success-modal.component.html",
	styleUrls: ["./success-modal.component.scss"],
})
export class SuccessModalComponent implements OnInit, OnDestroy {
	successModalSub: Subscription;

	constructor(public modalController: ModalController, public bullFormService: BullFormDataService) {
		this.successModalSub = this.bullFormService.changesSaved.subscribe((open: boolean) => {
			if (open) {
				this.presentModal();
			} else {
				this.modalController.dismiss;
			}
		});
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: SuccessModalPage,
			cssClass: "success-modal",
			backdropDismiss: false,
			componentProps: {},
		});

		return await modal.present();
	}

	ngOnInit(): void {}
	ngOnDestroy() {
		this.successModalSub.unsubscribe();
	}
}
