import { Component, Input, OnDestroy } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { BullListRecallService } from "../bull-list-recall.service";
import { BullService } from "../../bull/bull.service";
import { AlertService } from "../../utils/alerts/alert.service";
import { ProgressBarService } from "../../utils/loading/progress-bar.service";
import { UserManagementService } from "../../login/user-management.service";
import { BullListView } from "../bull-list.model";
import { PriceService } from "../../price/price.service";
import { BullListOptionsService } from "../../bull-list-options/bull-list-options.service";
import { AppService } from "../../app.service";

@Component({
	selector: "price-list-page",
	templateUrl: "price-list-page.component.html",
	styleUrls: ["./price-list-page.component.scss"],
})
export class PriceListPageComponent implements OnDestroy {
	public listOfViews: BullListView[] = [];
	public chosenBaseView: string = "";
	constructor(
		private bullService: BullService,
		private alert: AlertService,
		public priceService: PriceService,
		public alertController: AlertController,
		public recall: BullListRecallService,
		private progress: ProgressBarService,
		private listOptions: BullListOptionsService,
		private userManager: UserManagementService,
		private appService: AppService
	) {
		this.getbullViews();
	}

	dismissModal() {
		this.priceService.openPriceList.next(false);
	}
	chooseBasePriceListView(listName: string) {
		this.chosenBaseView = listName;
	}
	ngOnDestroy() {}
	async buildPriceList() {
		this.progress.loading.next({ load: true, source: "build price start" });

		let mixins = ["Base", "Location", "Prices", "MarketingGroup", "HealthTest", "Meta"];
		this.chosenBaseView.includes("Beef") ? mixins.push("Sire Fertility") : null;
		this.recall.currentMixins = mixins;
		this.recall.currentTemplateId = this.chosenBaseView;
		this.recall.currentDisplayMixins = mixins.filter((mix) => mix !== "Base");
		if (this.chosenBaseView.includes("Beef")) {
			this.listOptions.productPerRow = true;
			await this.bullService.createBullList(mixins, this.chosenBaseView, null, false);
		} else {
			this.listOptions.productPerRow = false;
			await this.bullService.createBullList(mixins, this.chosenBaseView, null, false);
		}

		this.priceService.buildPriceList.next(true);
		this.progress.loading.next({ load: false, source: "build price end" });
		this.dismissModal();
	}
	async getbullViews() {
		let views = await this.appService.GetSystemTemplates();
		this.listOfViews = views.filter((view) => {
			return !view.IsGvc;
		});
	}
}
