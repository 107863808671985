import { Component, Input, OnDestroy } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { AlertService } from "./alert.service";
import { Subscription } from "rxjs";
import { BullFormDataService } from "../../bull-list/bull-form/bull-form-data.service";

export interface IAlert {
	message: string;
	header?: string;
	subTitle?: string;
}

@Component({
	selector: "alert",
	templateUrl: "alert.component.html",
	styleUrls: ["./alert.component.scss"],
})
export class Alert implements OnDestroy {
	alertSub: Subscription;
	message: string;
	saveChangesSub: Subscription;
	header?: string = "Error";
	subTitle?: string = "Something went wrong!";
	constructor(public alertController: AlertController, private alertService: AlertService, private bullFormService: BullFormDataService) {
		this.saveChangesSub = this.bullFormService.openSaveChangesModal.subscribe((open: boolean) => {
			if (open) {
				this.presentAlertConfirm();
			}
		});

		this.alertSub = this.alertService.alerts.subscribe((alertObj: IAlert) => {
			this.message = alertObj.message;
			if (alertObj.header !== (null || undefined || "")) {
				this.header = alertObj.header;
			}
			if (alertObj.subTitle !== (null || undefined || "")) {
				this.subTitle = alertObj.subTitle;
			}

			this.message = alertObj.message;
			this.presentAlert();
		});
	}

	async presentAlertConfirm() {
		const alert = await this.alertController.create({
			//   cssClass: 'my-custom-class',
			header: "Confirm!",
			message: "Message <strong>Save Your Changes?</strong>",
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
					cssClass: "secondary",
					handler: (blah) => {
						this.bullFormService.saveChanges.next(false);
						this.alertController.dismiss();
					},
				},
				{
					text: "Okay",
					cssClass: "primary",
					handler: () => {
						this.bullFormService.saveChanges.next(true);
					},
				},
			],
		});

		await alert.present();
	}

	async presentAlert() {
		const alert = await this.alertController.create({
			cssClass: "alert-custom-class",
			header: this.header,
			subHeader: this.subTitle,
			message: this.message,
			buttons: ["OK"],
		});

		await alert.present();
	}
	ngOnDestroy() {
		this.alertSub.unsubscribe();
		this.saveChangesSub.unsubscribe();
	}
}
