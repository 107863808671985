import { Component, OnInit, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { BullListRecallService } from "../bull-list-recall.service";
import { CategoryPageComponent } from "./category-page.component";

@Component({
	selector: "app-category-modal",
	templateUrl: "./category-modal.component.html",
	styleUrls: ["./category-modal.component.scss"],
})
export class CategoryModalComponent implements OnInit {
	categoryModalSub: Subscription;
	documentSrc: string;
	constructor(public modalController: ModalController, public recall: BullListRecallService) {
		this.categoryModalSub = this.recall.openCategoryModal.subscribe((open: boolean) => {
			if (open) {
				this.presentModal();
			} else {
				modalController.dismiss();
			}
		});
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: CategoryPageComponent,
			cssClass: "category-modal",
			backdropDismiss: false,
			// componentProps: {
			// 	docSrc: this.documentSrc,
			// },
		});

		return await modal.present();
	}

	ngOnInit(): void {}
	ngOnDestroy() {
		this.categoryModalSub.unsubscribe();
	}
}
