import { ColDef } from "ag-grid-community";
import { IPrice } from "../price/price.model";
import { Proof, MarketingGroup, MarketingCampaign, Sons, BullDocuments, Categories, HealthTest, ProductLine, LineageStack } from "../bull-list/bull-form/bull-form.model";

export interface BullListView {
	Id: string;
	Name: string;
	Hidden?: boolean;
	Endpoint?: string;
	Title: string;
	Description: string;
	IsGvc?: boolean;
}

export interface HTMLInputEvent extends Event {
	target: HTMLInputElement & EventTarget;
}
export interface IExtraColumn {
	idColumn?: boolean;
	importColumn: boolean;
	columnName: string;
	columnType: string;
	columnValue: string | number;
	identifierColumn?: boolean;
}
export interface LactationField {
	FormattedValue: string;
	Id: string;
	Name?: string;
	LegacyName?: string;
	Metric?: string;
	LabelKg?: string;
	LabelLb?: string;
	ValueKg?: any;
	ValueLb?: any;
	Value?: any;
}
export interface IExtraBull {
	importBull: boolean;
	bullId: string;
	columns: IExtraColumn[];
}
export interface ImportedBullsDTO {
	bulls: IExtraBull[];
}
export class BullFilter {
	field: string;
	filterObject: IBullFilter;
	constructor(filterDef: Array<any>) {
		this.field = filterDef[0];
		this.filterObject = filterDef[1];
	}
}
export interface ICondFilter {
	filter?: any;
	filterTo?: any;
	filterType?: string;
	type?: string;
}

export interface IBullFilter {
	filter?: any;
	filterType?: string;
	type?: string;
	operator?: string;
	condition1?: ICondFilter;
	condition2?: ICondFilter;
	values?: any[];
}

export class SavedBullListDTO {
	Name?: string;
	Delete?: boolean;
	nameOfList?: string;
	Type?: string;
	CreatorId?: string;
	CreatorName?: string;
	Id?: string; //<------ GUID for list definition
	IsDefault?: boolean;
	SharedUsers?: string[];
	SharedOwners?: string[];
	IsShared?: boolean;
	Definition?: {
		baseDataSource: string;
		mixins: string[];
		sortState: any;
		groupState: any;
		productPerRow?: boolean;
		homeGrownIds?: string[];
		columns?: string[];
		externalData?: ImportedBullsDTO;
		filterState: object;
		columnState: any;
	};
}

export class DeleteBullListDTO extends SavedBullListDTO {
	Delete?: boolean;
}

// This Response Object models the servers response when calling for a list of bulls from the client. The server returns a list of these objects which are based on
// an interface IBullData that is shared between the Grid and Form response objects.
export class BullResponse {
	Id?: string;
	RegName?: string;
	ShortName?: string;
	RegId?: string;
	Breed?: string;
	Gender?: string;
	BreedSort?: string;
	Controller?: number;
	PrimaryNaabCode?: string;
	Country?: string;
	Recessives?: string;
	HealthTests?: HealthTest[];
	DateOfBirth?: Date;
	Stud?: number;
	YoungSire?: string;
	ReleaseDate?: string;

	Haplotypes?: string;
	SireStack?: string;
	isActiveMarketable?: boolean;

	ProductionStatus?: {
		Type?: string;
		MoveDate?: Date;
		ResidencyDate?: string;
		IsProduction?: boolean;
		IsEU?: boolean;
		IsIsolation?: boolean;
		IsLayoff?: boolean;
		BarnCode?: string; // <---- ONLY IN ANIMAL DETAIL LEAVING IN PLACE FOR FUTURE POSSIBLE INCLUSION
		BarnStatus?: string;
		State?: string;
		Location?: string;
	};
	Proof?: {
		EvalDate?: string;
		EvalType?: string;
		Categories?: Categories[];
		EvalCountry?: string;
	};
	Lactation?: {
		Dam?: LactationField[];
		DaughterAvg?: LactationField[];
		MGDam?: LactationField[];
		MMGDam?: LactationField[];
	};
	Meta?: {
		AIStatus?: string;
		BirthState: string;
		aAa?: string;
		DMS?: string;
		DnaStatus: string;
		Classification?: string;
		SexedOnly?: boolean;
		PercentBlack?: number;
		CloneGen?: string;
		CrossGen?: string;
		PureBred?: boolean;
		HousedInCountry?: string;
		RHA_Ind?: string;
		RHA_Pct?: number;
		SireStack?: string;
		ConvertedCanadianDaughterAvg?: boolean;

		[key: string]: any;
	};
	Genotypes?: {
		BCN?: string;
		BLG?: string;
		KC?: string;
	};
	Lineage?: LineageStack[];
	MarketingCampaigns?: MarketingCampaign[];
	MarketingGroups?: MarketingGroup[];
	IsDead?: boolean;
}
