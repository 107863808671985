import { Component, OnDestroy } from "@angular/core";

import { ICellRendererAngularComp } from "@ag-grid-community/angular";

@Component({
	selector: "date-cell",
	template: ` {{ formattedDate() }} `,
})
export class DateRenderer implements ICellRendererAngularComp, OnDestroy {
	private params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public formattedDate(): string {
		if (this.params.value && this.params.value !== "") {
			return new Date(this.params.value).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
		} else {
			return this.params.value;
		}
	}

	ngOnDestroy() {}

	refresh(): boolean {
		return false;
	}
}
