import { Component, OnInit } from "@angular/core";
import { BullListRecallService } from "../bull-list-recall.service";

@Component({
	selector: "app-category-page",
	templateUrl: "./category-page.component.html",
	styleUrls: ["./category-page.component.scss"],
})
export class CategoryPageComponent implements OnInit {
	constructor(public recall: BullListRecallService) {}

	ngOnInit() {}

	closeModal(close: boolean) {
		this.recall.openCategoryModal.next(close);
	}
}
