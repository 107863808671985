import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, Params } from "@angular/router";
import { BullService } from "../bull/bull.service";

import { BullListRecallService } from "./bull-list-recall.service";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { UserManagementService, User } from "../login/user-management.service";
import { BullListView, SavedBullListDTO } from "./bull-list.model";

import { IRecall } from "./bull-list-recall.service";
import { AuthService } from "../auth/auth.service";
import { AppService } from "../app.service";

@Injectable({
	providedIn: "root",
})
export class BullListResolver implements Resolve<any> {
	public listLength: number;
	public currentIndex: number;
	constructor(private recall: BullListRecallService, private progress: ProgressBarService, private bullService: BullService, public authService: AuthService, private appService: AppService) {}

	/**
	 * Everything interaction that occurs from the home.component and app.component page (the side-bar menu) comes through here.
	 * Operations from the List Builder do not pass through here ever.
	 * @param router
	 * @param state
	 * @returns an IRecall object that gets used in the Angular router service.
	 */
	async resolve(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// this.recall.listOfAvailableViews = await this.appService.GetSystemTemplates(true);
		this.recall.currentMixins = [];
		this.progress.loading.next({ load: true, source: "bull list resolver start" });
		let user = await this.authService.getUserFromStorage();
		let userDefinedList: SavedBullListDTO = user.CustomLists.UserDefined.filter((list) => list.Name === this.recall.currentTemplateTitle)[0];
		if (userDefinedList)
			this.recall.currentTemplateId = userDefinedList.Definition.baseDataSource;

		// If we're refreshing with F5 (or Node recompiles), this.recall.currentListName is blank...Handle that.
		if (this.recall.currentTemplateId.length == 0 )
			this.recall.currentTemplateId = this.recall.defaultListName;

		const baseTemplate: BullListView = await this.appService.GetSystemTemplate(userDefinedList ? userDefinedList.Definition.baseDataSource : this.recall.currentTemplateId),
			loadSystemTemplate: boolean = (userDefinedList == null) && !baseTemplate.IsGvc;

		// Is a system defined, non GVC, template.
		if (loadSystemTemplate) {
			return await this.AsSystemTemlate(baseTemplate);
		}

		// It's one of the system defined GVC templates.
		if (baseTemplate.IsGvc) {
			return await this.AsGVCTemplate(baseTemplate);
		}

		// It's one of the user's saved custom lists (not touching this).
		if (userDefinedList) {
			return await this.AsCustomList(userDefinedList);
		}

		// Catch-all was always treated as the Lineup, and so it shall be.
		const defaultTemplate: BullListView = await this.appService.GetSystemTemplate(this.recall.defaultListName);
		return await this.AsSystemTemlate(defaultTemplate);
	}


	/**
	 * Resolve list request as a System GVC Template.
	 * Refactored out of resolve() for better readability.
	 * @param baseTemplate
	 * @returns an IRecall object that gets used in the Angular router service.
	 */
	private async AsGVCTemplate(baseTemplate: BullListView) {
		this.recall.currentTemplateId = baseTemplate.Name;
		this.recall.currentTemplateTitle = baseTemplate.Title;
		await this.bullService.createBullList([], this.recall.currentTemplateId);
		let recallObj: IRecall = { source: this.recall.currentTemplateId, settingDefault: false, listName: this.recall.currentTemplateTitle };
		return recallObj;
	}


	/**
	 * Resolve list request as a System Template.
	 * Refactored out of resolve() for better readability.
	 * @param baseTemplate
	 * @returns an IRecall object that gets used in the Angular router service.
	 */
	private async AsSystemTemlate(baseTemplate: BullListView) {
		// Called if the current list name is the same as a base view
		let mixins = ["Base"];
		this.recall.currentTemplateTitle = baseTemplate.Title;
		await this.bullService.createBullList(mixins, this.recall.currentTemplateId);
		this.recall.currentMixins = mixins;
		this.recall.currentDisplayMixins = mixins.filter((mix) => mix !== "Base");
		let recallObj: IRecall = { source: this.recall.currentTemplateId, settingDefault: false, listName: this.recall.currentTemplateTitle };
		return recallObj;
	}


	/**
	 * Resolve list request as a User Custom List.
	 * Refactored out of resolve(), but not really cleaned up because I didn't want to break it.
	 * @param userDefinedList
	 * @returns an IRecall object that gets used in the Angular router service.
	 */
	private async AsCustomList(userDefinedList: SavedBullListDTO) {
		const { mixins, filterState, groupState, columnState, sortState } = userDefinedList.Definition;
		let baseData = userDefinedList.Definition.baseDataSource,
			importedBulls = userDefinedList.Definition.externalData;
		this.recall.currentTemplateId = baseData;
		this.recall.currentTemplateTitle = userDefinedList.Name;
		this.recall.importedDataDTO = importedBulls;
		this.recall.currentMixins = mixins;
		this.recall.currentDisplayMixins = mixins.filter((mix) => mix !== "Base");
		if (userDefinedList.Definition.baseDataSource === "HomeGrownList") {
			if (importedBulls) {
				this.recall.homeGrownIds = userDefinedList.Definition.homeGrownIds;
				let bullIds = userDefinedList.Definition.homeGrownIds;
				let extraBulls = importedBulls.bulls.map((bull) => bull.bullId);
				extraBulls.forEach((bull) => bullIds.push(bull));
				await this.bullService.homeGrownList(mixins, bullIds, false);
				let recallObj: IRecall = {
					source: baseData,
					importing: true,
					listName: userDefinedList.Name,
					sort: sortState,
					filters: filterState,
					group: groupState,
					columns: columnState,
				};
				return recallObj;
			} else {
				this.recall.homeGrownIds = userDefinedList.Definition.homeGrownIds;
				await this.bullService.homeGrownList(mixins, userDefinedList.Definition.homeGrownIds, false);
				let recallObj: IRecall = {
					source: baseData,
					importing: false,
					listName: userDefinedList.Name,
					sort: sortState,
					filters: filterState,
					group: groupState,
					columns: columnState,
				};
				return recallObj;
			}
		} else {
			if (importedBulls) {
				if (baseData.includes("Right")) {
					if (!mixins.includes("MarketingRight")) {
						mixins.push("MarketingRight");
						this.recall.currentDisplayMixins.push("MarketingRight");
					}
				}
				await this.bullService.createBullList(
					mixins,
					baseData,
					importedBulls.bulls.map((bull) => bull.bullId),
					false
				);
				let recallObj: IRecall = {
					source: baseData,
					importing: true,
					listName: userDefinedList.Name,
					sort: sortState,
					filters: filterState,
					group: groupState,
					columns: columnState,
				};
				return recallObj;
			} else {
				if (baseData.includes("Right")) {
					if (!mixins.includes("MarketingRight")) {
						mixins.push("MarketingRight");
						this.recall.currentDisplayMixins.push("MarketingRight");
					}
				}
				await this.bullService.createBullList(mixins, baseData, null, false);
				let recallObj: IRecall = {
					source: baseData,
					listName: userDefinedList.Name,
					sort: sortState,
					filters: filterState,
					group: groupState,
					columns: columnState,
				};
				return recallObj;
			}
		}
	}
}
