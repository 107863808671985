import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ListNamePipe } from "./pipes/list-name.pipe";
import { Alert } from "./alerts/alert.component";
import { LoadingComponent } from "./loading/loading.component";

import { FilterNamePipe } from "./pipes/filter-name.pipe";
import { HighlightDirective } from "./directives/highlight.directive";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { CertFormatPipe } from "./pipes/cert-format.pipe";
import { DnaFormatPipe } from "./pipes/dna-format.pipe";
import { PedigreeFormatPipe } from "./pipes/pedigree-format.pipe";
import { BooleanFormatPipe } from "./pipes/boolean-format.pipe";
import { SuccessModalComponent } from "./success-modal/success-modal.component";
import { SuccessModalPage } from "./success-modal/success-modal-page.component";
import { IonicModule } from "@ionic/angular";
import "ag-grid-enterprise";
import { AgGridModule } from "ag-grid-angular";
import { CurrencyPipe, DecimalPipe, PercentPipe } from "@angular/common";
import { SafeNumberPipe } from "./pipes/safe-number.pipe";
import { TraitLabelFormatPipe } from "./pipes/trait-label.pipe";
import { TraitValueFormatPipe } from "./pipes/trait-value.pipe";
import { MarketingRightBadgeFormatPipe } from "./pipes/marketing-right-badge.pipe";
import { DragDropDirective } from "./directives/drag-drop.directive";
import { MixinChipFormatPipe } from "./pipes/mixin-chip-format.pipe";

@NgModule({
	declarations: [
		ListNamePipe,
		HighlightDirective,
		FilterNamePipe,
		Alert,
		LoadingComponent,
		DateFormatPipe,
		CertFormatPipe,
		DnaFormatPipe,
		PedigreeFormatPipe,
		BooleanFormatPipe,
		SuccessModalComponent,
		SuccessModalPage,
		SafeNumberPipe,
		TraitLabelFormatPipe,
		TraitValueFormatPipe,
		MarketingRightBadgeFormatPipe,
		DragDropDirective,
		MixinChipFormatPipe,
	],
	providers: [DecimalPipe, PercentPipe, CurrencyPipe],
	imports: [CommonModule, IonicModule],
	exports: [
		FilterNamePipe,
		HighlightDirective,
		ListNamePipe,
		Alert,
		LoadingComponent,
		DateFormatPipe,
		CertFormatPipe,
		DnaFormatPipe,
		PedigreeFormatPipe,
		BooleanFormatPipe,
		SuccessModalComponent,
		SuccessModalPage,
		SafeNumberPipe,
		TraitLabelFormatPipe,
		TraitValueFormatPipe,
		MarketingRightBadgeFormatPipe,
		DragDropDirective,
		MixinChipFormatPipe,
	],
})
export class UtilsModule {}
