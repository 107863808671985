import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgForage } from "ngforage";
import { BehaviorSubject, Observable, throwError, of, from } from "rxjs";
import { catchError, switchMap, map, tap, filter, take, finalize } from "rxjs/operators";
import { API_URL, ENDPOINT } from "../../environments/environment";
import { User, UserManagementService } from "../login/user-management.service";
import { AlertService } from "../utils/alerts/alert.service";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private token: string;

	private APIKey: string;
	private userName: string;
	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	constructor(public http: HttpClient, public authService: AuthService, public userManager: UserManagementService, public ngf: NgForage, public alert: AlertService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.token = localStorage.getItem("token");
		this.userName = localStorage.getItem("user");
		this.APIKey = localStorage.getItem("APIKey");
		req = this.addToken(req);
		return next.handle(req).pipe(
			catchError((err) => {

				if (err.status === 499) {
					// An administrator has forcibly expired all Access Tokens.
					return this.AssertSessionRenewal(err);
				}

				if ([401, 403, 0].includes(err.status) && !req.url.includes("login") && !req.url.includes("generateAccessToken")) {
					if (this.refreshTokenInProgress) {
						return this.refreshTokenSubject.pipe(
							filter((result) => result !== null),
							take(1),
							switchMap(() =>
								next.handle(this.addToken(req)).pipe(
									catchError((err) => {
										return throwError(err);
									})
								)
							)
						);
					} else {
						this.refreshTokenInProgress = true;
						this.refreshTokenSubject.next(null);
						return this.renewToken().pipe(
							switchMap((token: any) => {
								this.refreshTokenInProgress = false;
								this.refreshTokenSubject.next(token);

								return next.handle(this.addToken(req)).pipe(
									catchError((err) => {
										return throwError(err);
									})
								);
							})
						);
					}
				}
				return throwError(err);
			})
		);
	}


	/**
	 * An administrator has forcibly expired all Access Tokens, kill this session and force the user to login.
	 * This wasn't working as expected using the alert Service, so it's using an old-school JS alert.
	 * This is triggered by the presence of "ForceOutSession" in the user object.
	 * @param err The error returned from the API end point.
	 */
	private AssertSessionRenewal(err: any): Observable<any> {
		this.ngf.removeItem("Meta");
		this.ngf.removeItem("NaabCodeIndex");
		alert(err.statusText);
		this.authService.logout().then( () => {
			window.location.reload();
		} );
		return new Observable<any>();
	}

	private renewToken(): Observable<any> {
		let params = {
			apiKey: this.APIKey,
			username: this.userName,
		};

		return this.http.post(API_URL + ENDPOINT.generateToken, params).pipe(
			map((user: { User: User }) => {
				if (user.User.AccessToken) {
					this.token = user.User.AccessToken;

					localStorage.setItem("token", user.User.AccessToken);
				}
				return of(user.User.AccessToken);
			})
		);
	}
	private addToken(request: HttpRequest<any>): HttpRequest<any> {
		if (request.url.includes("login") || request.url.includes("generate")) {
			return (request = request.clone({
				setHeaders: {
					SourceApp: "AT",
				},
			}));
		} else {
			return (request = request.clone({
				setHeaders: {
					SourceApp: "AT",
					AccessToken: this.token,
				},
			}));
		}
	}
}
