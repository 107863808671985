import { Injectable, ApplicationInitStatus } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class BullListOptionsService {
	productPerRow: boolean = false;
	showAdditionalProductData: boolean = false;
	constructor() {}
}
