import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from "@angular/router";
import { UserManagementService, User } from "../login/user-management.service";
import { NgForage } from "ngforage";
import { AlertService } from "../utils/alerts/alert.service";
import { AuthService } from "../auth/auth.service";

@Injectable({
	providedIn: "root",
})
export class HomeResolver implements Resolve<any> {
	constructor(private route: ActivatedRoute, public authService: AuthService, private ngf: NgForage, private alert: AlertService) {}

	async resolve(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try {
			let user: User = await this.authService.getUserFromStorage("home-resolver");

			return user;
		} catch (error) {
			this.alert.alerts.next({ message: `Something Went Wrong! Error: ${error}` });
		}
	}
}
