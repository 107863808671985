import { Pipe, PipeTransform } from "@angular/core";
import { Trait } from "../../bull-list/bull-form/bull-form.model";

@Pipe({ name: "traitLabel" })
export class TraitLabelFormatPipe implements PipeTransform {
	transform(trait: Trait) {
		if (trait.LabelRel) {
			return trait.Label + ` / ${trait.LabelRel}`;
		} else if (trait.Label) {
			return trait.Label;
		} else {
			return trait.LegacyName || trait.Trait || trait.Description;
		}
	}
}
