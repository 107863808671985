import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { Subscription } from "rxjs";
import { ListShareService } from "../list-share/list-share.service";
import { ListSharePageComponent } from "./list-share-page.component";

@Component({
	selector: "list-share",
	templateUrl: "list-share-modal.component.html",
	styleUrls: ["./list-share-modal.component.scss"],
})
export class ListShareModalComponent {
	listShareSub: Subscription;
	constructor(public modalController: ModalController, public listShareService: ListShareService) {
		this.listShareSub = this.listShareService.openListSharing.subscribe((openShare: boolean) => {
			if (openShare) {
				this.presentModal();
			} else {
				this.modalController.dismiss();
			}
		});
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: ListSharePageComponent,
			cssClass: "list-share",
			backdropDismiss: false,
		});

		return await modal.present();
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.listShareSub.unsubscribe();
	}
}
