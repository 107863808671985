import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { ModalController, IonInfiniteScroll } from "@ionic/angular";
import { Bull } from "../../bull/bull.model";
import { BullFormModalPage } from "./bull-form-modal.component";
import { Subject, Subscription } from "rxjs";
import { BullListRecallService } from "../bull-list-recall.service";

//COMPONENT THAT CONTROLS BULL FORM MODAL
@Component({
	selector: "bull-form",
	templateUrl: "./bull-form.component.html",
	styleUrls: ["./bull-form.component.scss"],
})
export class BullFormComponent implements OnInit, OnDestroy {
	@Input() bullList: Bull[];
	showSub: Subscription;

	constructor(public modalController: ModalController, private recall: BullListRecallService) {
		this.showSub = this.recall.showBullForm.subscribe(({ show, fromList }) => {
			if (show) {
				if (fromList) {
					this.recall.showBullFormFromList = true;
				} else {
					this.recall.showBullFormFromList = false;
				}
				this.presentModal();
			}
		});
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: BullFormModalPage,
			cssClass: "wws-modal",
			backdropDismiss: false,
			componentProps: {
				bullFormList: this.bullList,
			},
		});
		return await modal.present();
	}

	ngOnInit(): void {}
	ngOnDestroy() {
		this.showSub.unsubscribe();
	}
}
