import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ViewChildren, QueryList, ElementRef } from "@angular/core";
import { BullService } from "../bull/bull.service";
import { Bull, columnDefiner } from "../bull/bull.model";
import { SavedBullListDTO, BullFilter, BullListView } from "../bull-list/bull-list.model";
import { ActivatedRoute } from "@angular/router";
import { GridApi, ColDef, IToolPanel } from "ag-grid-community";
import { Driver, NgForageCache } from "ngforage";
import { BullImportService } from "./import-modal/bull-import.service";
import { ImportedBullsDTO } from "./bull-list.model";
import { BullListRecallService, IRecall } from "./bull-list-recall.service";
import { UserManagementService, UserPayload, User } from "../login/user-management.service";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { Subscription, interval, Subject } from "rxjs";
import { AlertService } from "../utils/alerts/alert.service";
import { GVCProduct, GVCTemplateResponse } from "../bull/gvc.model";
import { GvcType } from "../domain/gvc/gvc-type.model";
import { BullFormDataService } from "./bull-form/bull-form-data.service";
import { DateRenderer } from "./date-renderer.component";

import { PriceService } from "../price/price.service";
import { MarketingGroup } from "./bull-form/bull-form.model";
import { BullListOptionsService } from "../bull-list-options/bull-list-options.service";
import { ListShareService } from "./list-share/list-share.service";
import { AuthService } from "../auth/auth.service";
import { FormatDatesAsList } from "../utils/functions/format-dates-aslist";

import { LicenseManager } from "ag-grid-enterprise";
import { CT_URL, AG_GRID_LIC } from "../../environments/environment";
import { AppService } from "../app.service";

interface IColumnToolPanel extends IToolPanel {
	setColumnLayout?(colDefs: ColDef[]): void;
}

interface FilterParam {
	resetButton: boolean;
	applyButton: boolean;
}

interface ColumnDef {
	headerName?: string;
	field?: string;
	rowGroup?: boolean;
	rowDrag?: boolean;
	sortable?: boolean;
	filter?: string;
	hide?: boolean;
	enableRowGroup?: boolean;
	resizable?: boolean;

	filterParams?: FilterParam;
}

@Component({
	selector: "app-bull-list",
	templateUrl: "./bull-list.component.html",
	styleUrls: ["./bull-list.component.scss"],
})
export class BullListComponent implements OnInit, AfterViewInit, OnDestroy {
	gridComponents: any;
	addingColumns: boolean = false;
	public userLists: SavedBullListDTO[] = [];
	public showFiltersBoolean: boolean = false;
	public currentListDescription: string = "";
	public loadGVCSub: Subscription;
	public homeGrownSub: Subscription;
	public editedListName: string = "";
	public editListName: boolean = false;
	public bullListViewNames: string[] = [];
	public visibleColumns: ColDef[] = [];
	public hiddenColumns: ColDef[] = [];
	public buildPriceListSub: Subscription;
	public gvcCountry: string = "";
	public marketingRightCountry: string = "";
	public productPerRow: boolean = false;
	public listNameIsView: boolean = true;
	public priceFieldsToHide = [
		"ManufacturerCode",
		"Surcharge",
		"HasItemCard",
		"CountryComments",
		"Blocked",
		"Allocation",
		"Full_Ejac",
		"Royalty",
		"Retail",
		"MinFlex",
		"Cogs",
		"Unit_Price",
		"Unit_Cost",
		"NaabCode",
		"ATA_",
		"EC",
		"EU_Saleable_Units",
		"Non_EU_Saleable_Units",
		"ToBePriced",
		"Discount_Group",
	];
	public additonalProductPriceFieldsToHide = ["Royalty", "Retail", "MinFlex", "Cogs", "Unit_Price", "Unit_Cost"];
	@ViewChildren("loadTemplateItem") bullTemplates: QueryList<ElementRef>;
	@ViewChildren("viewCol") viewListItemColumns: QueryList<any>;
	@ViewChildren("addCatCol") addCategoryColumns: QueryList<any>;
	@ViewChild("loadTemplateContainer") bullTemplateContainer: ElementRef;
	@ViewChild("addCategoryContainer") addCategoryContainer: ElementRef;
	@ViewChild("loadListContainer") loadListContainer: ElementRef;
	@ViewChild("templateGrid") templateGrid: any;
	@ViewChild("categoryGrid") categoryGrid: any;
	@ViewChildren("loadListItem") loadListItems: QueryList<ElementRef>;
	@ViewChildren("addCat") addCategoryItems: QueryList<ElementRef>;
	public openLoadATemplateView: boolean = false;
	public openLoadList: boolean = false;
	public bullListViews: BullListView[];
	public bullListTitles: string[] = [];
	public excelStyles = [
		{
			id: "ExcelDateTime",
			dataType: "dateTime",
			numberFormat: { format: "yyyy-mm-dd hh:mm:ss;;;" },
		},
		{
			id: "ExcelDate",
			dataType: "dateTime",
			numberFormat: { format: "yyyy-mm-dd" },
		},
		{
			id: "ExcelDecimal",
			dataType: "string",
		},
		{
			id: "ExcelDecimalPlace1",
			// dataType: "number",
			numberFormat: { format: "#,##0.0" },
		},
		{
			id: "ExcelDecimalPlace2",
			// dataType: "number",
			numberFormat: { format: "#,##0.00" },
		},
	];
	//These columns are skipped when creating column definitions, can also comment the field out of the columnDefiner initializer object located in the bull model file
	public columnsToSkip = ["Update Date", "ID", "Classification"];
	//Sets which columns are going to get a filter that allows clicking on multiple criteria instead of "AND" "OR" which only allows 2 values
	public columnsWithSelectFilter = ["Breed", "BreedSort", "YoungSire", "EC", "SX2M_EC", "SX4M_EC", "Stud", "Dead", "stype", "CloneGen", "Active", "New"];
	public currentFilters: BullFilter[];
	public name: string;
	public arrayBuffer: any;
	public loggedInUser: User;
	public importedData: ImportedBullsDTO;
	public stagingBullsList: Bull[] = [];

	public columnDefs: any[];
	public rowData: any[];
	public gridApi: GridApi;
	public gridColumnApi;
	public defaultColDef;
	public newTab: boolean = false;
	public sideBar;
	public bullKeys: string[] = [];
	public statusBar;
	public gridOptions;
	public darkMode: boolean = false;
	public bullsList: Bull[];
	public bullSubscription: Subscription;
	public getRowNodeId;
	public currentNaabFilters;
	public currentRegIdFilters;
	public addCategorySub: Subscription;
	public importedBullSub: Subscription;
	public loadListWithImportsSub: Subscription;
	public gvcReportTypes = GvcType;
	public proofMeta = [];
	private ct_url: string = CT_URL;

	private columnMeta: any[] = [];

	private intlProofMeta: any[] = [];

	constructor(
		public bullImportService: BullImportService,
		public priceService: PriceService,
		private activatedRoute: ActivatedRoute,
		public bullServiceApI: BullService,
		public recall: BullListRecallService,
		private readonly cache: NgForageCache,

		public userManager: UserManagementService,
		private progress: ProgressBarService,
		public alert: AlertService,
		public bullFormService: BullFormDataService,
		public listOptions: BullListOptionsService,
		public listShareService: ListShareService,
		public authService: AuthService,
		private appService: AppService
	) {
		// Register AG-Grid with lic.
		LicenseManager.setLicenseKey(AG_GRID_LIC);

		this.gridComponents = {
			dateRenderer: DateRenderer,
		};
		this.getRowNodeId = function (data) {
			return data.PrimaryNaabCode;
		};

		this.sideBar = {
			toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					// toolPanelParams: {
					// 	contractColumnSelection: true,
					// },
				},
				{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel",
				},
			],
			defaultToolPanel: "columns",
		};
		this.defaultColDef = {
			flex: 1,
			minWidth: 100,
			enableValue: true,
			enableRowGroup: true,
			enablePivot: true,
			sortable: true,
			filter: true,
		};

		this.statusBar = {
			statusPanels: [
				{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left",
				},
				{
					statusPanel: "agTotalRowCountComponent",
					align: "center",
				},
				{
					statusPanel: "agFilteredRowCountComponent",
				},
				{
					statusPanel: "agSelectedRowCountComponent",
				},
				{
					statusPanel: "agAggregationComponent",
					statusPanelParams: {
						// possible values are: 'count', 'sum', 'min', 'max', 'avg'
						aggFuncs: ["avg", "sum"],
					},
				},
			],
		};
	}


	/**
	 * Rather than having this log all over this class, let's use a method to format header labels.
	 * @param col The column name.
	 * @returns string for header label
	**/
	private FormatHeaderLabel(col: string) {
		const fieldDef: any = this.GetDefinitionByIdentifier(col.toLowerCase());
		if (fieldDef != null) {
			return fieldDef["HeaderLabel"];
		}

		// Remove SX2M_ and replace with just SX_
		col = col.replace(/SX2M_/ig, "SX_");
		if (col.includes("Retail")) {
			col = col.replace("Retail", "Suggested Retail");
		}
		return this.bullServiceApI.formatTitleCase(col.replace(/_/g, " ")).toUpperCase();
	}


	/**
	 * Refactored out of FormatHeaderLabel.
	 * This helper method uses only metadata, but only for a couple of categories so far.
	 * @param fieldId A string representing the field for which we'll return a meta object.
	 * 		For now it's a legacy name, most likely.
	 * 		In the future, this should be an Id.
	 * @returns A Trait object, if found, or None.
	 */
	private GetDefinitionByIdentifier(fieldId: string): any {
		fieldId = fieldId.toLowerCase();
		let fieldMatched: any;

		// Catch-All field matches.
		fieldMatched = this.columnMeta.find((meta: any) => {
			return meta.LegacyName.toLowerCase() == fieldId
				|| (meta.hasOwnProperty("Name") && meta.Name.toLowerCase() == fieldId)
				|| (meta.hasOwnProperty("Trait") && meta.Trait.toLowerCase() == fieldId);
		});
		if (fieldMatched && fieldMatched.hasOwnProperty("HeaderLabel")) {
			// console.log(field, ": Found in column meta.")
			return fieldMatched[0];
		}

		// Int'l Proof field matches.
		fieldMatched = this.intlProofMeta.find((meta: any) => {
			return meta.LegacyName.toLowerCase() == fieldId
				|| (meta.hasOwnProperty("Trait") && meta.Trait.toLowerCase() == fieldId);
		});
		if (fieldMatched && fieldMatched.hasOwnProperty("HeaderLabel")) {
			// console.log(field, ": Found in int'l trait meta.")
			return fieldMatched[0];
		}

		// console.warn(field, ": Not found, using Reg Ex.")
		return null;
	}

	async showAdditionalProductData() {
		this.listOptions.showAdditionalProductData = !this.listOptions.showAdditionalProductData;
		if (this.listOptions.productPerRow) {
			await this.refreshData();
		} else {
			await this.rebuildBullGrid();
		}
	}

	async rebuildBullGrid() {
		let filters = this.gridApi.getFilterModel();
		let columns = this.gridColumnApi.getColumnState();
		let group = this.gridColumnApi.getColumnGroupState();
		let sort = this.gridApi.getSortModel();
		this.progress.loading.next({ load: true, source: "rebuild" });
		await this.buildBullGrid();

		setTimeout(() => {
			this.progress.loading.next({ load: false, source: "rebuild" });
		}, 1500);

		this.gridApi.setFilterModel(filters);
		this.gridColumnApi.setColumnState(columns);
		this.gridColumnApi.setColumnGroupState(group);
		this.gridApi.setSortModel(sort);
	}

	openListSharing() {
		this.listShareService.openListSharing.next(true);
	}
	displayFilters() {
		this.showFiltersBoolean = !this.showFiltersBoolean;
	}
	showFilters() {
		let filters = this.gridApi.getFilterModel();

		let filterArrays = Object.entries(filters).filter((key, value) => {
			return key[0] !== "PrimaryNaabCode";
		});
		this.currentFilters = filterArrays.map((filter) => {
			let newFilterObject: BullFilter = new BullFilter(filter);
			return newFilterObject;
		});
	}

	async removeImportedBulls() {
		let bullIdsToRemove = this.bullImportService.importedBullsDTO.bulls.map((bull) => {
			return bull.bullId;
		});
		this.recall.currentBullsList.forEach((bull) => {
			if (
				bullIdsToRemove.includes(bull.Id) ||
				bullIdsToRemove.includes(bull.PrimaryNaabCode) ||
				bullIdsToRemove.includes(bull.SX2M_NaabCode) ||
				bullIdsToRemove.includes(bull.SX4M_NaabCode) ||
				bullIdsToRemove.includes(bull.RegId)
			) {
				this.recall.currentBullsList.splice(this.recall.currentBullsList.indexOf(bull), 1);
			}
		});
		this.bullImportService.importedBullsDTO = {
			bulls: [],
		};
		this.recall.importedDataDTO = {
			bulls: [],
		};
		this.bullImportService.currentImportedFileName = "";
		await this.updateGrid();
	}
	removeFilter(filterName: string) {
		let filter = this.gridApi.getFilterInstance(filterName);

		filter.setModel(null);

		this.showFilters();
		this.gridApi.onFilterChanged();
	}

	async changeFilters(filters) {
		this.gridApi.setFilterModel(filters);
		this.gridApi.onFilterChanged();
	}

	async updateGrid() {
		this.progress.loading.next({ load: true, source: "update grid start" });
		let filters = this.gridApi.getFilterModel();
		let columns = this.gridColumnApi.getColumnState();
		let group = this.gridColumnApi.getColumnGroupState();
		let sort = this.gridApi.getSortModel();

		await this.buildBullGrid();

		this.gridApi.setFilterModel(filters);
		this.gridColumnApi.setColumnState(columns);
		this.gridColumnApi.setColumnGroupState(group);
		this.gridApi.setSortModel(sort);
		setTimeout(() => {
			this.progress.loading.next({ load: false, source: "update grid false" });
		}, 1000);
	}
	// Fix reodering of columns when unchecking box
	handleVisibleColumns(event) {
		let columnState = this.gridColumnApi.getColumnState();
		let arrangedHiddenColumns = [];
		if (event.column === null) {
			return null;
		}

		this.visibleColumns = columnState.filter((col) => {
			return col.hide === false;
		});

		this.hiddenColumns = columnState.filter((col) => {
			return col.hide === true;
		});

		let marketingRightsPresent = this.recall.currentMixins.filter((mix) => {
			return mix === "MarketingRight";
		})[0];
		if (marketingRightsPresent) {
			let columns = columnDefiner[marketingRightsPresent];
			if (columns) {
				let obj = Object.entries(columns);

				for (let [key, value] of obj) {
					let columnToPush = this.hiddenColumns.filter((hiddenCol) => hiddenCol.colId === key)[0];
					if (columnToPush) {
						arrangedHiddenColumns.push(columnToPush);
					}
				}
				this.hiddenColumns.concat(arrangedHiddenColumns);
			}
		}

		this.hiddenColumns.sort((a, b) => a.colId.localeCompare(b.colId));
		this.gridColumnApi.setColumnState(this.visibleColumns.concat(this.hiddenColumns));

		// this.gridColumnApi.setColumnState(this.visibleColumns.concat(arrangedHiddenColumns));
	}

	clearFilters() {
		this.gridApi.setFilterModel(null);
		this.currentFilters = null;
		this.gridApi.onFilterChanged();
	}

	removeCategory(categoryName: string) {
		let indexOfMixinCategory = this.recall.currentMixins.indexOf(categoryName);
		let indexOfDisplayMixinCategory = this.recall.currentDisplayMixins.indexOf(categoryName);

		this.recall.currentMixins.splice(indexOfMixinCategory, 1);
		this.recall.currentDisplayMixins.splice(indexOfDisplayMixinCategory, 1);

		let keyList: any[] = [];
		if (this.recall.importedDataDTO) {
			if (this.recall.importedDataDTO.bulls.length > 0) {
				let importedColumns = this.recall.importedDataDTO.bulls[0].columns.filter((col) => col.importColumn === true).map((col) => col.columnName);
				importedColumns.forEach((col) => keyList.push(col));
			}
		}

		let columns = columnDefiner;
		for (let mix of this.recall.currentMixins) {
			let obj = Object.entries(columns[mix.replace(/\s/g, "")]);

			for (let [key, value] of Object.entries(obj)) {
				keyList.push(value[0]);
			}
		}

		this.columnDefs = this.columnDefs.filter((col) => {
			return keyList.includes(col.colId);
		});
		this.gridApi.setColumnDefs(this.columnDefs);
	}

	async addColumns(mixinName: string) {

		try {
			this.addingColumns = true;
			let filters = this.gridApi.getFilterModel();
			let columns = this.gridColumnApi.getColumnState();
			let group = this.gridColumnApi.getColumnGroupState();
			let sort = this.gridApi.getSortModel();

			if (this.recall.currentMixins.includes(mixinName)) {
				this.alert.alerts.next({
					message: `${mixinName} columns are already in the list. Please select another Category`,
					header: `${mixinName} Category`,
					subTitle: "The columns are already here",
				});
			} else {
				this.recall.currentMixins.push(mixinName);

				if (mixinName === "BeefMale") {
					await this.buildBullGrid();
					this.gridApi.setFilterModel(filters);

					this.gridColumnApi.setColumnGroupState(group);
					this.gridColumnApi.setColumnState(columns);

					this.gridApi.setSortModel(sort);
				} else {
					if (this.recall.currentTemplateId === "HomeGrownList") {
						if (this.recall.importedDataDTO) {
							let bullIds = this.recall.homeGrownIds;
							let extraBulls = this.recall.importedDataDTO.bulls.map((bull) => bull.bullId);
							extraBulls.forEach((bull) => bullIds.push(bull));
							await this.bullServiceApI.homeGrownList([mixinName], bullIds, true);
							await this.buildBullGrid();
							let cols = this.addImportedColumnDefinitions(this.recall.importedDataDTO);
							cols.forEach((col) => this.columnDefs.push(col));
							this.gridApi.setColumnDefs(this.columnDefs);
							this.addImportedValues(this.recall.importedDataDTO);
							this.gridApi.setFilterModel(filters);

							this.gridColumnApi.setColumnGroupState(group);
							this.gridColumnApi.setColumnState(columns);

							this.gridApi.setSortModel(sort);
						} else {
							await this.bullServiceApI.homeGrownList([mixinName], this.recall.homeGrownIds, true);
							await this.buildBullGrid();
							this.gridApi.setFilterModel(filters);

							this.gridColumnApi.setColumnGroupState(group);
							this.gridColumnApi.setColumnState(columns);

							this.gridApi.setSortModel(sort);
						}
					} else {
						if (this.recall.importedDataDTO) {
							//Fires if there is imported bulls in the list
							if (this.recall.importedDataDTO.bulls.length > 0) {
								await this.bullServiceApI.createBullList(
									[mixinName],
									this.recall.currentTemplateId,
									this.recall.importedDataDTO.bulls.map((bull) => bull.bullId),
									true
								);

								await this.buildBullGrid();
								let cols = this.addImportedColumnDefinitions(this.recall.importedDataDTO);
								cols.forEach((col) => this.columnDefs.push(col));
								this.gridApi.setColumnDefs(this.columnDefs);
								this.addImportedValues(this.recall.importedDataDTO);
							} else {
								await this.bullServiceApI.createBullList([mixinName], this.recall.currentTemplateId, null, true);
								await this.buildBullGrid();
								this.gridApi.setFilterModel(filters);

								this.gridColumnApi.setColumnGroupState(group);
								this.gridColumnApi.setColumnState(columns);

								this.gridApi.setSortModel(sort);
							}
							//Fires if there are no imported bulls
						} else {
							await this.bullServiceApI.createBullList([mixinName], this.recall.currentTemplateId, null, true);
							await this.buildBullGrid();
							this.gridApi.setFilterModel(filters);

							this.gridColumnApi.setColumnGroupState(group);
							this.gridColumnApi.setColumnState(columns);

							this.gridApi.setSortModel(sort);
						}
					}
				}

				this.recall.currentDisplayMixins = this.recall.currentMixins.filter((mix) => {
					return mix !== "Base";
				});

				this.gridApi.refreshCells({ force: true });
			}

			this.showFilters();
			await this.handleVisibleColumns("filler");
			this.addingColumns = false;
		} catch (error) {
			this.alert.alerts.next({ message: `Something Went Wrong Adding A Category: ${error.message}` });
			this.removeCategory(mixinName);
			this.addingColumns = false;
		}
	}

	//##################################################################################################################
	//#######################INIT######################################################################################

	async ngOnInit() {

		// Populate Proof Meta and Trait Meta (which is proof meta but flattened to only traits).
		this.appService.GetProofMetadata().then(meta => {
			this.proofMeta = meta;
			this.proofMeta.forEach((category) => {
				if (category.Country == "USA") {
					this.columnMeta = this.columnMeta.concat(category.Traits);
					return;
				}
				this.intlProofMeta = this.intlProofMeta.concat(category.Traits);
			});
		});

		// Populate all the remaining metadata categories for AG Grid labeling.
		this.appService.GetAnimalBaseMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetAgritechMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetProdStatMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetGenotypeMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetProductLineMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetLactationMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetHealthTestMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetAnimalMetaMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});
		this.appService.GetDesignationMetadata().then((meta) => {
			this.columnMeta = this.columnMeta.concat(meta);
		});

		// These subscription in the ngOnInit build lists based on parameters that are passed to the Subject that they are Subscribing to via the .next() method.
		this.buildPriceListSub = this.priceService.buildPriceList.subscribe(async (build: boolean) => {
			let columnOrder = [];
			let dairyColumnOrder = [
				"New",
				"YoungSire",
				"Allocation",
				"Active",
				"PrimaryNaabCode",
				"RegName",
				"ShortName",
				"Dead",
				"ToBePriced",
				"Unit_Cost",
				"Cogs",
				"Unit_Price",
				"MinFlex",
				"Retail",
				"ActiveDate",
				"CountryComments",
				"Location",
				"EC",
				"DateOfBirth",
				"UDT",
				"Royalty",
				"SX2M_NaabCode",
				"SX2M_ToBePriced",
				"SX2M_Unit_Cost",
				"SX2M_Cogs",
				"SX2M_Unit_Price",
				"SX2M_MinFlex",
				"SX2M_Retail",
				"SX2M_Full_Ejac",
				"SX2M_EC",
				"SX2M_CountryComments",
				"SX2M_Royalty",
				"SX4M_NaabCode",
				"SX4M_ToBePriced",
				"SX4M_Unit_Cost",
				"SX4M_Cogs",
				"SX4M_Unit_Price",
				"SX4M_MinFlex",
				"SX4M_Retail",
				"SX4M_Full_Ejac",
				"SX4M_EC",
				"SX4M_CountryComments",
				"SX4M_Royalty",
				"CloneGen",
				"HousedInCountry",
				"BreedSort",
				"Link",
			];

			let beefColumnOrder = [
				"Active",
				"PrimaryNaabCode",
				"RegId",
				"RegName",
				"ShortName",
				"Dead",
				"BarnStatus",
				"ToBePriced",
				"Unit_Cost",
				"Cogs",
				"Unit_Price",
				"MinFlex",
				"Retail",
				"Full_Ejac",
				"Royalty",
				"CountryComments",
				"State",
				"IBR",
				"LEU",
				"BT",
				"EHD",
				"EC",
				"Non_EU_Saleable_Units",
				"EU_Saleable_Units",
				"ResidencyDate",
				"DateOfBirth",
				"UDT",
				"cfi_serv",
				"cfi",
				"Breed",
				"Link",
			];
			if (this.recall.currentTemplateId.includes("Beef")) {
				columnOrder = beefColumnOrder;
			} else {
				columnOrder = dairyColumnOrder;
			}
			if (build) {
				await this.buildBullGrid();

				let artificialColumnState = [];
				for (let col of columnOrder) {
					artificialColumnState.push({
						aggFunc: null,
						colId: col,
						flex: 1,
						hide: false,
						pinned: null,
						pivotIndex: null,
						rowGroupIndex: null,
						width: 200,
					});
				}

				this.gridApi.setColumnDefs(this.columnDefs);
				this.gridColumnApi.setColumnState(artificialColumnState);
				await this.filterOutXD();
			}
			await this.checkForView(this.recall.currentTemplateTitle);
			await this.retrieveListDefinitionDescription();
			await this.getUserLists();
			this.showFilters();
		});
		this.addCategorySub = this.recall.addCategory.subscribe(async (category: string) => {
			await this.addColumns(category);
		});

		//--------------------------------------------------------------------------------------------------
		// This subscription waits for two values passed into an object {load:boolean, import: boolean} if loading a GVC with no imports set import to false to skip additional processing
		//-----------------------------------------------------------------------------------------------
		this.loadGVCSub = this.recall.loadGVC.subscribe(async (data: { load: boolean; import: boolean; source: string; refresh?: boolean }) => {
			if (data.refresh) {
				let filters = this.gridApi.getFilterModel();
				let columns = this.gridColumnApi.getColumnState();
				let group = this.gridColumnApi.getColumnGroupState();
				let sort = this.gridApi.getSortModel();
				this.progress.loading.next({ load: true, source: "loadGVC Sub start" });
				if (data.import) {
					let importedIds = this.recall.importedDataDTO.bulls.map((bull) => {
						return bull.bullId;
					});
					await this.bullServiceApI.retrieveGVCWithExtraIds(importedIds, data.source, this.bullImportService.productFilters).then((resp) => (this.recall.currentGVCList = resp.report));
					this.loadGVCTemplate(data.source);
					this.addImportedValues(this.recall.importedDataDTO, true);
				} else {
					await this.bullServiceApI.retrieveGVC(data.source).then((resp: GVCTemplateResponse) => {
						this.recall.currentGVCList = resp.report;
						this.recall.gvcOrder = resp.columns;
					});
					this.loadGVCTemplate(data.source);
				}
				this.gridApi.setFilterModel(filters);

				this.gridColumnApi.setColumnGroupState(group);
				this.gridColumnApi.setColumnState(columns);

				this.gridApi.setSortModel(sort);
				this.progress.loading.next({ load: false, source: "loadGVC Sub end" });

			} else if (data.load === true && data.import === false) {
				// // Get the current loading value from the BehaviorSubject
				let loading = this.progress.loading.value

				if (!loading.load) {
					this.progress.loading.next({ load: true, source: "loadGVC Sub start" });
				}

				await this.bullServiceApI.retrieveGVC(data.source).then((resp: GVCTemplateResponse) => {
					this.recall.currentGVCList = resp.report;
					this.recall.gvcOrder = resp.columns;
				});
				this.initializeGVCColumnDefs();
				this.loadGVCTemplate(data.source);

				this.progress.loading.next({ load: false, source: "loadGVC Sub end" });
			} else {
				this.loadGVCTemplate(data.source);
				this.initializeGVCColumnDefs();
				this.addImportedColumnDefinitions(this.recall.importedDataDTO).forEach((col) => this.columnDefs.push(col));
				this.addImportedValues(this.recall.importedDataDTO, true);
			}
			await this.checkForView(this.recall.currentTemplateTitle);
			await this.retrieveListDefinitionDescription();
			await this.getUserLists();
		});
		//--------------------------------------------------------------------------------------------------
		// This subscription waits for a boolean value of true to tell it to build a homegrown list
		//
		//-----------------------------------------------------------------------------------------------
		this.homeGrownSub = this.recall.buildHomeGrown.subscribe(async ({ build, change, save }) => {
			if (build && change) {
				this.refreshData();
			}
			if (build && !change) {
				await this.buildBullGrid();
				await this.filterOutXD();
			}
			if (save) {
				await this.saveBullList();
			}
			await this.checkForView(this.recall.currentTemplateTitle);
			await this.retrieveListDefinitionDescription();
			await this.getUserLists();
			this.showFilters();
		});

		//--------------------------------------------------------------------------------------------------
		// This subscription waits for an IRecall object to load a list of bulls that contains imported information to use pass an IRecall object into
		// this.recall.loadListWithImports.next() from anywhere in the application after injecting the recall service
		//-----------------------------------------------------------------------------------------------
		this.loadListWithImportsSub = this.recall.loadListWithImports.subscribe(async (data: IRecall) => {
			this.recall.currentTemplateTitle = data.listName;
			this.recall.currentTemplateId = data.source;
			await this.buildBullGrid();
			this.addImportedColumnDefinitions(this.recall.importedDataDTO).forEach((col) => {
				this.columnDefs.push(col);
			});
			this.gridApi.setColumnDefs(this.columnDefs);
			if (data.sort) {
				this.gridApi.setSortModel(data.sort);
			}
			if (data.group) {
				this.gridColumnApi.setColumnGroupState(data.group);
			}
			if (data.columns) {
				this.gridColumnApi.setColumnState(data.columns);
			}
			if (data.filters) {
				this.gridApi.setFilterModel(data.filters);
			}

			this.addImportedValues(this.recall.importedDataDTO);
			this.gridApi.applyTransaction({ update: this.recall.currentBullsList });
			this.recall.currentBullsList.forEach((bull) => this.rowData.push(bull));

			this.gridApi.refreshCells();

			this.progress.loading.next({ load: false, source: "load list with imports end" });
			await this.filterOutXD();
			await this.handleVisibleColumns("filler");
			await this.checkForView(this.recall.currentTemplateTitle);
			await this.retrieveListDefinitionDescription();
			await this.getUserLists();
			this.showFilters();
		});
		//--------------------------------------------------------------------------------------------------
		// This subscription waits for an IRecall object to load a list of bulls that contains imported information to use pass an IRecall object into
		// this.recall.loadListWithImports.next() from anywhere in the application after injecting the recall service
		//-----------------------------------------------------------------------------------------------
		this.bullSubscription = this.recall.loadBullsList.subscribe(async (data: IRecall) => {
			this.recall.currentTemplateTitle = data.listName;
			this.recall.currentTemplateId = data.source;
			await this.buildBullGrid();
			this.gridApi.setColumnDefs(this.columnDefs);
			if (data.sort) {
				this.gridApi.setSortModel(data.sort);
			}

			if (data.group) {
				this.gridColumnApi.setColumnGroupState(data.group);
			}

			if (data.columns) {
				this.gridColumnApi.setColumnState(data.columns);
			}

			if (data.filters) {
				this.gridApi.setFilterModel(data.filters);
			}

			let user = await this.authService.getUserFromStorage("bull list");
			this.gridApi.refreshCells({ force: true });
			let systemDefault = user.CustomLists.UserDefined.filter((list) => {
				return list.IsDefault === true || list.Name === "My Active Lineup";
			})[0];
			if (systemDefault) {
				data.settingDefault = false;
			} else {
				data.settingDefault = true;
			}
			if (data.settingDefault) {
				await this.recall.saveDefault();
				await this.getUserLists();
			}

			this.progress.loading.next({ load: false, source: "loading regular list end" });
			await this.filterOutXD();
			await this.checkForView(this.recall.currentTemplateTitle);
			await this.retrieveListDefinitionDescription();
			await this.getUserLists();
			this.showFilters();
		});
		//--------------------------------------------------------------------------------------------------
		// This subscription waits for imported bulls to be included in the current list and adds them along with any extra column definitions and values.
		// There is a catch for GVC in here. Grid state is grabbed at the beginning and applied at the end.
		//-----------------------------------------------------------------------------------------------
		this.importedBullSub = this.recall.addImportedBullsToGrid.subscribe(async (data: any[]) => {
			// Grabbing Grid State
			let filters = this.gridApi.getFilterModel();
			let columns = this.gridColumnApi.getColumnState();
			let group = this.gridColumnApi.getColumnGroupState();
			let sort = this.gridApi.getSortModel();

			let rows = [];
			this.gridApi.forEachNode((node) => (node.data ? rows.push(node.data) : null));
			this.gridApi.applyTransaction({ remove: rows });

			if (this.recall.currentTemplateId.includes("GVC")) {
				this.recall.currentGVCList.map( (gvc) => {
						// RegName might be null (albeit unlikely), coalesce to ShortName or NaabCode.
					let linkText = (gvc.RegName || gvc.Name || gvc["Code#"]).trim().replace(/^[0]*/, "");
					let identifier = gvc["Code#"].trim().replace(/^[0]*/, "");
					gvc.Hyperlink = `=HYPERLINK("${this.ct_url}/bull/${identifier}/EN","${linkText}")`
				} );
				this.gridApi.applyTransaction({ add: this.recall.currentGVCList });
				this.initializeGVCColumnDefs();
				this.addImportedColumnDefinitions(this.recall.importedDataDTO).forEach((col) => {
					this.columnDefs.push(col);
				});
				this.addImportedValues(this.recall.importedDataDTO, true);
				this.gridApi.setColumnDefs(this.columnDefs);
				this.gridApi.refreshCells({ force: true });
			} else {
				this.gridApi.applyTransaction({ add: this.recall.currentBullsList });
				this.initializeColumnDefs();
				this.addImportedColumnDefinitions(this.recall.importedDataDTO).forEach((col) => {
					this.columnDefs.push(col);
				});
				this.addImportedValues(this.recall.importedDataDTO);
				this.gridApi.setColumnDefs(this.columnDefs);

				this.gridApi.refreshCells({ force: true });
			}

			//Applying Grid State

			this.gridApi.setFilterModel(filters);
			this.gridColumnApi.setColumnGroupState(group);
			this.gridColumnApi.setColumnState(columns);
			this.gridApi.setSortModel(sort);
			await this.handleVisibleColumns("filler");
			await this.checkForView(this.recall.currentTemplateTitle);
			await this.retrieveListDefinitionDescription();
			await this.getUserLists();
			this.showFilters();
		});
		//--------------------------------------------------------------------------------------------------
		//PRIMARY GRID BUILDING FUNCTION FIRES OFF TO INITIALIZE THE GRID PRIOR TO ANY LOADING OF SAVED LISTS
		//-----------------------------------------------------------------------------------------------
		await this.buildBullGrid();
		this.recall.populateSavedLists();

		this.cache.driver = Driver.LOCAL_STORAGE;
	}

	ngOnDestroy() {
		this.bullSubscription.unsubscribe();
		this.importedBullSub.unsubscribe();
		this.homeGrownSub.unsubscribe();
		this.loadGVCSub.unsubscribe();
		this.loadListWithImportsSub.unsubscribe();
		this.buildPriceListSub.unsubscribe();
		this.addCategorySub.unsubscribe();
	}
	async ngAfterViewInit() {
		this.populateViewSelectionList();
	}
	refreshGVC(base: string) {
		if (this.recall.importedDataDTO) {
			this.recall.loadGVC.next({ load: true, import: true, refresh: true, source: base });
		} else {
			this.recall.loadGVC.next({ load: true, import: false, refresh: true, source: base });
		}
	}
	async productSwitch() {
		if (!this.recall.currentMixins.includes("Prices")) {
			this.progress.loading.next({ load: true, source: "product switch start" });
			await this.addColumns("Prices");

			this.progress.loading.next({ load: false, source: "product switch end" });
		}

		await this.refreshData();
	}
	async saveBullList() {
		let user: User = await this.authService.getUserFromStorage();
		let list = user.CustomLists.UserDefined.filter((DTO: SavedBullListDTO) => DTO.Name.toUpperCase().trim() === this.recall.savedListName.toUpperCase().trim())[0];
		if (list) {
			this.alert.alerts.next({ message: "There is a List with that Name already, please rename your List" });
		} else if (this.recall.savedListName.trim() === "") {
			this.alert.alerts.next({ message: "Your List Name is blank, please give this list a Name" });
		} else {
			let filters = this.gridApi.getFilterModel();
			let columns = this.gridColumnApi.getColumnState();
			let group = this.gridColumnApi.getColumnGroupState();
			let sort = this.gridApi.getSortModel();

			await this.recall.saveList(filters, columns, sort, group);
		}
		await this.getUserLists();
	}

	async startUpdateList() {
		let filters = this.gridApi.getFilterModel();
		let columns = this.gridColumnApi.getColumnState();
		let group = this.gridColumnApi.getColumnGroupState();
		let sort = this.gridApi.getSortModel();
		await this.recall.updateList(filters, columns, sort, group, this.recall.currentTemplateId);
		await this.getUserLists();
		// this.recall.updateList(filters, columns, sort, group, this.columnDefs, this.recall.currentTemplateId);
	}

	async refreshData() {
		this.progress.loading.next({ load: true, source: "refresh data start" });
		let filters = this.gridApi.getFilterModel();
		let columns = this.gridColumnApi.getColumnState();
		let group = this.gridColumnApi.getColumnGroupState();
		let sort = this.gridApi.getSortModel();
		let rows = [];
		this.gridApi.forEachNode((node) => (node.data ? rows.push(node.data) : null));
		this.gridApi.applyTransaction({ remove: rows });
		let currentMixins = this.recall.currentMixins;
		let baseDataSource = this.recall.currentTemplateId;
		let externalData = this.recall.importedDataDTO;
		if (baseDataSource === "HomeGrownList") {
			if (externalData && externalData.bulls.length > 0) {
				this.rowData = [];
				let bullIds = this.recall.homeGrownIds;
				let extrabulls = externalData.bulls.map((bull) => bull.bullId);
				extrabulls.forEach((bull) => bullIds.push(bull));
				await this.bullServiceApI.homeGrownList(currentMixins, bullIds, false).then(() => {
					this.gridApi.applyTransaction({ add: this.recall.currentBullsList });

					this.addImportedValues(this.recall.importedDataDTO);
					this.gridApi.refreshCells({ force: true });
				});
			} else {
				this.rowData = [];
				let bullIds = this.recall.homeGrownIds;

				await this.bullServiceApI.homeGrownList(currentMixins, bullIds, false).then(() => {
					this.gridApi.applyTransaction({ add: this.recall.currentBullsList });
				});
			}
		} else {
			this.rowData = [];
			if (externalData && externalData.bulls.length > 0) {
				await this.bullServiceApI
					.createBullList(
						currentMixins,
						baseDataSource,
						externalData.bulls.map((bull) => bull.bullId),
						false
					)
					.then(() => {
						this.gridApi.applyTransaction({ add: this.recall.currentBullsList });

						this.addImportedValues(this.recall.importedDataDTO);

						this.gridApi.refreshCells({ force: true });
					});
			} else {
				await this.bullServiceApI.createBullList(currentMixins, baseDataSource, [], false).then(() => {
					this.gridApi.applyTransaction({ add: this.recall.currentBullsList });
				});
			}
		}

		this.gridApi.setFilterModel(filters);
		this.gridColumnApi.setColumnGroupState(group);
		this.gridColumnApi.setColumnState(columns);
		this.gridApi.setSortModel(sort);
		await this.handleVisibleColumns("filler");
		this.progress.loading.next({ load: false, source: "refresh data end" });
	}


	/**
	 * Asynchronously restores a custom list from storage.
	 * Used to restore lists but checks storage instead of memory because new lists are saved to storage.
	 * Triggered by the "Load Custom List" dropdown in the view.
	 * @param listName - The name of the custom list to restore.
	 * @returns A Promise that resolves once the list is restored.
	 */
	async restoreListFromForage(listName: string) {
		// this.handleDropDown("load", false);
		this.recall.homeGrownIds = [];
		if (this.recall.importedDataDTO) {
			this.recall.importedDataDTO.bulls = [];
			this.bullImportService.currentImportedFileName = "";
		}
		let user: User = await this.authService.getUserFromStorage("restoring list"),
			list = user.CustomLists.UserDefined.filter((DTO) => DTO.Name === listName)[0],
			restoreFilters = list.Definition.filterState,
			restoreColumns = list.Definition.columnState,
			restoreSort = list.Definition.sortState,
			recalledMixins = list.Definition.mixins,
			restoreGroup = list.Definition.groupState,
			systemTemplate = list.Definition.baseDataSource;
		this.progress.loading.next({ load: true, source: "load list from storage start" });

		this.recall.currentMixins = recalledMixins;
		this.AssertMRs(systemTemplate);
		this.recall.currentDisplayMixins = recalledMixins.filter((mix) => mix !== "Base");
		await this.LoadInitialData(systemTemplate, list, recalledMixins);
		if (this.ListHasExternalData(list)) {
			this.recall.importedDataDTO = list.Definition.externalData;
			return this.recall.loadListWithImports.next({
				source: systemTemplate,
				importing: true,
				listName: list.Name,
				columns: restoreColumns,
				filters: restoreFilters,
				sort: restoreSort,
				group: restoreGroup,
				mixins: recalledMixins
			});
		}

		this.recall.importedDataDTO = { bulls: [] };
		this.recall.loadBullsList.next({
			source: systemTemplate,
			listName: list.Name,
			filters: restoreFilters,
			columns: restoreColumns,
			group: restoreGroup,
			sort: restoreSort,
			mixins: recalledMixins,
		});
	}


	/**
	 * Asynchronously loads initial data for a custom list based on the system template and mixins.
	 * @param systemTemplate - The data source for the list.
	 * @param list - The SavedBullListDTO object representing the list.
	 * @param recalledMixins - An array of mixins to be applied to the list.
	 * @returns A Promise that resolves once the initial data is loaded.
	 */
	private async LoadInitialData(systemTemplate: string, list: SavedBullListDTO, recalledMixins: string[]) {
		// Handle if this this is a home grown list (no system template).
		if (systemTemplate === "HomeGrownList") {
			let bullIds: string[] = list.Definition.homeGrownIds;
			this.recall.homeGrownIds = list.Definition.homeGrownIds;
			await this.bullServiceApI.homeGrownList(recalledMixins, bullIds, false);
			return;
		}

		// Handle any other system template.
		let extraBulls: string[] = this.ListHasExternalData(list) ? list.Definition.externalData.bulls.map((bull) => bull.bullId) : [];
		await this.bullServiceApI.createBullList(
			recalledMixins,
			systemTemplate,
			extraBulls,
			false
		);
	}


	/**
	 * Asserts the presence of "MarketingRight" in the current mixins and adds it if missing.
	 * @param baseDataSource The base data source to check for the presence of "Right".
	 */
	private AssertMRs(baseDataSource: string) {
		if (!baseDataSource.includes("Right")) {
			return;
		}
		if (!this.recall.currentMixins.includes("MarketingRight")) {
			this.recall.currentMixins.push("MarketingRight");
			this.recall.currentDisplayMixins.push("MarketingRight");
		}
	}

	/**
	 * Checks if the provided bull list has external data.
	 * @param {SavedBullListDTO} list - The saved bull list data transfer object to check.
	 * @returns {boolean} Returns true if the list has external data, false otherwise.
	 */
	private ListHasExternalData(list: SavedBullListDTO) {
		return list.Definition.externalData !== null
			&& list.Definition.externalData !== undefined
			&& list.Definition.externalData.bulls.length > 0;
	}

	/**
	 * Populates the drop down list in the view with the system defined templates.
	 * The "Price List" is not a system defined template, it's merely a client-defined custom list, and is append to the bottom of the templates list.
	 */
	async populateViewSelectionList() {
		this.bullListViews = await this.appService.GetSystemTemplates();	// This will already have hidden lists suppressed.
		this.bullListViews.push({
			Id: "PriceList",
			Name: "Price List",
			Title: "Price List",
			Description: "Entry to Price List Builder",
		});
		this.bullListViewNames = this.bullListViews.map((view) => view.Name);
		this.bullListTitles = this.bullListViews.map((view) => view.Title);
	}

	async loadGVCTemplate(source: string) {
		let selectedGvc = await this.appService.GetSystemTemplate(source);
		this.recall.currentTemplateTitle = selectedGvc.Title;
		this.recall.currentTemplateId = selectedGvc.Name;
		this.recall.currentMixins = [];
		this.recall.currentDisplayMixins = [];

		let rows = [];
		this.gridApi.forEachNode((node) => {
			node.data ? rows.push(node.data) : null;
		});
		this.gridApi.applyTransaction({ remove: rows });
		this.recall.currentGVCList.map(
			(gvc) => (gvc.Hyperlink = `=HYPERLINK("${this.ct_url}/bull/${gvc["Code#"]?.trim().replace(/^[0]*/, "")}/EN","${gvc.RegName.trim().replace(/^[0]*/, "")}")`)
		);
		this.gridApi.applyTransaction({ add: this.recall.currentGVCList });
	}

	initializeGVCColumnDefs(addingColumns: boolean = false) {
		this.columnDefs = [];
		for (let obj of this.recall.gvcOrder) {
			let key = obj.Name;
			let value = obj.Value;
			if (key === "Marketing Rights Customer") {
				this.columnDefs.push({
					headerName: key.toUpperCase(),
					field: key,
					colId: key,
					hide: true,
					filter: true,
					sortable: true,
					resizable: true,

					valueGetter: (params) => {
						if (this.gvcCountry === "") {
							return null;
						}
						if (params.data.MarketingRight) {
							let rights = params.data.MarketingRight.filter((mkright: MarketingGroup) => {
								return mkright.Name.includes(this.gvcCountry);
							});

							if (rights.length > 0) {
								if (rights.length > 1) {
									let name = "";
									rights = rights.map((mk) => mk.Name);
									rights = rights.join();
									name = rights;
									return name;
								} else {
									return rights[0].Name;
								}
							}
						}
					},
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (key === "Marketing Rights Date") {
				this.columnDefs.push({
					headerName: key.toUpperCase(),
					field: key,
					colId: key,
					hide: true,
					filter: "agTextColumnFilter",
					sortable: true,
					resizable: true,

					valueGetter: (params) => {
						if (this.gvcCountry === "") {
							return null;
						}
						if (params.data.MarketingRight) {
							let rights = params.data.MarketingRight.filter((mkright: MarketingGroup) => {
								return mkright.Name.includes(this.gvcCountry);
							});

							if (rights.length === 0)
								return "";

							let dateValues = [];
							rights.forEach(right => {
								if (!right.Status.ActionDate)
									return;
								dateValues.push(right.Status.ActionDate);
							});
							return FormatDatesAsList(dateValues);
						}
					},
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (key === "Marketing Rights Action") {
				this.columnDefs.push({
					headerName: key.toUpperCase(),
					field: key,
					colId: key,
					hide: true,
					filter: true,
					sortable: true,
					resizable: true,

					valueGetter: (params) => {
						if (this.gvcCountry === "") {
							return null;
						}
						if (params.data.MarketingRight) {
							let rights = params.data.MarketingRight.filter((mkright: MarketingGroup) => {
								return mkright.Name.includes(this.gvcCountry);
							});

							if (rights.length > 0) {
								if (rights.length > 1) {
									let name = "";
									rights = rights.filter((rght) => rght.Status.Action !== "").map((mk) => mk.Status.Action);

									rights.length > 0 ? (rights = rights.join()) : null;
									name = rights;
									return name;
								} else {
									return rights[0].Status.Action;
								}
							}
						}
					},
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (key === "DOB") {
				this.columnDefs.push({
					headerName: key,
					field: key,
					colId: key,
					hide: true,
					valueGetter: (params) => {
						if (params.data && params.data[key]) {
							return new Date(params.data[key]).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
						} else {
							return null;
						}
					},
					filter: "agDateColumnFilter",
					sortable: true,
					resizable: true,
					cellClass: "ExcelDate",
					cellRenderer: "dateRenderer",
					filterParams: {
						comparator: this.compareDate,
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (key === "DMS") {
				this.columnDefs.push({
					headerName: key.toUpperCase(),
					field: key,
					colId: key,
					hide: true,
					filter: "agTextColumnFilter",
					sortable: true,
					resizable: true,

					valueGetter: (params) => {
						if (params.data && params.data[key]) {
							return params.data[key].toString().replace(/^(\d{3})\D?(\d{3})/, "$1-$2");
						} else return null;
					},
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}

			this.columnDefs.push({
				headerName: key.toUpperCase(),
				field: key,
				colId: key,
				hide: false,
				filter: (typeof value === "number" ? "agNumberColumnFilter" : "agTextColumnFilter"),
				sortable: true,
				resizable: true,
				filterParams: {
					buttons: ["apply", "clear"],
				},
			});
		}

		this.gridApi.setColumnDefs(this.columnDefs);
		let artificialColumnState = this.columnDefs.map((def) => {
			if (def.colId === "Interim") {
				return {
					aggFunc: null,
					colId: def.colId,
					flex: 1,
					hide: true,
					pinned: null,
					pivotIndex: null,
					rowGroupIndex: null,
					width: 200,
				};
			} else {
				return {
					aggFunc: null,
					colId: def.colId,
					flex: 1,
					hide: false,
					pinned: null,
					pivotIndex: null,
					rowGroupIndex: null,
					width: 200,
				};
			}
		});
		this.gridColumnApi.setColumnState(artificialColumnState);
	}
	async checkForView(listName: string) {
		let user: User = await this.authService.getUserFromStorage();
		let storedListName = user.CustomLists.UserDefined.filter((storedList) => {
			return storedList.Name === listName;
		})[0];

		if (!this.bullListViewNames.includes(listName) && !listName.includes("GVC") && storedListName !== null && storedListName !== undefined) {
			this.listNameIsView = false;
		} else {
			this.listNameIsView = true;
		}
	}

	handleDropDown(from: string, open: boolean) {
		if (from === "addCat") {
			if (this.categoryGrid) {
				if (open) {
					this.categoryGrid.el.style.display = "";
				} else {
					this.categoryGrid.el.style.display = "none";
				}
			}

			for (let el of this.addCategoryColumns) {
				if (open) {
					el.el.style.display = "block";
				} else {
					el.el.style.display = "none";
				}
			}
			for (let element of this.addCategoryItems["_results"]) {
				if (open) {
					element.nativeElement.style.display = "block";
				} else {
					element.nativeElement.style.display = "none";
				}
			}
			if (open) {
				this.addCategoryContainer.nativeElement.style.border = "0.5px solid black";
				this.addCategoryContainer.nativeElement.style.width = "500px";
				this.addCategoryContainer.nativeElement.style.borderRadius = "5px";
				// this.addCategoryContainer.nativeElement.style.cursor = "pointer";
			} else {
				this.addCategoryContainer.nativeElement.style.backgroundColor = "white";
				this.addCategoryContainer.nativeElement.style.width = "219.08px";
				this.addCategoryContainer.nativeElement.style.zIndex = "100";
				this.addCategoryContainer.nativeElement.style.height = "auto";
				this.addCategoryContainer.nativeElement.style.border = "";
				this.addCategoryContainer.nativeElement.style.borderTop = "0.5px solid black";
				this.addCategoryContainer.nativeElement.style.borderRadius = "0px";
				this.addCategoryContainer.nativeElement.style.position = "absolute";
			}
		}
		if (from === "template") {
			if (this.templateGrid) {
				if (open) {
					this.templateGrid.el.style.display = "";
				} else {
					this.templateGrid.el.style.display = "none";
				}
			}

			for (let el of this.viewListItemColumns) {
				if (open) {
					el.el.style.display = "block";
				} else {
					el.el.style.display = "none";
				}
			}
			for (let element of this.bullTemplates["_results"]) {
				if (open) {
					element.nativeElement.style.display = "block";
				} else {
					element.nativeElement.style.display = "none";
				}
			}
			if (open) {
				this.bullTemplateContainer.nativeElement.style.border = "0.5px solid black";
				this.bullTemplateContainer.nativeElement.style.width = "950px";
				this.bullTemplateContainer.nativeElement.style.borderRadius = "5px";
				this.bullTemplateContainer.nativeElement.style.cursor = "pointer";
			} else {
				this.bullTemplateContainer.nativeElement.style.backgroundColor = "white";
				this.bullTemplateContainer.nativeElement.style.width = "219.08px";
				this.bullTemplateContainer.nativeElement.style.zIndex = "100";
				this.bullTemplateContainer.nativeElement.style.height = "auto";
				this.bullTemplateContainer.nativeElement.style.border = "";
				this.bullTemplateContainer.nativeElement.style.borderTop = "0.5px solid black";
				this.bullTemplateContainer.nativeElement.style.borderRadius = "0px";
				this.bullTemplateContainer.nativeElement.style.position = "absolute";
			}
		} else if (from === "load") {
			for (let element of this.loadListItems["_results"]) {
				if (open) {
					element.nativeElement.style.display = "block";
				} else {
					element.nativeElement.style.display = "none";
				}
			}

			if (open) {
				this.loadListContainer.nativeElement.style.border = "0.5px solid black";
				this.loadListContainer.nativeElement.style.width = "350px";
				this.loadListContainer.nativeElement.style.borderRadius = "5px";
				this.loadListContainer.nativeElement.style.cursor = "pointer";
			} else {
				this.loadListContainer.nativeElement.style.backgroundColor = "white";
				this.loadListContainer.nativeElement.style.width = "219.08px";
				this.loadListContainer.nativeElement.style.zIndex = "100";
				this.loadListContainer.nativeElement.style.height = "auto";
				this.loadListContainer.nativeElement.style.border = "";
				this.loadListContainer.nativeElement.style.borderTop = "0.5px solid black";
				this.loadListContainer.nativeElement.style.borderRadius = "0px";
				this.loadListContainer.nativeElement.style.position = "absolute";
			}
		}
	}
	clearImportData() {
		if (this.recall.importedDataDTO && this.recall.importedDataDTO.bulls.length > 0) {
			this.recall.importedDataDTO.bulls = [];
			this.bullImportService.currentImportedFileName = "";
			this.bullImportService.productFilters = [];
		}
	}
	async changeView(listName: string, listTitle: string) {
		this.recall.homeGrownIds = [];
		const baseTemplate: BullListView = await this.appService.GetSystemTemplate(listName);
		this.clearImportData();

		if (listName === "Price List") {
			// Specific to switching from any list to a Price List.
			this.priceService.openPriceList.next(true);
			this.recall.currentTemplateTitle = listTitle;
		}
		else if (baseTemplate.IsGvc) {
			// else if (this.recall.currentListName.includes("GVC") && !listTitle.includes("GVC")) {
			// Specifically switching to a GVC System Template from any other source template/list, even another GVC.
			this.recall.loadGVC.next({ load: true, import: false, refresh: false, source: listName });
		}
		else {
			// Specifically loading a vanilla system template, with or without a pre-chosen custom list...not a GVC.
			if (listName.includes("Right")) {
				if (!this.recall.currentMixins.includes("MarketingRight")) {
					this.recall.currentMixins.push("MarketingRight");
					this.recall.currentDisplayMixins.push("MarketingRight");
				}
			}
			this.progress.loading.next({ load: true, source: "change view start" });
			this.resetFilters();
			this.showFilters();
			// this.handleDropDown("template", false);
			this.recall.currentTemplateId = listName;
			this.recall.currentTemplateTitle = listTitle;
			await this.bullServiceApI.createBullList(this.recall.currentMixins, listName);
			await this.buildBullGrid();

			this.progress.loading.next({ load: false, source: "change view end" });
		}
	}
	async retrieveListDefinitionDescription() {
		let user: User = await this.authService.getUserFromStorage();
		let list: any;
		list = user.CustomLists.UserDefined.filter((list) => {
			return list.Name === this.recall.currentTemplateTitle;
		})[0];

		if (list) {
			this.currentListDescription = `Template: ${this.recall.currentTemplateId === "HomeGrownList" ? "Custom List" : this.bullServiceApI.formatTitleCase(this.recall.currentTemplateId)}\nImports: ${list.Definition.externalData && list.Definition.externalData.bulls.length > 0 ? "Yes" : "No"
				}`;
		} else {
			this.currentListDescription = `Template: ${this.recall.currentTemplateId === "HomeGrownList" ? "Custom List" : this.bullServiceApI.formatTitleCase(this.recall.currentTemplateId)}\nImports: ${this.recall.importedDataDTO && this.recall.importedDataDTO.bulls.length > 0 ? "Yes" : "No"
				}`;
		}
	}
	async onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.activatedRoute.data.subscribe(async (data: { data: IRecall }) => {
			const baseTemplate: BullListView = await this.appService.GetSystemTemplate(data.data.source);

			if (data.data.importing) {
				this.recall.loadListWithImports.next({
					source: data.data.source,
					settingDefault: data.data.settingDefault,
					importing: data.data.importing,
					listName: data.data.listName,
					columns: data.data.columns,
					filters: data.data.filters,
					sort: data.data.sort,
					group: data.data.group,
					mixins: this.recall.currentMixins,
				});
			} else {
				if (data.data.source === "HomeGrownList") {
					this.recall.loadBullsList.next({
						source: data.data.source,
						settingDefault: data.data.settingDefault,
						importing: data.data.importing,
						listName: data.data.listName,
						columns: data.data.columns,
						filters: data.data.filters,
						sort: data.data.sort,
						group: data.data.group,
						mixins: this.recall.currentMixins,
					});
				}
				else if (baseTemplate.IsGvc) {
					this.recall.loadGVC.next({ load: true, import: false, refresh: false, source: baseTemplate.Name });
				}
				else {
					this.recall.loadBullsList.next({
						source: data.data.source,
						settingDefault: data.data.settingDefault,
						importing: data.data.importing,
						listName: data.data.listName,
						columns: data.data.columns,
						filters: data.data.filters,
						sort: data.data.sort,
						group: data.data.group,
						mixins: this.recall.currentMixins,
					});
				}
			}
		});

		this.gridApi.refreshCells({ force: true });
	}
	addImportedValues(extraBullData?: ImportedBullsDTO, gvc: boolean = false) {
		if (extraBullData !== undefined && extraBullData !== null) {
			if (gvc) {
				for (let bull of extraBullData.bulls) {
					// filter this list first

					let bullRecords = this.recall.currentGVCList.filter((record) => {
						return (
							bull.columns
								.filter((col) => col.identifierColumn === true)[0]
								.columnValue.toString()
								.replace(/^([0]*)/, "")
								.toUpperCase()
								.trim() === record["Code#"].toString().toUpperCase().trim()
						);
					});

					if (bullRecords.length > 0) {
						for (let bullRecord of bullRecords) {
							for (let column of bull.columns) {
								if (column.importColumn) {
									bullRecord[`${column.columnName}`] = column.columnValue;
								}
							}
						}
					} else {
						bullRecords = this.recall.currentGVCList.filter((record) => {
							return bull.bullId === record.Id;
						});
						for (let bullRecord of bullRecords) {
							for (let column of bull.columns) {
								if (column.importColumn) {
									bullRecord[`${column.columnName}`] = column.columnValue;
								}
							}
						}
					}
				}
			} else {
				for (let bull of extraBullData.bulls) {
					// filter this list first

					for (let bullRecord of this.recall.currentBullsList.filter((record) => {
						if (this.recall.currentMixins.includes("Prices")) {
							return (
								bull.bullId === record.PrimaryNaabCode ||
								bull.bullId === record.RegId ||
								bull.bullId === record.SX2M_NaabCode ||
								bull.bullId === record.SX4M_NaabCode ||
								bull.bullId === record.Id
							);
						} else {
							return bull.bullId === record.PrimaryNaabCode || bull.bullId === record.RegId || bull.bullId === record.Id;
						}
					})) {
						for (let column of bull.columns) {
							if (column.importColumn) {
								bullRecord[`${column.columnName}`] = column.columnValue;
							}
						}
					}
				}
			}
		}
	}

	addImportedColumnDefinitions(extraBullData?: ImportedBullsDTO): ColDef[] {
		let cols = [];
		if (extraBullData == undefined || extraBullData == null || extraBullData.bulls.length == 0) {
			return cols;
		}

		this.ExtractImportedColumns(extraBullData).forEach(extraCol => {
			if (!extraCol.importColumn) {
				return;
			}
			let columnDefNames = this.columnDefs.map((def) => {
				return def.headerName;
			});

			if (columnDefNames.includes(extraCol.columnName.toUpperCase())) {
				return;
			}
			if (extraCol.columnType === "date") {
				this.AppendDateTypeColDef(cols, extraCol);
				return;
			}

			if (extraCol.columnType === "text") {
				this.AppendTextTypeColDef(cols, extraCol);
				return;
			}
			if (extraCol.columnType === "set") {
				this.AppendSetTypeColDef(cols, extraCol);
				return;
			}

			// Default "numeric" case.
			this.AppendNumericTypeColDef(cols, extraCol);

		});
		return cols;
	}


	/**
	 * Refactored out of addImportedColumnDefinitions to handle the assignment of column definitions for the Numeric type.
	 * @param cols
	 * @param extraCol
	 */
	private AppendNumericTypeColDef(cols: any[], extraCol: any): void {
		cols.push({
			headerName: this.FormatHeaderLabel(extraCol.columnName),
			field: extraCol.columnName,
			colId: extraCol.columnName,
			hide: false,
			sortable: true,
			filter: "agNumberColumnFilter",
			resizable: true,
			filterParams: {
				buttons: ["apply", "clear"],
			}
		});
	}


	/**
	 * Refactored out of addImportedColumnDefinitions to handle the assignment of column definitions for the Set type.
	 * @param cols
	 * @param extraCol
	 */
	private AppendSetTypeColDef(cols: any[], extraCol: any): void {
		cols.push({
			headerName: this.FormatHeaderLabel(extraCol.columnName),
			field: extraCol.columnName,
			hide: false,
			colId: extraCol.columnName,
			sortable: true,
			filter: true,
			resizable: true,
			filterParams: {
				buttons: ["apply", "clear"],
			}
		});
	}


	/**
	 * Refactored out of addImportedColumnDefinitions to handle the assignment of column definitions for the Text type.
	 * @param cols
	 * @param extraCol
	 */
	private AppendTextTypeColDef(cols: any[], extraCol: any): void {
		cols.push({
			headerName: this.FormatHeaderLabel(extraCol.columnName),
			field: extraCol.columnName,
			hide: false,
			colId: extraCol.columnName,
			sortable: true,
			filter: "agTextColumnFilter",
			resizable: true,
			filterParams: {
				buttons: ["apply", "clear"],
			}
		});
	}


	/**
	 * Refactored out of addImportedColumnDefinitions to handle the assignment of column definitions for the Date type.
	 * @param cols
	 * @param extraCol
	 */
	private AppendDateTypeColDef(cols: any[], extraCol: any): void {
		cols.push({
			headerName: this.FormatHeaderLabel(extraCol.columnName),
			field: extraCol.columnName,
			colId: extraCol.columnName,
			hide: false,
			sortable: true,
			filter: "agDateColumnFilter",
			valueGetter: (params) => {
				if (params.data && params.data[extraCol.columnName]) {
					if (typeof params.data[extraCol.columnName] === "number") {
						let date = params.data[extraCol.columnName];

						return new Date((date - (25567 + 1)) * 86400 * 1000).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
					} else {
						let date = params.data[extraCol.columnName];

						return new Date(date).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
					}
				} else {
					return null;
				}
			},
			resizable: true,
			filterParams: {
				buttons: ["apply", "clear"],
			}
		});
	}


	/**
	 * Extracts column definitions for imported columns from Excel.
	 * These column definitions are not exactly the same as AG Grid Column Defs.
	 * @param extraBullData
	 * @returns array of imported column definitions.
	 */
	private ExtractImportedColumns(extraBullData: ImportedBullsDTO) {
		let columnNames = [],
			extraColumns = extraBullData.bulls.reduce((result, bull) => {
				bull.columns.forEach(col => {
					if (!columnNames.includes(col.columnName) && col.importColumn) {
						result.push(col);
						columnNames.push(col.columnName);
					}
				});
				return result;
			}, []);
		return extraColumns;
	}


	// Grid instantiation function. Will fire off when the page is first loaded
	async buildBullGrid() {
		this.initializeRowData();
		this.initializeColumnDefs();
	}
	async filterOutXD() {
		if (this.gridApi) {
			let crossBreeds = ["XD", "XB", "XX"];
			let filterBreedSort: any = this.gridApi.getFilterInstance("BreedSort");
			let newValues: string[] = [];
			if (filterBreedSort && filterBreedSort.valueModel) {
				filterBreedSort.valueModel.selectedValues.forEach((element) => {
					!crossBreeds.includes(element) ? newValues.push(element) : null;
				});

				await filterBreedSort.setModel({ type: "set", values: newValues });
				this.gridApi.onFilterChanged();
			}
		}
	}


	/**
	 * Initializes the row data in the grid based on the current state.
	 * This function populates the row data in the grid by either removing existing rows and adding new ones, or resetting the row data if the grid API is not available.
	 */
	initializeRowData() {
		if (this.gridApi) {
			let rows = [];
			this.gridApi.forEachNode((row) => {
				row.data ? rows.push(row.data) : null;
			});

			this.gridApi.applyTransaction({ remove: rows });
			this.gridApi.applyTransaction({ add: this.recall.currentBullsList });
		} else {
			this.rowData = [];
			this.recall.currentBullsList.forEach((bull) => this.rowData.push(bull));
		}
	}
	async doubleClickedRow(event) {
		if (event.data && event.data.Id) {
			let rows = [];
			await this.gridApi.forEachNodeAfterFilterAndSort((node) => rows.push(node));
			if (rows[0].data) {
				let row = rows.filter((bull) => bull.data.Id === event.data.Id)[0];
				this.recall.clickedbull = event.data.Id;
				this.recall.clickedbullIndex = row.childIndex;
				this.recall.currentNodeList = rows;
			} else {
				let row;
				this.recall.currentNodeList = rows.filter((node) => node.data);
				for (let i = 0; i < this.recall.currentNodeList.length; i++) {
					if (this.recall.currentNodeList[i].data && this.recall.currentNodeList[i].data.Id === event.data.Id) {
						row = this.recall.currentNodeList[i];
						this.recall.clickedbullIndex = i;
					}
				}
				this.recall.clickedbull = event.data.Id;
			}

			this.recall.showBullForm.next({ show: true, fromList: true });
		}
	}
	doubleClickedCell(event) {
		let listOfIdColumns: string[] = ["RegId", "PrimaryNaabCode", "SireRegId", "SireNaabCode", "MGSireRegId", "MGSireNaabCode", "MGGSireRegId", "MGGSireNaabCode"];
		let listOfRegNames: string[] = ["MGSireRegName", "SireRegName", "MGGSireRegName", "RegName"];
		let clickedColumn: string = event.colDef.colId;
		if (listOfIdColumns.includes(clickedColumn)) {
			this.recall.clickedbull = event.value;
		}
		for (let regName of listOfRegNames) {
			if (regName === event.colDef.colId) {
				switch (regName) {
					case "MGSireRegName":
						this.recall.clickedbull = event.data.MGSireRegId || event.data.MGSireNaabCode;
						break;
					case "MGGSireRegName":
						this.recall.clickedbull = event.data.MGGSireRegId || event.data.MGGSireNaabCode;
						break;
					case "SireRegName":
						this.recall.clickedbull = event.data.SireRegId || event.data.SireNaabCode;
						break;
					case "RegName":
						this.recall.clickedbull = event.data.MGSireRegId || event.data.PrimaryNaabCode;
						break;
				}
			}
		}

		this.recall.showBullForm.next({ show: true, fromList: true });
	}

	initializeColumnDefs(addingColumns: boolean = false) {
		this.columnDefs = [];

		for (let mix of this.recall.currentMixins) {
			let columns = columnDefiner;

			let obj = Object.entries(columns[mix.replace(/\s/g, "")]);

			this.createColumnDefs(obj, mix);
		}

		if (addingColumns) {
		} else {
			if (this.gridColumnApi) {
				let columnState = this.gridColumnApi.getColumnState();

				this.visibleColumns = columnState.filter((col) => {
					return col.hide === false;
				});

				this.hiddenColumns = columnState.filter((col) => {
					return col.hide === true;
				});

				this.hiddenColumns.sort((a, b) => a.colId.localeCompare(b.colId));

				this.gridColumnApi.setColumnState(this.visibleColumns.concat(this.hiddenColumns));
			} else {
				this.visibleColumns = this.columnDefs.filter((col) => col.hide === false);
				this.hiddenColumns = this.columnDefs.filter((col) => col.hide === true);
				this.hiddenColumns.sort((a, b) => a.headerName.localeCompare(b.headerName));
				this.columnDefs = this.visibleColumns.concat(this.hiddenColumns);
			}
		}
	}
	changeDarkMode() {
		this.darkMode = !this.darkMode;
	}

	compareDate(dateFromGrid, cellvalue) {
		let gridDate = new Date(dateFromGrid).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");

		if (cellvalue) {
			if (cellvalue < gridDate) {
				return -1;
			} else if (cellvalue > gridDate) {
				return 1;
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	async createColumnDefs(obj: any, mix: string) {

		let category: Object[] = [];
		let traits: Object[] = [];
		let trait: Object[] = []

		if (mix.includes("_GBR")) {
			category = this.proofMeta.find(category => category["Name"] === mix);

			if (category) {
				traits = category["Traits"];

				for (let [key, value] of obj) {
					trait = traits.filter(trait => trait["LegacyName"] === key);

					if (trait.length > 0) {
						let metaObj = {};
						metaObj["HeaderLabel"] = trait[0]["HeaderLabel"];

						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(metaObj["HeaderLabel"]),
							field: key,
							colId: key,
							hide: true,
							filter: "agNumberColumnFilter",
							sortable: true,
							resizable: true,
							filterParams: {
								buttons: ["apply", "clear"],
							},
						});
					}
				}
			}
		}

		if (mix === "Braunvieh_DEU") {
			category = this.proofMeta.filter(category => category["Name"] === mix);

			if (category.length > 0) {
				traits = category[0]["Traits"];

				for (let [key, value] of obj) {
					trait = traits.filter(trait => trait["LegacyName"] === key);

					if (trait.length > 0) {
						let metaObj = {};
						metaObj["HeaderLabel"] = trait[0]["HeaderLabel"];

						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(metaObj["HeaderLabel"]),
							field: key,
							colId: key,
							hide: true,
							filter: "agNumberColumnFilter",
							sortable: true,
							resizable: true,
							filterParams: {
								buttons: ["apply", "clear"],
							},
						});
					}
				}
			}
		}

		if (mix === "MarketingRight") {
			this.marketingRightCountry === "" ? (this.marketingRightCountry = this.recall.currentTemplateId.replace(/([A-Z]{1})([a-z]+)([A-Z]{1}).*/, "$1$2")) : null;
			this.bullServiceApI.uniqueCountries.includes(this.marketingRightCountry) ? null : (this.marketingRightCountry = "");
			for (let [key, value] of obj) {
				if (key.match(/Date$/)) {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: false,
						valueGetter: (params) => {
							if (params.data) {
								if (this.marketingRightCountry === "") {
									return null;
								}
								if (params.data.MarketingRights) {
									let rights = params.data.MarketingRights.filter((mkright: MarketingGroup) => {
										return mkright.Name.includes(this.marketingRightCountry);
									});

									if (rights.length === 0)
										return "";

									let dateValues = [];
									rights.forEach(right => {
										if (!right.Status.ActionDate)
											return;
										dateValues.push(right.Status.ActionDate);
									});
									return FormatDatesAsList(dateValues);
								}
							}
						},
						filter: "agDateColumnFilter",
						sortable: true,
						resizable: true,
						// cellRenderer: "dateRenderer",
						filterParams: {
							comparator: this.compareDate,
							buttons: ["apply", "clear"],
						},
					});
				} else if (key.match(/Action$/)) {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: false,
						sortable: true,
						filter: true,
						resizable: true,
						valueGetter: (params) => {
							if (params.data) {
								if (this.marketingRightCountry === "") {
									return null;
								}
								if (params.data.MarketingRights) {
									let rights = params.data.MarketingRights.filter((mkright: MarketingGroup) => {
										return mkright.Name.includes(this.marketingRightCountry);
									});

									if (rights.length > 0) {
										if (rights.length > 1) {
											let name = "";
											rights = rights.filter((rght) => rght.Status.Action !== "").map((mk) => mk.Status.Action);

											rights.length > 0 ? (rights = rights.join()) : null;
											name = rights;
											return name;
										} else {
											return rights[0].Status.Action;
										}
									}
								}
							}
						},
						filterParams: {
							buttons: ["apply", "clear"],
						},
					});
				} else {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: false,
						sortable: true,
						filter: true,
						resizable: true,
						valueGetter: (params) => {
							if (params.data) {
								if (this.marketingRightCountry === "") {
									return null;
								}
								if (params.data.MarketingRights) {
									let rights = params.data.MarketingRights.filter((mkright: MarketingGroup) => {
										return mkright.Name.includes(this.marketingRightCountry);
									});

									if (rights.length > 0) {
										if (rights.length > 1) {
											let name = "";
											rights = rights.map((mk) => mk.Name);
											rights = rights.join();
											name = rights;
											return name;
										} else {
											return rights[0].Name;
										}
									}
								}
							}
						},
						filterParams: {
							buttons: ["apply", "clear"],
						},
					});
				}
			}
		}

		for (let [key, value] of obj) {
			if (mix === "Braunvieh_DEU") {
				continue;
			}
			if (mix.includes("_GBR")) {
				continue;
			}
			if (mix === "MarketingRight") {
				continue;
			}
			if (this.columnsToSkip.includes(key)) {
				continue;
			}
			if (mix === "BeefMale") {
				if (key.match(/^SX2M|^SX4M/)) {
					if (typeof value === "number") {
						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(key),
							field: key,
							colId: key,
							hide: true,
							filter: "agNumberColumnFilter",
							valueGetter: (params) => {
								if (params.data) {
									let discountGroup;
									let fullEjac;
									if (key.match(/^SX2M/)) {
										discountGroup = params.data["SX2MBM_Discount_Group"];
										fullEjac = params.data["SX2MBM_Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											let value = params.data[key];
											for (let field of this.priceFieldsToHide) {
												if (key.includes(field)) {
													value = null;
												}
											}
											return value;
										} else {
											return params.data[key];
										}
									}
									if (key.match(/^SX4M/)) {
										discountGroup = params.data["SX4MBM_Discount_Group"];
										fullEjac = params.data["SX4MBM_Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											let value = params.data[key];
											for (let field of this.priceFieldsToHide) {
												if (key.includes(field)) {
													value = null;
												}
											}
											return value;
										} else {
											return params.data[key];
										}
									}
								}
							},
							sortable: true,
							resizable: true,

							filterParams: {
								buttons: ["apply", "clear"],
							},
						});
						continue;
					} else if (typeof value === "string") {
						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(key),
							field: key,
							colId: key,
							hide: true,
							filter: "agTextColumnFilter",
							valueGetter: (params) => {
								if (params.data) {
									let discountGroup;
									let fullEjac;
									if (key.match(/^SX2M/)) {
										discountGroup = params.data["SX2MBM_Discount_Group"];
										fullEjac = params.data["SX2MBM_Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											let value = params.data[key];
											for (let field of this.priceFieldsToHide) {
												if (key.includes(field)) {
													value = null;
												}
											}
											return value;
										} else {
											return params.data[key];
										}
									}
									if (key.match(/^SX4M/)) {
										discountGroup = params.data["SX4MBM_Discount_Group"];
										fullEjac = params.data["SX4MBM_Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											let value = params.data[key];
											for (let field of this.priceFieldsToHide) {
												if (key.includes(field)) {
													value = null;
												}
											}
											return value;
										} else {
											return params.data[key];
										}
									}
								}
							},
							sortable: true,
							resizable: true,

							filterParams: {
								buttons: ["apply", "clear"],
							},
						});
						continue;
					}
				}
			}
			if (mix === "Prices") {
				// if (this.recall.currentTemplateTitle.toLowerCase().match(/^external/)) {
				let field = this.priceFieldsToHide.filter((fld) => fld === key)[0];

				// Added selectable filtering for EC code columns instead of default text filtering.
				if (key.includes("EC")) {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: true,
						sortable: true,
						resizable: true,
						valueGetter: (params) => {
							if (params.data) {
								if (this.listOptions.showAdditionalProductData) {
									return params.data[key];
								} else {
									let discountGroup;
									let fullEjac;

									if (key === "EC") {
										discountGroup = params.data["Discount_Group"];
										fullEjac = params.data["Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											let value = params.data[key];
											for (let field of this.priceFieldsToHide) {
												if (key.includes(field)) {
													value = null;
												}
											}
											return value;
										} else {
											return params.data[key];
										}
									}

									if (key.match(/^SX2M/)) {
										discountGroup = params.data["SX2M_Discount_Group"];
										fullEjac = params.data["SX2M_Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											let value = params.data[key];
											for (let field of this.priceFieldsToHide) {
												if (key.includes(field)) {
													value = null;
												}
											}
											return value;
										} else {
											return params.data[key];
										}
									}

									if (key.match(/^SX4M/)) {
										discountGroup = params.data["SX4M_Discount_Group"];
										fullEjac = params.data["SX4M_Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											let value = params.data[key];
											for (let field of this.priceFieldsToHide) {
												if (key.includes(field)) {
													value = null;
												}
											}
											return value;
										} else {
											return params.data[key];
										}
									}
								}
							}
						},
						filter: true,
						filterParams: {
							buttons: ["apply", "clear"],
						},
					});

					continue;
				}

				if (field) {
					if (typeof value === "number") {
						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(key),
							field: key,
							colId: key,
							hide: true,
							filter: "agNumberColumnFilter",
							valueGetter: (params) => {
								if (params.data) {
									if (this.listOptions.showAdditionalProductData) {
										return params.data[key];
									} else {
										let discountGroup = params.data["Discount_Group"];
										let fullEjac = params.data["Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											return null;
										} else {
											return params.data[key];
										}
									}
								}
							},
							sortable: true,
							resizable: true,
							filterParams: {
								buttons: ["apply", "clear"],
							},
						});

						continue;
					} else if (typeof value === "string") {
						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(key),
							field: key,
							colId: key,
							hide: true,
							filter: "agTextColumnFilter",
							valueGetter: (params) => {
								if (params.data) {
									if (this.listOptions.showAdditionalProductData) {
										return params.data[key];
									} else {
										let discountGroup = params.data["Discount_Group"];
										let fullEjac = params.data["Full_Ejac"];
										if (!discountGroup && !fullEjac) {
											return null;
										} else {
											return params.data[key];
										}
									}
								}
							},
							sortable: true,
							resizable: true,
							filterParams: {
								buttons: ["apply", "clear"],
							},
						});
						continue;
					}
				}

				if (key.match(/^SX2M|^SX4M/)) {
					if (typeof value === "number") {
						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(key),
							field: key,
							colId: key,
							hide: true,
							filter: "agNumberColumnFilter",
							valueGetter: (params) => {
								if (params.data) {
									if (this.listOptions.showAdditionalProductData) {
										return params.data[key];
									} else {
										let discountGroup;
										let fullEjac;
										if (key.match(/^SX2M/)) {
											discountGroup = params.data["SX2M_Discount_Group"];
											fullEjac = params.data["SX2M_Full_Ejac"];
											if (!discountGroup && !fullEjac) {
												let value = params.data[key];
												for (let field of this.priceFieldsToHide) {
													if (key.includes(field)) {
														value = null;
													}
												}
												return value;
											} else {
												return params.data[key];
											}
										}
										if (key.match(/^SX4M/)) {
											discountGroup = params.data["SX4M_Discount_Group"];
											fullEjac = params.data["SX4M_Full_Ejac"];
											if (!discountGroup && !fullEjac) {
												let value = params.data[key];
												for (let field of this.priceFieldsToHide) {
													if (key.includes(field)) {
														value = null;
													}
												}
												return value;
											} else {
												return params.data[key];
											}
										}
									}
								}
							},
							sortable: true,
							resizable: true,

							filterParams: {
								buttons: ["apply", "clear"],
							},
						});
						continue;
					} else if (typeof value === "string") {
						this.columnDefs.push({
							headerName: this.FormatHeaderLabel(key),
							field: key,
							colId: key,
							hide: true,
							filter: "agTextColumnFilter",
							valueGetter: (params) => {
								if (params.data) {
									if (this.listOptions.showAdditionalProductData) {
										return params.data[key];
									} else {
										let discountGroup;
										let fullEjac;
										if (key.match(/^SX2M/)) {
											discountGroup = params.data["SX2M_Discount_Group"];
											fullEjac = params.data["SX2M_Full_Ejac"];
											if (!discountGroup && !fullEjac) {
												let value = params.data[key];
												for (let field of this.priceFieldsToHide) {
													if (key.includes(field)) {
														value = null;
													}
												}
												return value;
											} else {
												return params.data[key];
											}
										}
										if (key.match(/^SX4M/)) {
											discountGroup = params.data["SX4M_Discount_Group"];
											fullEjac = params.data["SX4M_Full_Ejac"];
											if (!discountGroup && !fullEjac) {
												let value = params.data[key];
												for (let field of this.priceFieldsToHide) {
													if (key.includes(field)) {
														value = null;
													}
												}
												return value;
											} else {
												return params.data[key];
											}
										}
									}
								}
							},
							sortable: true,
							resizable: true,

							filterParams: {
								buttons: ["apply", "clear"],
							},
						});
						continue;
					}
				}
			}
			if (mix === "MarketingGroup") {
				if (key.match(/Date$/)) {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: true,
						filter: "agDateColumnFilter",
						valueGetter: (params) => {
							if (params.data && params.data[key]) {
								return new Date(params.data[key]).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
							} else {
								return null;
							}
						},
						sortable: true,
						resizable: true,
						cellRenderer: "dateRenderer",
						cellClass: "ExcelDate",
						filterParams: {
							comparator: this.compareDate,
							buttons: ["apply", "clear"],
						},
					});
					continue;
				}
			}

			if (key === "DateOfBirth") {
				this.columnDefs.push({
					headerName: this.FormatHeaderLabel(key),
					field: key,
					colId: key,
					hide: true,
					filter: "agDateColumnFilter",
					valueGetter: (params) => {
						if (params.data && params.data[key]) {
							return new Date(params.data[key]).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
						} else {
							return null;
						}
					},
					sortable: true,
					resizable: true,
					cellRenderer: "dateRenderer",
					cellClass: "ExcelDate",
					filterParams: {
						comparator: this.compareDate,
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (key === "HousedInCountry") {
				this.columnDefs.push({
					headerName: this.FormatHeaderLabel(key),
					field: key,
					colId: key,
					hide: true,
					filter: true,
					sortable: true,
					resizable: true,
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (key === "aAa") {
				this.columnDefs.push({
					headerName: key.toUpperCase(),
					field: key,
					colId: key,
					hide: true,
					filter: "agTextColumnFilter",
					sortable: true,
					resizable: true,
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (key === "DMS") {
				this.columnDefs.push({
					headerName: key.toUpperCase(),
					field: key,
					colId: key,
					hide: true,
					filter: "agTextColumnFilter",
					sortable: true,
					resizable: true,

					valueGetter: (params) => {
						if (params.data && params.data[key]) {
							return params.data[key].toString().replace(/^(\d{3})\D?(\d{3})/, "$1-$2");
						} else return null;
					},
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (this.columnsWithSelectFilter.includes(key)) {
				this.columnDefs.push({
					headerName: this.FormatHeaderLabel(key),
					field: key,
					colId: key,
					hide: true,
					sortable: true,
					filter: true,
					resizable: true,
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}

			if (mix === "Base") {
				if (key.match(/Date$/)) {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: true,
						filter: "agDateColumnFilter",
						valueGetter: (params) => {
							if (params.data && params.data[key]) {
								return new Date(params.data[key]).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
							} else {
								return null;
							}
						},
						sortable: true,
						resizable: true,
						cellRenderer: "dateRenderer",
						cellClass: "ExcelDate",
						filterParams: {
							comparator: this.compareDate,
							buttons: ["apply", "clear"],
						},
					});
					continue;
				}
				if (key === "PrimaryNaabCode") {
					this.columnDefs.push({
						headerName: `Naab Code`.toUpperCase(),
						field: key,
						colId: key,
						hide: false,
						sortable: true,
						filter: "agTextColumnFilter",
						resizable: true,
						filterParams: {
							buttons: ["apply", "clear"],
						},
					});
					continue;
				}

				if (key === "RegId") {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: false,
						sortable: true,
						resizable: true,
						filterParams: {
							buttons: ["apply", "clear"],
						},
					});
					continue;
				}
				if (typeof value === "number") {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: false,
						sortable: true,
						filter: "agNumberColumnFilter",
						resizable: true,
						filterParams: {
							buttons: ["apply", "clear"],
						},
					});
				} else {
					this.columnDefs.push({
						headerName: this.FormatHeaderLabel(key),
						field: key,
						colId: key,
						hide: false,
						sortable: true,
						filter: "agTextColumnFilter",
						resizable: true,
						filterParams: {
							buttons: ["apply", "clear"],
						},
					});
				}
				//END OF FOR LOOP
				continue;
			}
			if (key.match(/^Top.*/)) {
				this.columnDefs.push({
					headerName: this.FormatHeaderLabel(key),
					field: key,
					colId: key,
					hide: true,
					cellRenderer: "dateRenderer",
					cellClass: "ExcelDate",
					filter: "agDateColumnFilter",
					valueGetter: (params) => {
						if (params.data && params.data[key]) {
							return new Date(params.data[key]).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
						} else {
							return null;
						}
					},
					sortable: true,
					resizable: true,

					filterParams: {
						comparator: this.compareDate,
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}

			if (key.match(/Date$/)) {
				this.columnDefs.push({
					headerName: this.FormatHeaderLabel(key),
					field: key,
					colId: key,
					hide: true,
					filter: "agDateColumnFilter",
					valueGetter: (params) => {
						if (params.data && params.data[key]) {
							return new Date(params.data[key]).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
						} else {
							return null;
						}
					},
					sortable: true,
					resizable: true,
					cellClass: "ExcelDate",
					cellRenderer: "dateRenderer",
					filterParams: {
						comparator: this.compareDate,
						buttons: ["apply", "clear"],
					},
				});
				continue;
			}
			if (typeof value === "number") {
				this.columnDefs.push({
					headerName: this.FormatHeaderLabel(key),
					field: key,
					colId: key,
					hide: true,
					sortable: true,
					filter: "agNumberColumnFilter",
					resizable: true,
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
			} else {
				this.columnDefs.push({
					headerName: this.FormatHeaderLabel(key),
					field: key,
					colId: key,
					hide: true,
					sortable: true,
					filter: "agTextColumnFilter",
					resizable: true,
					filterParams: {
						buttons: ["apply", "clear"],
					},
				});
			}
		}

		let mixinsWithFormatting = ["Daughter Fertility", "Indexes", "Management", "Production", "Sire Fertility", "Type", "Wellness Traits", "Production_DEU"];
		if (mixinsWithFormatting.includes(mix)) {
			let appMeta = this.bullServiceApI.applicationMeta;
			let metaCategory: any = appMeta.Proof.find((category) => {
				return category.Name === mix;
			});
			if (metaCategory) {
				for (let [key, value] of obj) {
					if (key.match(/_rel$|^rel_/)) {
						continue;
					}
					if (typeof value === "number") {
						let trait = metaCategory.Traits.filter((trait) => {
							return trait.LegacyName === key;
						})[0];
						if (trait) {
							let queryColumnDef = this.columnDefs.filter((col) => {
								return col.colId === trait.LegacyName;
							})[0];
							if (trait.Format.match(/^decimal/)) {
								let decimalPlaces = trait.Format.replace(/^(decimal\(\d,)(\d)(\))/, "$2");
								decimalPlaces = Number(decimalPlaces);
								// decimalPlaces > 0 ? (queryColumnDef.cellClass = "ExcelDecimal") : null;
								queryColumnDef.cellClass = (params) => {
									if (params.value !== null) {
										if (decimalPlaces == 1) {
											return "ExcelDecimalPlace1";
										} else if (decimalPlaces == 2) {
											return "ExcelDecimalPlace2";
										} else {
											return null;
										}
									}
								};
								queryColumnDef.valueFormatter = (params) => {
									if (params.data && typeof params.data[trait.LegacyName] === "number") {
										let numberToReturn: number = params.data[trait.LegacyName].toFixed(decimalPlaces);
										if (numberToReturn) {
											return numberToReturn;
										} else {
											return params.value;
										}
									} else {
										return params.value;
									}
								};
							}
						}
					}
				}
			}
		}

		await this.filterOutXD();
	}
	resetFilters() {
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();
	}

	searchCurrentBullList() {
		let searchArray = this.recall.naabCodesSearch.trim().split(/[\n|\r]/);
		let unpaddedArray = searchArray.map((naab) => naab.replace(/^[0,]*/, "").toUpperCase());
		this.currentNaabFilters = unpaddedArray;
		let filterNaabCodes = this.gridApi.getFilterInstance("PrimaryNaabCode");

		filterNaabCodes.setModel({ values: unpaddedArray });

		this.gridApi.onFilterChanged();
	}
	searchRegIds() {
		let regIdSearchArray = this.recall.regIdSearch.trim().split(/[\n|\r]/);
		this.currentRegIdFilters = regIdSearchArray;
		let regIdFilter = this.gridApi.getFilterInstance("RegId");
		regIdFilter.setModel({ values: regIdSearchArray });
		this.gridApi.onFilterChanged();
	}
	clearNaabFilter() {
		let filterNaabCodes = this.gridApi.getFilterInstance("PrimaryNaabCode");
		if (filterNaabCodes) {
			filterNaabCodes.setModel({
				values: this.recall.currentBullsList.map((bull) => bull.PrimaryNaabCode),
			});
			filterNaabCodes.destroy();
			this.gridApi.onFilterChanged();
			this.recall.naabCodesSearch = "";
		}
	}
	clearRegIdFilter() {
		let regIdFilters = this.gridApi.getFilterInstance("RegId");
		if (regIdFilters) {
			regIdFilters.setModel({
				values: this.recall.currentBullsList.map((bull) => bull.RegId),
			});
			regIdFilters.destroy();
			this.gridApi.onFilterChanged();
			this.recall.regIdSearch = "";
		}
	}
	async getUserLists() {
		let user: User = await this.authService.getUserFromStorage();
		let userLists = user.CustomLists.UserDefined;
		userLists = userLists.sort((a, b) => {
			return a.Name.localeCompare(b.Name);
		});
		this.userLists = userLists;
	}

	formatExcelExport() {
		let params = {
			fileName: this.recall.currentTemplateTitle,
			sheetName: this.recall.currentTemplateTitle,
			// processCellCallback: (params) => {
			// 	let value: string;
			// 	let mixinsWithFormatting = ["Daughter Fertility", "Indexes", "Management", "Production", "Sire Fertility", "Type", "Wellness Traits"];
			// 	this.recall.currentMixins.forEach((mix) => {
			// 		if (mixinsWithFormatting.includes(mix)) {
			// 			let appMeta = this.bullServiceApI.applicationMeta;
			// 			let metaCategory: any = appMeta.Proof.filter((category) => {
			// 				return category.Name === mix;
			// 			})[0];
			// 			if (metaCategory) {
			// 				let key = params.column.colId;
			// 				let trait = metaCategory.Traits.filter((trait) => {
			// 					return trait.LegacyName === key;
			// 				})[0];
			// 				if (trait) {
			// 					if (trait.Format.match(/^decimal/)) {
			// 						let decimalPlaces = trait.Format.replace(/^(decimal\(\d,)(\d)(\))/, "$2");
			// 						decimalPlaces = Number(decimalPlaces);

			// 						if (params.node.data && !isNaN(params.node.data[trait.LegacyName])) {
			// 							let numberToReturn: string = params.node.data[trait.LegacyName].toFixed(decimalPlaces);
			// 							if (numberToReturn.length > 0) {
			// 								value = numberToReturn;
			// 							}
			// 						}
			// 					}
			// 				}
			// 			}
			// 		}
			// 	});
			// 	!value ? (value = params.value) : null;
			// 	return value;
			// },
		};

		this.gridApi.exportDataAsExcel(params);
	}
}
