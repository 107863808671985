import { NgModule } from "@angular/core";

import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomeComponent } from "./home/home.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgForageOptions, DEFAULT_CONFIG, Driver } from "ngforage";
import { LoginPageComponent } from "./login/login-page.component";
import { BullListModule } from "./bull-list/bull-list.module";
import { UtilsModule } from "./utils/utils.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AuthInterceptor } from "./auth/auth-interceptor";
import { DocumentUploadComponent } from "./document-upload/document-upload.component";
import { MatIconModule } from "@angular/material/icon";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatSelectModule } from "@angular/material/select";
import { MatListModule } from "@angular/material/list";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { AppService } from "./app.service";
@NgModule({
    declarations: [AppComponent, LoginPageComponent, HomeComponent, DocumentUploadComponent],
    imports: [
        BrowserModule,
        BullListModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        UtilsModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        FontAwesomeModule,
        MatDividerModule,
        MatCardModule,
        MatSelectModule,
        MatListModule,
        MatChipsModule,
        MatStepperModule,
        MatProgressBarModule,
    ],
    providers: [
        StatusBar,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: DEFAULT_CONFIG,
            useValue: {
                name: "admin-tools",
                driver: [
                    // defaults to indexedDB -> webSQL -> localStorage
                    Driver.INDEXED_DB,
                    Driver.LOCAL_STORAGE,
                ],
            } as NgForageOptions,
        },
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		AppService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
