import { Component, OnInit, Input, Output, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { ImportModalPage } from "./import-modal-page.component";
import { ImportedBullsDTO } from "../bull-list.model";
import { Subscription } from "rxjs";
import { BullImportService } from "./bull-import.service";
//COMPONENT THAT CONTROLS MODAL
@Component({
	selector: "import-modal",
	templateUrl: "./import-modal.component.html",
	styleUrls: ["./import-modal.component.scss"],
})
export class ImportModalComponent implements OnInit, OnDestroy {
	public importingSub: Subscription;
	constructor(public modalController: ModalController, private bullImportService: BullImportService) {
		this.importingSub = bullImportService.importing.subscribe((importing: boolean) => {
			if (importing) this.presentModal();
			else this.modalController.dismiss();
		});
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: ImportModalPage,
			cssClass: "my-import-class",
			backdropDismiss: false,
		});

		return await modal.present();
	}

	ngOnInit(): void {}
	ngOnDestroy() {
		this.importingSub.unsubscribe();
	}
}
