import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { User, UserManagementService } from "../../login/user-management.service";
import { API_URL, ENDPOINT } from "../../../environments/environment";

export interface IShareUser {
	Email?: string;
	FirstName?: string;
	Id?: string;
	LastName?: string;
	Type?: string;
	ShareWith?: boolean; // this property is not delivered in the payload and is used to populate the checkboxes
}

@Injectable({
	providedIn: "root",
})
export class ListShareService {
	openListSharing: Subject<boolean> = new Subject();

	constructor(public http: HttpClient, public userManager: UserManagementService) {}

	async getUsersToShareWith() {
		return this.http.get<IShareUser[]>(API_URL + ENDPOINT.shareList).toPromise();
	}
	async shareListWithUsers(listGuid: string, sharedUsersToUpdate: string[]) {
		return this.http
			.post<User>(API_URL + ENDPOINT.shareList, {
				listId: listGuid,
				sharedUsers: sharedUsersToUpdate,
			})
			.toPromise();
	}
}
