import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { Subscription } from "rxjs";
import { PriceService } from "../../price/price.service";
import { AlertService } from "../../utils/alerts/alert.service";
import { BullListRecallService } from "../bull-list-recall.service";
import { PriceListPageComponent } from "./price-list-page.component";

@Component({
	selector: "price-list",
	templateUrl: "price-list.component.html",
	styleUrls: ["./price-list.component.scss"],
})
export class PriceListModalComponent {
	priceListSub: Subscription;
	constructor(public modalController: ModalController, public recall: BullListRecallService, public priceService: PriceService) {
		this.priceListSub = this.priceService.openPriceList.subscribe((open) => {
			if (open) {
				this.presentModal();
			} else {
				this.modalController.dismiss();
			}
		});
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: PriceListPageComponent,
			cssClass: "change-modal",
			backdropDismiss: false,
		});

		return await modal.present();
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.priceListSub.unsubscribe();
	}
}
