import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BullListComponent } from "./bull-list.component";
import { BullService } from "../bull/bull.service";
import { BullFormComponent } from "./bull-form/bull-form.component";
import { FormsModule } from "@angular/forms";
import { BullFormModalPage } from "./bull-form/bull-form-modal.component";
import "ag-grid-enterprise";
import { AgGridModule } from "ag-grid-angular";
import { BullListRecallService } from "./bull-list-recall.service";
import { IonicModule } from "@ionic/angular";
import { PriceService } from "../price/price.service";
import { ImportModalComponent } from "./import-modal/import-modal.component";
import { ImportModalPage } from "./import-modal/import-modal-page.component";
import { BullImportService } from "./import-modal/bull-import.service";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { UtilsModule } from "../utils/utils.module";
import { BullFormDataService } from "./bull-form/bull-form-data.service";
import { HomeGrownModalComponent } from "./home-grown/home-grown.component";
import { HomeGrownModalPageComponent } from "./home-grown/home-grown-page.component";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { DocumentModalComponent } from "./documents-modal/documents-modal.component";
import { DocumentModalPage } from "./documents-modal/documents-modal-page.component";
import { DateRenderer } from "./date-renderer.component";
import { HomeGrownModalChangeComponent } from "./home-grown/home-grown-change.component";
import { PriceListPageComponent } from "./price-list/price-list-page.component";
import { PriceListModalComponent } from "./price-list/price-list.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { BullListOptionsService } from "../bull-list-options/bull-list-options.service";
import { ListShareModalComponent } from "./list-share/list-share-modal.component";
import { ListSharePageComponent } from "./list-share/list-share-page.component";
import { ListShareService } from "./list-share/list-share.service";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { CategoryModalComponent } from "./category-modal/category-modal.component";
import { CategoryPageComponent } from "./category-modal/category-page.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatGridListModule } from "@angular/material/grid-list";
@NgModule({
	declarations: [
		BullListComponent,
		BullFormComponent,
		BullFormModalPage,
		ImportModalComponent,
		ImportModalPage,
		HomeGrownModalComponent,
		HomeGrownModalPageComponent,
		DocumentModalComponent,
		DocumentModalPage,
		DateRenderer,
		HomeGrownModalChangeComponent,
		PriceListModalComponent,
		PriceListPageComponent,
		ListShareModalComponent,
		ListSharePageComponent,
		CategoryModalComponent,
		CategoryPageComponent,
	],
	imports: [
		CommonModule,
		AgGridModule.withComponents([]),
		FormsModule,
		IonicModule,
		UtilsModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatInputModule,
		MatCheckboxModule,
		MatRadioModule,
		MatButtonToggleModule,
		MatButtonModule,
		MatDividerModule,
		MatFormFieldModule,
		MatMenuModule,
		MatTabsModule,
		MatGridListModule,
	],
	exports: [
		BullListComponent,
		BullFormComponent,
		BullFormModalPage,
		ImportModalComponent,
		ImportModalPage,
		HomeGrownModalComponent,
		HomeGrownModalPageComponent,
		MatDatepickerModule,
		DocumentModalComponent,
		DocumentModalPage,
		DateRenderer,
		HomeGrownModalChangeComponent,
		PriceListModalComponent,
		PriceListPageComponent,
		MatCheckboxModule,
		MatRadioModule,
		MatButtonToggleModule,
		MatButtonModule,
		MatDividerModule,
		ListShareModalComponent,
		ListSharePageComponent,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatTabsModule,
		MatGridListModule,
	],
	providers: [BullService, PriceService, BullImportService, BullListRecallService, ProgressBarService, BullFormDataService, MatDatepickerModule, BullListOptionsService, ListShareService],
	// schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class BullListModule {}
