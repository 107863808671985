import { Component, OnInit, Input, OnDestroy, ViewChildren, QueryList, AfterViewInit, Output, ViewChild } from "@angular/core";
import { ModalController, IonSelectOption, IonSelect } from "@ionic/angular";

import { IExtraBull, ImportedBullsDTO } from "../bull-list.model";
import { BullImportService } from "./bull-import.service";
import { BullListRecallService } from "../bull-list-recall.service";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatRadioButton } from "@angular/material/radio";
import { AlertService } from "../../utils/alerts/alert.service";
import { Binary } from "@angular/compiler";

//MODAL COMPONENT
@Component({
	selector: "import-modal-page",
	templateUrl: "./import-modal-page.component.html",
	styleUrls: ["./import-modal-page.component.scss"],
})
export class ImportModalPage implements OnInit, OnDestroy, AfterViewInit {
	step: number = 1;

	@ViewChild("importFile") importFileInput;
	@ViewChildren("checkboxValues") selectOptions: QueryList<MatCheckbox>;
	@ViewChildren("radioValues") idRadios: QueryList<MatRadioButton>;

	constructor(private modalController: ModalController, public bullImportService: BullImportService, private recall: BullListRecallService, private alert: AlertService) {}

	ngOnInit() {}
	goToColumnSelect() {
		this.step === 4 ? null : (this.step += 1);
	}
	decreaseStep() {
		this.step === 1 ? null : (this.step -= 1);
	}
	checkForImportFiles() {

		if (this.importFileInput.el.value !== "" && this.importFileInput.el.value !== undefined && this.importFileInput.el.value !== null) {
			return true;
		} else {
			return false;
		}
	}


	public allColumnsChecked: boolean = false;
	/**
	 * Checks or unchecks all the selectable columns for import on step 3.
	 */
	public selectAllColumns(): void {
		this.allColumnsChecked = !this.allColumnsChecked;
		this.getImportedBullsColumns().forEach( col => {
			col.importColumn = this.allColumnsChecked;
		} );
	}


	/**
	 * This used to only look at the 1st data row, if a column was absent of data, it would be omitted.
	 * Fixed as a quality of life improvement for the end user.
	 * Rather than assume the 1st data row has all the columns populated, we'll loop over the entire data set to catch all the columns.
	 * @returns array of importable column names
	 */
	public getIdColumnCandidates(): string[] {
		// By only looking at the 1st row, we implicitly omit columns with empty values in the 1st row (2nd row in Excel).
		let columnNames;
		if (this.bullImportService.stagingImportedData) {
			columnNames = [];
			this.bullImportService.stagingImportedData.forEach(row => {
				Object.keys(row).forEach(key => {
					if (!columnNames.includes(key)) {
						columnNames.push(key);
					}
				});
			});
		}
		return columnNames;
	}


	/**
	 * This used to only look at the 1st data row, if a column was absent of data, it would be omitted.
	 * Fixed as a quality of life improvement for the end user.
	 * Rather than assume the 1st data row has all the columns populated, we'll loop over the entire data set to catch all the columns.
	 * @returns array of importable columns
	 */
	public getImportedBullsColumns(): any[] {
		let importedBulls = this.bullImportService.importedBullsDTO.bulls;
		if (importedBulls.length > 0) {
			// By only looking at the 1st row, we implicitly omit columns with empty values in the 1st row (2nd row in Excel).
			return this.ExtractSelectedColumns(importedBulls);
		} else {
			return null;
		}
	}


	async chooseColumnOfIds() {
		let idColumn;
		this.idRadios["_results"].forEach((radio: MatRadioButton) => {
			if (radio["_checked"]) {
				idColumn = radio["_value"];
			}
		});

		this.bullImportService.idColumnName = idColumn;
		if (!idColumn || idColumn === "") {
			this.alert.alerts.next({ message: "Please select the column that containers your Bull IDs" });
		} else {
			await this.bullImportService.importExternalData(this.bullImportService.stagingImportedData);
			this.step++;
		}
	}


	chooseImportColumns() {
		for (let bull of this.bullImportService.importedBullsDTO.bulls) {
			for (let column of bull.columns) {
				column.importColumn = false;
			}
		}


		for (let { option, index } of this.selectOptions.map((option, index) => ({
			index,
			option,
		}))) {
			if (option.name === "columnNames" && option.value !== null && option.value !== undefined && option.value !== "") {
				for (let col of option.value) {
					for (let bull of this.bullImportService.importedBullsDTO.bulls) {
						for (let column of bull.columns) {
							if (col === column.columnName) {
								column.importColumn = true;
							}
						}
					}
				}
			}
		}
	}


	/**
	 * This used to only look at the 1st data row, if a column was absent of data, it would be omitted.
	 * Fixed as a quality of life improvement for the end user.
	 * Rather than assume the 1st data row has all the columns populated, we'll loop over the entire data set to catch all the columns.
	 * @returns array of imported columns
	 */
	public getSelectedColumns(): any[] {
		// By only looking at the 1st row, we implicitly omit columns with empty values in the 1st row (2nd row in Excel).
		return this.ExtractSelectedColumns(this.bullImportService.importedBullsDTO.bulls, true);
	}


	/**
	 * Returns a unique list of importable column definitions.
	 * This method is essential in gathering all the potentially importable columns regardless of the 1st data row being populated (old bug).
	 * This method is repeated in bull-list.component as ExtractImportedColumns().
	 * @param importedData
	 * @returns array of importable column definitions.
	 */
	private ExtractSelectedColumns(importedData: IExtraBull[], requireImported: boolean = false) {
		let columnNames = [];
		return importedData.reduce((result, row) => {
			row.columns.forEach(col => {
				if ( !columnNames.includes(col.columnName) && ((col.importColumn && requireImported) || !requireImported) ) {
					result.push(col);
					columnNames.push(col.columnName);
				}
			});
			return result;
		}, []);
	}

	chooseImportDataTypes() {
		for (let bull of this.bullImportService.importedBullsDTO.bulls) {
			for (let column of bull.columns) {
				for (let { option, index } of this.selectOptions.map((option, index) => ({
					index,
					option,
				}))) {
					if (column.columnName === option.name && option.value !== null && option.value !== undefined && option.value !== "") {
						column.columnType = option.value;
					}
				}
			}
		}
	}


	applyImportTrue() {
		this.step === 4 ? null : (this.step += 1);
		let columnsToImport = this.ExtractSelectedColumns(this.bullImportService.importedBullsDTO.bulls)	// this.bullImportService.importedBullsDTO.bulls[0].columns
			.filter((col) => {
				return col.importColumn === true;
			})
			.map((column) => column.columnName);
		this.bullImportService.importedBullsDTO.bulls.map((bull) => {
			bull.columns.map((col) => {
				if (columnsToImport.includes(col.columnName)) {
					col.importColumn = true;
				}
			});
		});
	}
	async confirmImports() {
		this.bullImportService.importing.next(false);
		this.recall.importedDataDTO = this.bullImportService.importedBullsDTO;
		if (this.recall.currentTemplateTitle.includes("GVC"))
			this.recall.addImportedBullsToGrid.next(this.recall.currentGVCList);
		else
			this.recall.addImportedBullsToGrid.next(this.recall.currentBullsList);

		this.modalController.dismiss();
	}

	ngAfterViewInit() {}
	dismissModal() {
		this.modalController.dismiss();
		this.bullImportService.currentImportedFileName = "";
		this.recall.importedDataDTO ? (this.recall.importedDataDTO.bulls = []) : null;

		this.bullImportService.importing.next(false);
		this.bullImportService.importedBullsDTO = {
			bulls: [],
		};
	}
	ngOnDestroy() {}
}
