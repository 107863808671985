import { Component, Input, OnDestroy } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { BullListRecallService } from "../bull-list-recall.service";
import { BullService } from "../../bull/bull.service";
import { AlertService } from "../../utils/alerts/alert.service";
import { ProgressBarService } from "../../utils/loading/progress-bar.service";
import { UserManagementService } from "../../login/user-management.service";

@Component({
	selector: "homegrown-change",
	templateUrl: "home-grown-change.component.html",
	styleUrls: ["./home-grown-change.component.scss"],
})
export class HomeGrownModalChangeComponent implements OnDestroy {
	public IdCount: number = 0;
	public listOfIds: string;
	public searchArray: string[] = [];
	public unpaddedArray: string[] = [];
	public listOfMixins: string[] = ["Base"];
	public listName: string = "";
	constructor(
		private bullService: BullService,
		private alert: AlertService,
		public alertController: AlertController,
		public recall: BullListRecallService,
		private progress: ProgressBarService,
		private userManager: UserManagementService
	) {}

	dismissModal() {
		this.recall.inputAlert.next({ show: false, from: "change" });
	}

	countIds() {
		this.searchArray = this.listOfIds.trim().split(/[\n|\r]/);
		this.unpaddedArray = this.searchArray
			.map((naab) =>
				naab
					.replace(/^[0,]*/, "")
					.toUpperCase()
					.trim()
			)
			.filter((id) => {
				return id !== "";
			});

		this.listOfIds = this.unpaddedArray.join("\n");
		this.IdCount = this.unpaddedArray.length;
	}
	ngOnDestroy() {}
	async changeCustomList() {
		if (this.listOfIds.length > 0) {
			this.recall.homeGrownIds = [];
			if (this.recall.importedDataDTO) {
				this.recall.importedDataDTO.bulls = [];
			}
			this.recall.currentTemplateId = "HomeGrownList";

			this.recall.homeGrownIds = this.unpaddedArray;

			this.recall.buildHomeGrown.next({ build: true, change: true, save: false });

			this.recall.inputAlert.next({ show: false, from: "change" });
		} else {
			this.alert.alerts.next({ message: "Please make sure that you have a least 1 ID and a list name" });
		}
	}
}
