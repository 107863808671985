import { Pipe, PipeTransform } from "@angular/core";
import { MarketingGroup } from "../../bull-list/bull-form/bull-form.model";

@Pipe({ name: "MKRightBadgeFormat" })
export class MarketingRightBadgeFormatPipe implements PipeTransform {
	transform(right: MarketingGroup) {
		let badge = "";
		if (right) {
			badge = badge + right.Name;
		}
		if (right && right.Status && right.Status.Action) {
			badge = badge + "-" + right.Status.Action;
		}
		if (right && right.Status && right.Status.ActionDate) {
			badge = badge + "-" + new Date(right.Status.ActionDate).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1");
		}
		return badge;
	}
}
