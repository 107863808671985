import { Component, Input, OnDestroy } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { BullListRecallService } from "../bull-list-recall.service";
import { BullService } from "../../bull/bull.service";
import { AlertService } from "../../utils/alerts/alert.service";
import { ProgressBarService } from "../../utils/loading/progress-bar.service";
import { User, UserManagementService } from "../../login/user-management.service";
import { BullListOptionsService } from "../../bull-list-options/bull-list-options.service";
import { AuthService } from "../../auth/auth.service";

@Component({
	selector: "homegrown-page",
	templateUrl: "home-grown-page.component.html",
	styleUrls: ["./home-grown-page.component.scss"],
})
export class HomeGrownModalPageComponent implements OnDestroy {
	public IdCount: number = 0;
	public listOfIds: string;
	public searchArray: string[] = [];
	public unpaddedArray: string[] = [];
	public listOfMixins: string[] = ["Base"];
	public listName: string = "";
	constructor(
		private bullService: BullService,
		private alert: AlertService,
		public alertController: AlertController,
		public recall: BullListRecallService,
		private progress: ProgressBarService,
		private userManager: UserManagementService,
		private listOptions: BullListOptionsService,
		public authService: AuthService
	) {}

	addCategory(mixinName: string) {
		this.listOfMixins.push(mixinName);
	}
	disableHomeGrownCategory(mixinName: string) {
		if (this.listOfMixins.includes(mixinName)) {
			return true;
		} else return false;
	}
	removeCategory(mixinName: string) {
		let indexOfMix = this.listOfMixins.indexOf(mixinName);
		this.listOfMixins.splice(indexOfMix, 1);
	}

	dismissModal() {
		this.recall.inputAlert.next({ show: false, from: "show" });
	}

	countIds() {
		this.searchArray = this.listOfIds.trim().split(/[\n|\r]/);
		this.unpaddedArray = this.searchArray
			.map((naab) =>
				naab
					.replace(/^[0,]*/, "")
					.toUpperCase()
					.trim()
			)
			.filter((id) => {
				return id !== "";
			});

		this.listOfIds = this.unpaddedArray.join("\n");
		this.IdCount = this.unpaddedArray.length;
	}
	ngOnDestroy() {}
	async buildCustomList(saveListToo: boolean = false) {
		let user: User = await this.authService.getUserFromStorage();
		let listMatch = user.CustomLists.UserDefined.filter((list) => {
			list.Name === this.listName;
		});
		if (this.listOfIds.length > 0 && this.listName !== "" && listMatch.length === 0) {
			this.recall.homeGrownIds = [];
			if (this.recall.importedDataDTO) {
				this.recall.importedDataDTO.bulls = [];
			}
			if (this.listOptions.productPerRow && !this.listOfMixins.includes("Prices")) {
				this.listOfMixins.push("Prices");
			}
			this.progress.loading.next({ load: true, source: "build custom list start" });
			await this.bullService.homeGrownList(this.listOfMixins, this.unpaddedArray, false);
			this.recall.currentTemplateId = "HomeGrownList";
			this.recall.currentTemplateTitle = this.listName;
			this.recall.currentMixins = this.listOfMixins;
			this.recall.homeGrownIds = this.unpaddedArray;
			this.recall.currentDisplayMixins = this.listOfMixins.filter((mix) => mix !== "Base");

			//set saved list name in recall service
			saveListToo ? (this.recall.savedListName = this.listName) : null;

			//
			this.recall.buildHomeGrown.next({ build: true, change: false, save: saveListToo });
			this.progress.loading.next({ load: false, source: "build custom list end" });
			this.recall.inputAlert.next({ show: false, from: "show" });
		} else {
			this.alert.alerts.next({ message: "Please make sure that you have a least 1 ID and a list name" });
		}
	}
}
