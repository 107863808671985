import { Injectable, Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL, ENDPOINT } from "../../environments/environment";
import { SavedBullListDTO, DeleteBullListDTO } from "../bull-list/bull-list.model";
import { NgForage } from "ngforage";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { AlertService } from "../utils/alerts/alert.service";

import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
export class CustomList {
	Index?: [];
	UserDefined?: SavedBullListDTO[];
}
export interface CountryObj {
	AlphaCode: string;
	Country: string;
	Id: string;
	NumericCode: string;
	country_code: string;
	iso_alpha: string;
	iso_numeric: string;
	name: string;
}
export interface CompanyObj {
	Company: string;
	Country: CountryObj;
	Id: string;
	Type: string;
}

export class User {
	CustomLists?: CustomList;
	DefaultLanguage?: string;
	Id?: string;
	IsAdmin?: boolean;
	IsLoggedIn?: boolean;
	Role?: number;
	Roles?: string[];
	AccessToken?: string;
	ApiKey?: string;
	Company?: CompanyObj;
	Country?: CountryObj;
	CreatedBy?: string;
	DateCreated?: string;
	DateModified?: string;
	DeviceToken?: string;
	Email?: string;
	FirstName?: string;
	FullName?: string;
	IsLocked?: boolean;
	LastLogin?: string;
	LastLoginAttempt?: string;
	LastName?: string;
	LegacyUserId?: string | number;
	LoginAttempts?: number;
	Message?: string;
	ModifiedBy?: string;
	Note?: string;
	SecurityLevel?: number;
	Status?: number;
	Type?: string;
	VerificationCode?: string;
	Permissions?: any;
}

export class UserPayload {
	User: User;
	constructor(user: User) {
		Object.assign(this, user);
	}
}

@Injectable({
	providedIn: "root",
})
export class UserManagementService {
	// public user: User;
	public accessToken: string;

	constructor(private http: HttpClient, private ngf: NgForage, private progress: ProgressBarService, private alert: AlertService, private router: Router, private authService: AuthService) {}
}
