import { Component, OnInit, OnDestroy, AfterViewInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { UserManagementService } from "../../login/user-management.service";
import { BullFormDataService } from "../bull-form/bull-form-data.service";
import { DomSanitizer } from "@angular/platform-browser";

//MODAL COMPONENT
@Component({
	selector: "document-modal-page",
	templateUrl: "./documents-modal-page.component.html",
	styleUrls: ["./documents-modal-page.component.scss"],
})
export class DocumentModalPage implements OnInit, OnDestroy, AfterViewInit {
	@Input() docSrc: string;
	pedigreeResponse: any;
	pedigreeUrl: any;
	constructor(private modalController: ModalController, public http: HttpClient, public userManager: UserManagementService, public formData: BullFormDataService, private sanitizer: DomSanitizer) {}

	async ngOnInit() {
		let docResponse: any = await this.http.get(this.docSrc, { responseType: "arraybuffer" }).toPromise();

		this.pedigreeResponse = new Blob([docResponse], { type: "application/pdf" });
		this.pedigreeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.pedigreeResponse));
	}

	ngAfterViewInit() {}
	closeDocument() {
		this.formData.openDocumentModal.next("");
	}
	ngOnDestroy() {}
}
