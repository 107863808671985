import { Injectable } from "@angular/core";
import { Bull, columnDefiner } from "../bull/bull.model";
import { SavedBullListDTO, ImportedBullsDTO, DeleteBullListDTO, BullListView } from "./bull-list.model";
import { NgForage, Driver, NgForageCache, CachedItem } from "ngforage";
import { UserManagementService, User } from "../login/user-management.service";
import { Subject, Subscription } from "rxjs";
import { ColDef } from "ag-grid-community";
import { Price } from "../price/price.model";
import { HttpClient } from "@angular/common/http";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { AlertService } from "../utils/alerts/alert.service";
import { GVCTemplateResponse, GVCProduct, GVCColumnDef } from "../bull/gvc.model";
import { API_URL, ENDPOINT } from "../../environments/environment";
import { BullService } from "../bull/bull.service";
import { BullListOptionsService } from "../bull-list-options/bull-list-options.service";
import { AuthService } from "../auth/auth.service";
import { BullImportService } from "./import-modal/bull-import.service";

export interface BullMixin {
	name: string;
	display: string;
}
export interface UpdateProfileResponse {
	user: User;
	isLoggedIn: boolean;
	message: string;
	status: number;
}
export interface IRecall {
	source?: string;
	settingDefault?: boolean;
	importing?: boolean;
	listName?: string;
	columns?: any;
	filters?: any;
	sort?: any;
	group?: any;
	columnDefs?: ColDef[];
	mixins?: string[];
	importedBullDTO?: ImportedBullsDTO;
	homeGrownIds?: string[];
}

@Injectable({
	providedIn: "root",
})
export class BullListRecallService {
	// public currentTraitFormats: { name: string; decimalPlace: number }[] = [];
	public mixinListUSA: BullMixin[] = [
		{ name: "Production", display: "Production" },
		{ name: "Management", display: "Management" },
		{ name: "Prices", display: "Prices" },
		{ name: "BeefMale", display: "Beef Male" },
		{ name: "Genotypes", display: "Genotypes" },
		{ name: "Designation", display: "Designation" },
		{ name: "HealthTest", display: "Health Tests" },
		{ name: "Indexes", display: "Indexes" },
		{ name: "Index", display: "Index" },
		{ name: "Lineage", display: "Lineage" },
		{ name: "Location", display: "Production Status (Barn Movement)" },
		{ name: "Meta", display: "Meta" },
		{ name: "Lactation", display: "Lactation" },
		{ name: "Type", display: "Type" },
		{ name: "MarketingRight", display: "Marketing Right" },
		{ name: "Sire Fertility", display: "Sire Fertility" },
		{ name: "MarketingGroup", display: "Marketing Groups" },
		{ name: "Daughter Fertility", display: "Daughter Fertility" },
		{ name: "Wellness Traits", display: "Wellness Traits" },
		{ name: "Inbreeding", display: "Inbreeding" },
	];
	public mixinListDEU: BullMixin[] = [
		{ name: "Production_DEU", display: "Production" },
		{ name: "Total Breeding Values_DEU", display: "Total Breeding Values" },
		{ name: "Type_DEU", display: "Type" },
		{ name: "Fertility_DEU", display: "Fertility" },
		{ name: "Health_DEU", display: "Health" },
		{ name: "Meta_DEU", display: "Meta" },
		{ name: "Braunvieh_DEU", display: "Braunvieh" }
	];
	public mixinListAUS: BullMixin[] = [
		{ name: "Indexes_AUS", display: "Indexes" },
		{ name: "Production_AUS", display: "Production" },
		{ name: "Type_AUS", display: "Type" },
		{ name: "Management_AUS", display: "Management" },
		{ name: "Calving_AUS", display: "Calving" },
		{ name: "Meta_AUS", display: "Meta" },
	];
	public mixinListCDDRGBR: BullMixin[] = [
		{ name: "CDDR_Production_GBR", display: "CDDR Production" },
		{ name: "CDDR_Management_GBR", display: "CDDR Management" },
		{ name: "CDDR_Type_GBR", display: "CDDR Type" },
		{ name: "CDDR_Index_GBR", display: "CDDR Index" },
		{ name: "CDDR_IntSCI_GBR", display: "CDDR IntSCI" },
		{ name: "CDDR_Health_GBR", display: "CDDR Health" },
	];
	public mixinListGBR: BullMixin[] = [
		{ name: "Production_GBR", display: "Production" },
		{ name: "Management_GBR", display: "Management" },
		{ name: "Type_GBR", display: "Type" },
		{ name: "Indexes_GBR", display: "Indexes" },
		{ name: "Health_GBR", display: "Health" },
	];
	public currentGVCList: GVCProduct[] = [];
	public gvcOrder: GVCColumnDef[] = [];
	public loadGVC: Subject<{ load: boolean; import: boolean; refresh: boolean; source: string }> = new Subject();
	public inputAlert: Subject<{ show: boolean; from: string }> = new Subject();
	public buildHomeGrown: Subject<{ build: boolean; change: boolean; save: boolean }> = new Subject();
	public homeGrownIds: string[] = [];
	public showBullForm: Subject<{ show: boolean; fromList: boolean }> = new Subject();
	public showBullFormFromList: boolean;
	public clickedbull: string = "";
	public clickedbullIndex: number = 0;
	public listOfAvailableViews: BullListView[] = [];
	public currentTemplateId: string = "";			// The system name, or id, very ugly.	Previously known as "currentListView".
	public naabCodesSearch: string = "";
	public regIdSearch: string = "";
	public currentMixins: string[] = [];
	public currentDisplayMixins: string[] = [];
	public importedDataDTO: ImportedBullsDTO;
	public openCategoryModal: Subject<boolean> = new Subject();
	public restoring: boolean;
	public savedListName: string = "";
	public restoreListName: string;
	public loadListWithImports: Subject<IRecall> = new Subject();
	public naabFilter: Subject<string[]> = new Subject();
	public saving: boolean;
	public addImportedBullsToGrid: Subject<any[]> = new Subject();
	public addCategory: Subject<string> = new Subject();
	public loadBullsList: Subject<IRecall> = new Subject();
	public currentBullsList: Bull[] = [];
	public currentNodeList: any[] = [];
	public currentPriceList: Price;
	public currentTemplateTitle: string = "";		// The pretty list name, it's title (or label). Previously named "currentListName".
	public defaultListName: string = "DairyListActiveLineup";
	public changePriceList: Subject<Price> = new Subject();
	public changeListName: Subject<string> = new Subject();
	public savedListArray: string[] = [];
	public savedBullListDTO: SavedBullListDTO;

	constructor(
		private alert: AlertService,
		private readonly ngf: NgForage,
		public userManager: UserManagementService,
		private http: HttpClient,
		private progress: ProgressBarService,
		private listOptions: BullListOptionsService,
		public authService: AuthService
	) {}
	showCreator(list: SavedBullListDTO) {
		let user = this.authService.currentUser;
		if (list.CreatorName === user.FirstName + " " + user.LastName[0] || !list.CreatorName) {
			return null;
		} else {
			return ` Created By: ${list.CreatorName}`;
		}
	}


	async getSavedBullListDTO(key: string) {
		let data: SavedBullListDTO;
		await this.ngf.getItem(key).then((result: SavedBullListDTO) => {
			data = result;
		});
		return data;
	}

	async populateSavedLists() {
		let storageLength = await this.ngf.length();
		if (storageLength > 0) {
			for (let i = 0; i < storageLength; i++) {
				let key = await this.ngf.key(i);
				this.savedListArray.push(key);
			}
		}
	}
	startSaving() {
		this.savedListName = "";
		this.saving = true;
	}
	createSqlGuid() {
		let dt = new Date().getTime();
		let uuid = "xxxxxxxx-xxxx-yxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
			let rand = (dt + Math.random() * 16) % 16 | 0;
			dt = Math.floor(dt / 16);
			return (c == "x" ? rand : (rand & 0x3) | 0x8).toString(16);
		});
		return uuid.toUpperCase();
	}
	async saveDefault() {
		let columnArray = Object.keys(columnDefiner.Base);
		let columnStateData = [];
		columnArray.forEach((colName) => {
			columnStateData.push({
				aggFunc: null,
				colId: colName,
				flex: 1,
				hide: false,
				pinned: null,
				pivotIndex: null,
				rowGroupIndex: null,
				width: 200,
			});
		});

		let user: User = await this.authService.getUserFromStorage("save default");

		this.savedBullListDTO = {
			CreatorId: user.Id.toString(),
			Id: this.createSqlGuid(),
			Name: "My Active Lineup",
			Type: "UserDefined",
			IsDefault: true,
			SharedOwners: [],
			Definition: {
				baseDataSource: "DairyListActiveLineup",
				mixins: ["Base"],
				externalData: null,
				// columnDefs: columnDefs,
				sortState: [],
				groupState: [],
				filterState: {},
				columnState: columnStateData,
			},
		};

		user.CustomLists.UserDefined.push(this.savedBullListDTO);
		try {
			let userResponse = await this.sendAllListToServer(user);

			user.CustomLists = userResponse.CustomLists;
			await this.ngf.setItem("BABUser", user);

			this.currentTemplateTitle = "My Active Lineup";
		} catch (error) {
			this.alert.alerts.next({ message: `Something Went Wrong Trying To Save Default List: ${error.message}` });
		} finally {
		}
	}

	/**
	 * Fetch the user from storage.
	 * Update existing list in user's lists object.
	 * Save user back to storage.
	 * Create a deep copy of the user object.
	 * Purge lists and assign only the single list to be saved.
	 * Push copy of user to the API.
	 * @param filterSettings The current filter(s) object applied in the grid.
	 * @param columnSettings The current column state array applied in the grid.
	 * @param sortSettings The current sort ordering array applied in the grid.
	 * @param groupSettings The current grouping state array applied in the grid.
	 * @param baseDataSource The name of the list, or "HomegrownList", used in the grid.
	**/
	async updateList(filterSettings, columnSettings, sortSettings, groupSettings, baseDataSource: string) {
		this.progress.loading.next({ load: true, source: "update list start" });
		let user: User = await this.authService.getUserFromStorage("update list");

		// Filter the array of all the lists down to only the one we're modifying.
		let customList: SavedBullListDTO;
		user.CustomLists.UserDefined.forEach( (list: SavedBullListDTO, indx: number) => {
			// Gotta match?  Apply all the List Definition particulars.
			if ( list.Name === this.currentTemplateTitle )
			{
				// This implicitly updates the user object's list item.
				list.Definition.mixins = this.currentMixins;
				list.Definition.productPerRow = this.listOptions.productPerRow;
				list.Definition.filterState = filterSettings;
				list.Definition.columnState = columnSettings;
				list.Definition.sortState = sortSettings;
				list.Definition.groupState = groupSettings;
				list.Definition.baseDataSource = baseDataSource;
				list.Definition.externalData = this.importedDataDTO;
				if (this.currentTemplateId === "HomeGrownList")
					list.Definition.homeGrownIds = this.homeGrownIds;
				customList = list;
			}
		} );

		// Create a deep copy of the user, overwrite the lists, send to API.
		let userCopy: User = JSON.parse(JSON.stringify(user));
		userCopy.CustomLists.UserDefined = [customList];

		try {
			let userResponse = await this.sendAllListToServer(userCopy);
			await this.ngf.setItem("BABUser", user);
		} catch (error) {
			this.alert.alerts.next({ message: `Something Went Wrong Trying To Update ${this.currentTemplateTitle.trim()}: ${error.message}` });
		}

		this.progress.loading.next({ load: false, source: "update list end" });
	}

	disableCategory(mixin?: string) {
		if (mixin === "BeefMale" && !this.currentMixins.includes("Prices")) {
			return true;
		} else {
			if (this.currentMixins.includes(mixin)) {
				return true;
			} else {
				return false;
			}
		}
	}


	/**
	 * Fetch the user from storage.
	 * Append the new list to user.
	 * Save user back to storage.
	 * Create a deep copy of the user object.
	 * Purge lists and assign only the single list to be saved.
	 * Push copy of user to the API.
	 * @param filterSettings The current filter(s) object applied in the grid.
	 * @param columnSettings The current column state array applied in the grid.
	 * @param sortSettings The current sort ordering array applied in the grid.
	 * @param groupSettings The current grouping state array applied in the grid.
	**/
	async saveList(filterSettings, columnSettings, sortSettings, groupSettings) {
		this.progress.loading.next({ load: true, source: "save list start" });
		let user: User = await this.authService.getUserFromStorage("save list");

		this.savedBullListDTO = {
			CreatorId: user.Id.toString(),
			Name: this.savedListName.trim(),
			Id: this.createSqlGuid(),
			IsDefault: false,
			Type: "UserDefined",
			SharedOwners: [],
			Definition: {
				baseDataSource: this.currentTemplateId,
				productPerRow: this.listOptions.productPerRow,
				sortState: sortSettings,
				mixins: this.currentMixins,
				groupState: groupSettings,
				externalData: this.importedDataDTO,
				filterState: filterSettings,
				columnState: columnSettings,
			},
		};
		if (this.currentTemplateId === "HomeGrownList")
			this.savedBullListDTO.Definition.homeGrownIds = this.homeGrownIds;

		// Append new list to user's lists object.
		this.savedListArray.push(`${this.savedListName}`);
		user.CustomLists.UserDefined.push( this.savedBullListDTO );

		// Create a deep copy of the user, overwrite the lists, send to API.
		let userCopy: User = JSON.parse(JSON.stringify(user));
		userCopy.CustomLists.UserDefined = [this.savedBullListDTO];
		try {
			let userResponse = await this.sendAllListToServer(userCopy);
			await this.ngf.setItem("BABUser", user);
			this.currentTemplateTitle = this.savedListName;
		} catch (error) {
			console.error(error);
			this.alert.alerts.next({ message: `Failed to save ${this.savedListName.trim()}` });
		} finally {
			this.saving = false;
			this.progress.loading.next({ load: false, source: "save list end" });
		}
	}

	async sendAllListToServer(userToUse: User) {
		let user = userToUse;
		let userToSend = {
			user: {
				email: user.Email,
				CustomLists: {
					UserDefined: user.CustomLists.UserDefined,
				},
			},
		};

		return this.http.post<User>(API_URL + ENDPOINT.updateProfile, { data: userToSend }).toPromise();
	}

	async deleteListFromServer(list: SavedBullListDTO, userToUse: User) {
		let user = userToUse;
		let userToSend = {
			user: {
				email: user.Email,
				CustomLists: {
					UserDefined: [
						{
							CreatorId: list.CreatorId,
							Id: list.Id,
							Delete: true,
						},
					],
				},
			},
		};

		return this.http.post<User>(API_URL + ENDPOINT.updateProfile, { data: userToSend }).toPromise();
	}
}
