import { Injectable } from "@angular/core";
import { BullService } from "../bull/bull.service";

@Injectable({
	providedIn: "root",
})
export class DocumentUploadService {

	// A couple of quasi-enum objects used for consistency.
	public DocTypes = {
		"Dna": "Dna",
		"Pedigree": "Pedigree",
		"BeefPedigree": "BeefPedigree",
		"Certificate": "Certificate",
		"PartA": "PartA"
	};

	public DocTypeLabels = {
		"Dna": "DNA",
		"Pedigree": "Pedigree",
		"Certificate": "Certificate",
		"PartA": "Completed Part A"
	};

	/**
	 * The next few regular expressions represent the various file types naming conventions.
	 */
	private PedigreeConv = new RegExp(/^([0-9]{1,3}[a-zA-z]{1,2}[0-9]{3,5})[_\s]*([0-9]{8})[_\s]*([0-9]{1}[A-Za-z]{1,2})[_\s]*([a-zA-Z]{5})*/i);
	private DnaCertConv = new RegExp(/^([0-9]{1,3}[a-zA-z]{1,2}[0-9]{3,5})(.+)(\.pdf)+/i);
	private PartAConv = new RegExp(/^[a-zA-Z]{2}\w{3}.{4,12}\.pdf/i);
	private ColorConv = new RegExp(/color/, "i");
	private animalCache = {};
	constructor(
		private bullService: BullService
	) {}


	private toBase64(file) {
		return new Promise((res, rej) => {
			let fileReader = new FileReader();

			fileReader.readAsDataURL(file);
			fileReader.onload = () => res(fileReader.result);
		});
	}

	/**
	 * Marked for deprecation, I'm not sure if this is ever called from anywhere.
	 */
	base64ToBlob(base64, file) {
		let arrayBuffer = new ArrayBuffer(base64.length);
		const typedArray = new Uint8Array(arrayBuffer);

		for (let i = 0; i < base64.length; i++) {
			typedArray[i] = base64.charCodeAt(i);
		}

		return new Blob([arrayBuffer], { type: file.type });
	}


	/**
	 * Checks for compliance that the uploaded file is of the proper type, within reason.
	 * This is bouncing the filename off of a RegEx pattern, which is mostly perfect, but will not catch human error.
	 * @param uploadedFile
	 * @param docType
	 */
	private AssertDocType(uploadedFileName: string, docType: string): void {
		let valid = false;
		switch(docType) {
			case this.DocTypes.Dna:
				valid = this.DnaCertConv.test(uploadedFileName);
				break;
			case this.DocTypes.Certificate:
				valid = this.DnaCertConv.test(uploadedFileName) && !uploadedFileName.toLowerCase().includes("dna");
				break;
			case this.DocTypes.Pedigree:
			case this.DocTypes.BeefPedigree:
				valid = this.PedigreeConv.test(uploadedFileName);
				break;
			case this.DocTypes.PartA:
				valid = this.PartAConv.test(uploadedFileName);
				break;
		}
		if (!valid) {
			// Now what?  Well, our file naming convention doesn't match the doc type specified by the user, throw it out!
			throw new TypeError(`File Naming Convention "${uploadedFileName}" does not match the selected ${docType} PDF Type.  Please Check Your File Name(s) or the Selected File Type.`);
		}
	}


	/**
	 * Service logic refactored out of the component layer to handle metadata assignment of whatever file type we throw at it.
	 * Pedigrees are specifically handled below in BuildPedigreeMeta.
	 * @param uploadedFile The uploaded file.
	 * @returns Returns an object representation of the various Dna particulars.
	 */
	public async BuildPdfFileMeta(uploadedFile: File, docType: string) {
		this.AssertDocType(uploadedFile.name, docType);
		if ( docType.includes(this.DocTypes.Pedigree) )
			return this.BuildPedigreeMeta(uploadedFile);

		let fileParts = this.DnaCertConv.exec(uploadedFile.name),
			identifier: string = docType.includes(this.DocTypes.PartA) ? uploadedFile.name.replace(/\.pdf/i, "").trim() : fileParts[1],
			naab: string = (fileParts && fileParts.length ? fileParts[1] : identifier),
			animal = await this.FetchAnimalIdentity(identifier),
			data: any = await this.toBase64(uploadedFile),
			typeOfFile: string = this.DeriveSubtype(docType, uploadedFile.name, naab),
			fixedFilename: string = this.DeriveFileName(docType, naab, fileParts, animal, uploadedFile);
		return {
			AnimalId: animal.GlobalId,
			ShortName: animal.ShortName || animal.RegName,
			NaabCode: animal.PrimaryNaabCode,
			DocumentBlob: data,
			FileName: fixedFilename,
			DocumentDate: new Date(uploadedFile.lastModified).toISOString().split("T")[0],
			Sent: false,
			Type: {
				Primary: docType,
				Secondary: typeOfFile,
				Color: this.ColorConv.test(uploadedFile.name) ? "Color" : null,
			}
		};
	}


	/**
	 * Service logic refactored out of the component layer to handle the assignment of Pedigree file meta.
	 * @param uploadedFile The uploaded file.
	 * @returns Returns an object representation of the various Pedigree particulars.
	 */
	private async BuildPedigreeMeta(uploadedFile: File) {
		let color: boolean = this.ColorConv.test(uploadedFile.name);
		let fileParts = this.PedigreeConv.exec(uploadedFile.name);
		let naab: string = fileParts[1];
		let date: string = fileParts[2] || new Date(uploadedFile.lastModified).toISOString().split("T")[0];
		let data: any = await this.toBase64(uploadedFile);

		let typeOfFile: string = fileParts[3];
		let animal = await this.FetchAnimalIdentity(naab);
		let isBeef = (animal.BreedGroup && animal.BreedGroup == "Beef");

		return {
			AnimalId: animal.GlobalId,
			ShortName: animal.ShortName || animal.RegName,
			NaabCode: naab || animal.PrimaryNaabCode || "",
			DocumentBlob: data,
			FileName: color ? naab + "_" + date + "_" + typeOfFile + "_Color" : naab + "_" + date + "_" + typeOfFile,
			DocumentDate: date,
			Sent: false,
			Type: {
				Primary: (isBeef ? "Beef" : "") + "Pedigree",
				Secondary: typeOfFile || "",
				Color: color ? "Color" : null
			}
		};
	}


	/**
	 * A clean, readable method that derives the secondary file type, there are many combinations.
	 * This is an attempt to strip out the jargon and only leave the details, where appropriate.
	 * @param uploadedFile
	 * @param naab
	 * @param typeRx
	 * @returns A string containing the cleaned "secondary" file type.
	 */
	private DeriveSubtype(docType: string, uploadedFileName: string, naab: string): string {
		const typeRx: RegExp = new RegExp("(\\s?" + docType + ")+", "i");
		return uploadedFileName
			// Strip the file extension.
			.replace(/\.pdf/i, "")

			// Strip the naab code portion of the file.
			.replace(naab, "")

			// Strip the type (dna, certificate, et al)
			.replace(typeRx, "")

			// Strip the abbreviated "cert" word.
			.replace(/\s?cert/i, "")

			// Remove the word "color".
			.replace(this.ColorConv, "")

			// Trim the remainder
			.trim()

			// Attempt to enforce title casing.
			.replace( /\w\S*/g, raw => { return raw.charAt(0).toUpperCase() + raw.substr(1).toLowerCase() } );
	}


	/**
	 * Extracted from BuildPdfFileMeta for readability and clarity, derives the filename convention by type.
	 * @param docType 3
	 * @param naab
	 * @param fileParts
	 * @param animal
	 * @param uploadedFile
	 * @returns Returns the filename.
	 */
	private DeriveFileName(docType: any, naab: string, fileParts: RegExpExecArray, animal: any, uploadedFile: File): string {
		let fixedFilename: string = uploadedFile.name;
		switch (docType) {
			case "Dna":
				fixedFilename = naab + fileParts[2];
				break;
			case "PartA":
				fixedFilename = animal.RegId.toUpperCase() + ".pdf";
				break;
		}
		return fixedFilename;
	}


	/**
	 * Fetches an animal identity object from the Rest API based on the provided identifier.
	 * Stores it in the instance to eliminate repeated calls, although this is async so it's likely still to make multiples if firing in parallel.
	 * @param identifier The reg Id or naab code used to identify the animal
	 * @returns A thin animal object containing identifiers and some basic base data.
	 */
	private async FetchAnimalIdentity(identifier: string) {
		if (Object.keys(this.animalCache).includes(identifier))
			return this.animalCache[identifier];

		let animalIdentity = await this.bullService.getAnimalIdentity(identifier);
		if (animalIdentity.hasOwnProperty("Error"))
			throw new Error(animalIdentity.Error);

		this.animalCache[identifier] = animalIdentity;
		return animalIdentity;
	}


}
