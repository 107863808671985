import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL, ENDPOINT } from "../../environments/environment";
import { Price } from "./price.model";
import { UserManagementService } from "../login/user-management.service";
import { AlertService } from "../utils/alerts/alert.service";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { Observable, Subject } from "rxjs";
import { BullResponse } from "../bull-list/bull-list.model";

@Injectable({
	providedIn: "root",
})
export class PriceService {
	protected endpoint: string;
	public buildPriceList: Subject<boolean> = new Subject();
	public openPriceList: Subject<boolean> = new Subject();

	constructor(private http: HttpClient, private userManager: UserManagementService, private alert: AlertService, private progress: ProgressBarService) {}

	// retrieves products from NAV. Calling function must pass the payload object
	getPrices(payload: object): Promise<Price[]> {
		return this.http.post<Price[]>(API_URL + ENDPOINT.products, payload).toPromise();
	}
}
