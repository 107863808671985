import { Component, OnInit, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { DocumentModalPage } from "./documents-modal-page.component";
import { Subscription } from "rxjs";
import { BullFormDataService } from "../bull-form/bull-form-data.service";

//COMPONENT THAT CONTROLS MODAL
@Component({
	selector: "document-modal",
	templateUrl: "./documents-modal.component.html",
	styleUrls: ["./documents-modal.component.scss"],
})
export class DocumentModalComponent implements OnInit, OnDestroy {
	documentModalSub: Subscription;
	documentSrc: string;
	constructor(public modalController: ModalController, public bullFormService: BullFormDataService) {
		this.documentModalSub = this.bullFormService.openDocumentModal.subscribe((blobSrc: string) => {
			if (blobSrc.length > 0) {
				this.documentSrc = blobSrc;
				this.presentModal();
			} else {
				modalController.dismiss();
			}
		});
	}

	async presentModal() {
		const modal = await this.modalController.create({
			component: DocumentModalPage,
			cssClass: "document-modal",
			backdropDismiss: false,
			componentProps: {
				docSrc: this.documentSrc,
			},
		});

		return await modal.present();
	}

	ngOnInit(): void {}
	ngOnDestroy() {
		this.documentModalSub.unsubscribe();
	}
}
