import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { HomeGrownModalPageComponent } from "./home-grown-page.component";
import { Subscription } from "rxjs";
import { AlertService } from "../../utils/alerts/alert.service";
import { BullListRecallService } from "../bull-list-recall.service";
import { HomeGrownModalChangeComponent } from "./home-grown-change.component";

@Component({
	selector: "homegrown-list-controller",
	templateUrl: "home-grown.component.html",
	styleUrls: ["./home-grown.component.scss"],
})
export class HomeGrownModalComponent {
	inputSub: Subscription;
	change;
	constructor(public modalController: ModalController, public recall: BullListRecallService) {
		this.inputSub = this.recall.inputAlert.subscribe((obj: { show: boolean; from: string }) => {
			if (obj.show) {
				this.presentModal(obj.from);
			} else {
				this.modalController.dismiss();
			}
		});
	}

	async presentModal(from: string) {
		if (from === "show") {
			const modal = await this.modalController.create({
				component: HomeGrownModalPageComponent,
				cssClass: "input-modal",
				backdropDismiss: false,
			});

			return await modal.present();
		}
		if (from === "change") {
			const modal = await this.modalController.create({
				component: HomeGrownModalChangeComponent,
				cssClass: "change-modal",
				backdropDismiss: false,
			});

			return await modal.present();
		}
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.inputSub.unsubscribe();
	}
}
