import { Component, OnInit } from "@angular/core";
import { DocumentUploadDTO } from "./document-upload.model";
import { faAngry, faCheck, faFileUpload, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { DocumentUploadService } from "./document-upload.service";
import { AlertService } from "../utils/alerts/alert.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { API_URL, ENDPOINT} from "../../environments/environment";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { KeyValue } from "@angular/common";

@Component({
	selector: "app-document-upload",
	templateUrl: "./document-upload.component.html",
	styleUrls: ["./document-upload.component.scss"],
})
export class DocumentUploadComponent implements OnInit {
	public filesReady: boolean = false;
	public fileType: string = "";

	public loadingIndicator: string = "Verifying Files...";


	// This is referenced in the view.
	public pedigreeTypes: string[] = ["3GC", "3GT", "5GT", "3G", "5G", "4G"];
	public docTypeLabels = {};
	public docTypeLabelOrder = (a, b): number => {
		// Enforces desc key order when rendering the radio list, so Pedigree is on top.
		return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
	};
	public reading: boolean = false;

	wrongFileType: string[] = [];
	public firstFormGroup: FormGroup;
	public secondFormGroup: FormGroup;
	public thirdFormGroup: FormGroup;
	public uploadIcon = faFileUpload;
	public thumbsUpIcon = faThumbsUp;
	public angry = faAngry;
	public checkIcon = faCheck;
	public documents: DocumentUploadDTO[] = [];

	constructor(
		public documentService: DocumentUploadService,
		private alert: AlertService,
		private _formBuilder: FormBuilder,
		private http: HttpClient,
		private progress: ProgressBarService
	) {
		this.docTypeLabels = this.documentService.DocTypeLabels;
	}

	private StartReadingAction(): void {
		this.reading = true;
		this.filesReady = false;
		this.clearDocuments();
		let progressSimulator = setInterval(() => {
			// if (this.loadingIndicator.length === 16) {
			// 	this.loadingIndicator = "Reading Files";
			// }
			// this.loadingIndicator = this.loadingIndicator + ".";
			if (this.filesReady) {
				clearInterval(progressSimulator);
			}
		}, 500);
	}

	private EndReadingAction(): void {
		this.filesReady = true;
		this.reading = false;
		this.displayWarning();
	}

	ngOnInit() {
		this.firstFormGroup = this._formBuilder.group({
			firstCtrl: new FormControl(),
		});
		this.secondFormGroup = this._formBuilder.group({
			secondCtrl: new FormControl(),
		});
		this.thirdFormGroup = this._formBuilder.group({
			thirdCtrl: new FormControl(),
		});
	}
	fileBrowse($event: any) {
		this.onFilesDropped($event);
	}
	clearDocuments() {
		this.documents = [];
		this.wrongFileType = [];
	}


	private displayWarning(): void {
		if (this.wrongFileType.length > 0) {
			let mess = "";
			this.wrongFileType.forEach((id) => {
				id = id + ",<br/>";
				mess = mess + id;
			});

			this.alert.alerts.next({
				message: "Failed to upload:" + "<br/>" + `${mess}` + "<br/><br/>" + "Please check that:" + "<br/>" + "*Files are named correctly" + "<br/>" + "*The correct file type is selected",
			});
		}
	}


	/**
	 * Event handler for the uploaded files resolution.
	 * @param filesToUpload The FileList array containing one or more files from the post.
	 */
	public async onFilesDropped(filesToUpload: FileList) {
		this.StartReadingAction();
		this.VerifyFiles(filesToUpload).then(filesReady => {
			this.documents = this.documents.concat(filesReady);
		}).catch(ex => {
			// More of a catch-all for any other non-file errors that may crop up.
			this.alert.alerts.next({ message: ex.message });
		}).finally(() => {
			this.EndReadingAction();
		});
	}


	/**
	 * A simplified method that verifies the 4 different types and calls the proper methods to assign metadata appropriately.
	 * @param files A FileList array containing one or more files from the post.
	 * @returns A resolved stream of promises.
	 */
	private async VerifyFiles(files: any) {
		let promises = [];
		for (let uf of files) {
			if (uf.type !== "application/pdf") {
				this.wrongFileType.push(uf.name);
				// throw new TypeError(`Incompatible File Type "${uf.name}", the File Must be a PDF.  Please Check Your File Names Or Selected File Type.`);
				continue;
			}
			promises.push(this.documentService.BuildPdfFileMeta(uf, this.documentService.DocTypes[this.fileType]));
		}
		return Promise.all(promises);
	}


	removeFile(file) {
		this.documents.splice(this.documents.indexOf(file), 1);
	}

	async sendFiles() {
		let errors = [];
		this.wrongFileType = [];
		this.documents.forEach((doc) => {
			delete doc.Sent;
			delete doc.Failed;
		});

		for (let doc of this.documents) {
			try {
				let response = await this.http.post<any>(API_URL + ENDPOINT.documentUpload, { data: doc }).toPromise();
				if (response.UploadSuccessful) {
					doc.Sent = true;
				} else {
					doc.Failed = true;

					this.wrongFileType.push(doc.FileName);
				}
			} catch (error) {
				doc.Failed = true;
				errors.push(error);
			}
		}
		errors.length > 0 ? this.alert.alerts.next({ message: "Something Went Wrong Please Contact IT" }) : null;
		this.displayWarning();
	}
}
