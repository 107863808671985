export function FormatDatesAsList(dates: Array<string> ) {
	let dateValues = [];
	dates.forEach(date => {
		let parsed = new Date(date).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1") || "";
		if (parsed.length > 0)
			dateValues.push( parsed );
	});
	return dateValues.join(", ");
}

