import { MarketingGroup } from "../bull-list/bull-form/bull-form.model";
import { BullResponse } from "../bull-list/bull-list.model";

export interface BullHealthTest {
	TestName?: string;
	TestResult?: string;
	LegacyName?: string;
}
export class Bull {
	//Inbreeding
	inbred_pct_gen?: number;
	inbred_efi?: number;
	inbred_dtr?: number;
	inbred_pct?: number;
	inbred_efi_gen?: number;

	//Meta Information
	AIStatus: string = "";
	BirthState: string = "";
	aAa: string = "";
	DMS: string = "";
	DnaStatus: string = "";
	Classification?: number;
	PercentBlack?: number;
	HousedInCountry: string = "";
	CloneGen: string | number;
	CrossGen: string = "";
	Country: string = "";
	RHA_Ind: string = "";
	RHA_Pct?: number;
	Link: string = "";

	ConvertedCanadianDaughterAvg?: boolean;
	ConvertedCanadianDaughterAverage: string = "";
	SexedOnly: string = "";
	TopTPI: string = "";
	TopTPIGenomic: string = "";
	TopJPI: string = "";
	TopJPIGenomic: string = "";
	TopPPR: string = "";
	TopPPRGenomic: string = "";
	TopPTAM: string = "";
	TopPTAMGenomic: string = "";
	TopRedRCTPI: string = "";
	TopRedRCTPIGenomic: string = "";

	//Production Status (Location)
	Type: string = "";
	MoveDate?: Date;
	ResidencyDate: string = "";
	IsProduction: string = "";
	IsEU: string = "";
	IsIsolation: string = "";
	IsLayoff: string = "";
	BarnCode: string = "";
	BarnStatus: string = "";
	State: string = "";
	Location: string = "";
	ProductionStatus: string = "";
	[key: string]: any;
	//Base call data

	Id: string = "";
	RegName: string = "";
	ShortName: string = "";
	RegId: string = "";
	PrimaryNaabCode: string = "";
	Stud?: number;
	Dead: string = "";
	DateOfBirth: string = "";
	Gender: string = "";
	Breed: string = "";
	BreedSort: string = "";
	Haplotypes: string = "";
	IsDead?: boolean;
	UpdateDate: string = "";
	YoungSire: string = "";
	isActiveMarketable?: boolean;
	Recessives: string = "";
	SireStack: string = "";
	Pedigree: string = "";
	ProvenReleaseDate: string = "";
	Controller?: number;

	//Lactation
	fat_lb?: number;
	fat_kg?: number;
	protm?: number;
	pro_p?: number;
	milk_kg?: number;
	milk_lb?: number;
	pro_lb?: number;
	pro_kg?: number;
	fat_p?: number;
	dam_age?: number;
	dam_class?: number;
	dam_dim?: number;
	dam_fat?: number;
	dam_fat_lb?: number;
	dam_fat_kg?: number;
	dam_fat_p?: number;
	dam_milk?: number;
	dam_milk_lb?: number;
	dam_milk_kg?: number;
	dam_pro?: number;
	dam_pro_lb?: number;
	dam_pro_kg?: number;
	dam_pro_p?: number;
	dam_times?: number;

	MGdam_age?: number;
	MGdam_class?: number;
	MGdam_dim?: number;
	MGdam_fat?: number;
	MGdam_fat_lb?: number;
	MGdam_fat_kg?: number;
	MGdam_fat_p?: number;
	MGdam_milk?: number;
	MGdam_milk_lb?: number;
	MGdam_milk_kg?: number;
	MGdam_pro?: number;
	MGdam_pro_lb?: number;
	MGdam_pro_kg?: number;
	MGdam_pro_p?: number;
	MGdam_times?: number;

	//Lineage
	DamRegName: string = "";
	DamRegId: string = "";

	SireRegName: string = "";
	SireShortName: string = "";
	SireNaabCode: string = "";
	SireRegId: string = "";

	PGSireRegName: string = "";
	PGSireShortName: string = "";
	PGSireNaabCode: string = "";
	PGSireRegId: string = "";

	PGDamRegName: string = "";
	PGDamRegId: string = "";

	PPGSireNaabCode: string = "";
	PPGSireRegName: string = "";
	PPGSireShortName: string = "";
	PPGSireRegId: string = "";

	PPGDamRegName: string = "";
	PPGDamRegId: string = "";

	PMGSireNaabCode: string = "";
	PMGSireRegName: string = "";
	PMGSireShortName: string = "";
	PMGSireRegId: string = "";

	PMGDamRegName: string = "";
	PMGDamRegId: string = "";

	MGDamRegName: string = "";
	MGDamRegId: string = "";

	MGSireNaabCode: string = "";
	MGSireShortName: string = "";
	MGSireRegName: string = "";
	MGSireRegId: string = "";

	MMGDamRegName: string = "";
	MMGDamRegId: string = "";

	MMGSireNaabCode: string = "";
	MMGSireRegName: string = "";
	MMGSireShortName: string = "";
	MMGSireRegId: string = "";

	MPGSireNaabCode: string = "";
	MPGSireRegName: string = "";
	MPGSireShortName: string = "";
	MPGSireRegId: string = "";

	MPGDamRegName: string = "";
	MPGDamRegId: string = "";

	//Marketing Rights
	// MarketingRight:string[];
	MarketingRights?: MarketingGroup[];
	MarketingRightCustomer: string = "";
	MarketingRightAction: string = "";
	MarketingRightDate: string = "";

	//Marketing Groups
	Japan: string = "";
	Saudi: string = "";

	New: string = "";
	NewDate: string = "";
	NewExpireDate: string = "";
	Active: string = "";
	ActiveDate: string = "";

	//Designations
	RobotPro: string = "";
	GForcePlus: string = "";
	SexedUltraAccess: string = "";
	Showcase: string = "";
	FertilityPro: string = "";
	ProgenyProven: string = "";
	GForce: string = "";
	EliteSexedFertility: string = "";
	FeedPro: string = "";
	NxGen: string = "";
	MastitisResistantPro: string = "";

	//Genotypes

	BCN: string = "";
	BLG: string = "";
	KC: string = "";

	//Index
	ProductionMaxx: string = "";
	ComponentMaxx: string = "";
	Balanced: string = "";
	GrazingPro: string = "";

	// Health Tests
	EHD: string = "";
	IBR: string = "";
	Johnes: string = "";
	JohnesFecal: string = "";
	LEU: string = "";
	BT: string = "";
	UDT: string = "";

	//Production
	source: string = "";
	pkey: string = "";
	ndtrs?: number;
	nhrds?: number;
	ptam?: number;
	ptaf?: number;
	ptaf_p?: number;
	ptap?: number;
	ptap_p?: number;
	yld_rel?: number;
	pa_milk?: number;
	pa_fat?: number;

	//Type
	tsource: string = "";
	mob?: number;
	rls?: number;
	tkey: string = "";
	bd?: number;
	rlr?: number;
	rtp?: number;
	ruh?: number;
	ra?: number;
	thrds?: number;
	ruw?: number;
	trel?: number;
	flc?: number;
	tdtrs?: number;
	fls?: number;
	ftp?: number;
	str?: number;
	fa?: number;
	udc?: number;
	bwc?: number;
	teatl?: number;
	fua?: number;
	ud?: number;
	thurlw?: number;
	df?: number;
	ptat?: number;
	dc?: number;
	stat?: number;
	uc?: number;
	rtps?: number;

	//Management
	ms?: number;
	ms_rel?: number;
	mt?: number;
	mt_rel?: number;
	hh?: number;
	hh_rel?: number;
	efc?: number;
	efc_rel?: number;
	pa_pl?: number;
	pa_pl_rel?: number;
	pl?: number;
	pl_rel?: number;
	sgl?: number;
	sgl_rel?: number;
	liv?: number;
	liv_rel?: number;
	scs?: number;
	scs_rel?: number;
	pa_scs?: number;
	pa_scs_rel?: number;
	dce?: number;
	dce_rel?: number;
	dce_obs?: number;
	sce?: number;
	sce_rel?: number;
	sce_obs?: number;
	pa_liv?: number;
	dsb_rel?: number;
	ssb_rel?: number;
	dsb_pct?: number;
	ssb_pct?: number;
	rfi?: number;
	rfi_rel?: number;
	ebv_bcs?: number;
	ebv_bcs_rel?: number;

	//Indexes

	fs?: number;
	fs_rel?: number;
	wt?: number;
	cw?: number;
	tpi?: number;
	dwp?: number;
	fe?: number;
	nm?: number;
	pa_nm?: number;
	cm_dol?: number;
	fm_dol?: number;
	gm_dol?: number;
	hhp_dol?: number;
	GPro$?: number;

	//Daughter Fertility
	fi?: number;
	ccr?: number;
	ccr_rel?: number;
	hcr?: number;
	hcr_rel?: number;
	dpr?: number;
	dpr_rel?: number;
	pa_ccr?: number;
	pa_dpr?: number;
	pa_hcr_rel?: number;
	pa_ccr_rel?: number;
	pa_dpr_rel?: number;

	//Wellness Traits
	hlv_rel?: number;
	// pa_hlv?: number;
	hlv?: number;
	// hlv_herds?: number;
	// hlv_dtrs?: number;
	da?: number;
	da_rel?: number;
	met?: number;
	met_rel?: number;
	mast?: number;
	mast_rel?: number;
	mf?: number;
	mf_rel?: number;
	rp?: number;
	rp_rel?: number;
	ket?: number;
	ket_rel?: number;
	z_calf_liv?: number;
	z_calf_liv_rel?: number;
	z_calf_scours?: number;
	z_calf_scours_rel?: number;
	z_cystic_ovary?: number;
	z_cystic_ovary_rel?: number;
	z_ket?: number;
	z_ket_rel?: number;
	z_mast?: number;
	z_mast_rel?: number;
	z_mf?: number;
	z_mf_rel?: number;
	z_twins?: number;
	z_twins_rel?: number;
	z_calf_resp?: number;
	z_calf_resp_rel?: number;
	z_abort?: number;
	z_abort_rel?: number;
	z_cow_resp?: number;
	z_cow_resp_rel?: number;
	z_da?: number;
	z_da_rel?: number;
	z_lame?: number;
	z_lame_rel?: number;
	z_met?: number;
	z_met_rel?: number;
	z_rp?: number;
	z_rp_rel?: number;

	//Sire Fertility

	scr?: number;
	scr_rel?: number;
	serv_scr?: number;


	//Uncategorized
	pa_da?: number;
	pa_ket?: number;
	pa_met?: number;
	pa_mf?: number;
	pa_rp?: number;

	// NAV Price info
	ATA_STAR?: number;
	ATA_SSFS?: number;
	ATA_SERV?: number;
	CFI?: number;
	CFI_SERV?: number;
	SX2M_CFI?: number;
	SX2M_CFI_SERV?: number;
	SX4M_CFI?: number;
	SX4M_CFI_SERV?: number;
	EC: string = "";
	Quantity_On_BSO?: number;
	EU_Saleable_Units?: number;
	Non_EU_Saleable_Units?: number;
	Unit_Price?: number;
	Discount_Group: string = "";
	Unit_Cost?: number;
	Full_Ejac: string = "";
	Allocation: string = "";
	LegacyECStatus: string = "";
	Category: string = "";
	MinFlex?: number;
	QuantityInInventory?: number;
	QuantityOnBSO?: number;
	EUSaleableUnits?: number;
	NonEUSaleableUnits?: number;
	UnitPrice?: number;
	Cogs?: number;
	ToBePriced: string = "";
	DiscountGroup: string = "";
	UnitCost?: number;
	Royalty: string = "";
	Retail?: number;
	IsFullBatchOnly: string = "";
	IsAllocationOnly: string = "";
	Blocked: string = "";
	CountryComments: string = "";
	HasItemCard: string = "";
	ManufacturerCode?: number;
	Surcharge?: number;
	SX2M_NaabCode: string = "";
	SX2M_ATA_STAR?: number;
	SX2M_ATA_SSFS?: number;
	SX2M_ATA_SERV?: number;
	SX2M_Royalty: string = "";
	SX2M_Retail?: number;
	SX2M_EC: string = "";
	SX2M_MinFlex?: number;
	SX2M_Cogs?: number;
	SX2M_Quantity_On_BSO?: number;
	SX2M_EU_Saleable_Units?: number;
	SX2M_Category: string = "";
	SX2M_Non_EU_Saleable_Units?: number;
	SX2M_Unit_Price?: number;
	SX2M_ToBePriced: string = "";
	SX2M_Discount_Group: string = "";
	SX2M_Unit_Cost?: number;
	SX2M_Full_Ejac: string = ""; //Full Ejac
	SX2M_Allocation: string = "";
	SX2M_Blocked: string = "";
	SX2M_CountryComments: string = "";
	SX2M_HasItemCard: string = "";
	SX2M_ManufacturerCode?: number;
	SX2M_Surcharge?: number;
	SX4M_EC: string = "";
	SX4M_Royalty: string = "";
	SX4M_Retail?: number;
	SX4M_MinFlex?: number;
	SX4M_Cogs?: number;
	SX4M_NaabCode: string = "";
	SX4M_Category: string = "";
	SX4M_Quantity_On_BSO?: number;
	SX4M_EU_Saleable_Units?: number;
	SX4M_Non_EU_Saleable_Units?: number;
	SX4M_Unit_Price?: number;
	SX4M_Unit_Cost?: number;
	SX4M_ToBePriced: string = "";
	SX4M_Discount_Group: string = "";
	SX4M_Full_Ejac: string = "";
	SX4M_Allocation: string = "";
	SX4M_Blocked: string = "";
	SX4M_CountryComments: string = "";
	SX4M_HasItemCard: string = "";
	SX4M_ManufacturerCode?: number;
	SX4M_Surcharge?: number;
	SX2MBM_NaabCode: string = "";
	SX2MBM_ATA_STAR?: number;
	SX2MBM_ATA_SSFS?: number;
	SX2MBM_ATA_SERV?: number;
	SX2MBM_Royalty: string = "";
	SX2MBM_Retail?: number;
	SX2MBM_EC: string = "";
	SX2MBM_MinFlex?: number;
	SX2MBM_Cogs?: number;
	SX2MBM_Quantity_On_BSO?: number;
	SX2MBM_EU_Saleable_Units?: number;
	SX2MBM_Category: string = "";
	SX2MBM_Non_EU_Saleable_Units?: number;
	SX2MBM_Unit_Price?: number;
	SX2MBM_ToBePriced: string = "";
	SX2MBM_Discount_Group: string = "";
	SX2MBM_Unit_Cost?: number;
	SX2MBM_Full_Ejac: string = ""; //Full Ejac
	SX2MBM_Allocation: string = "";
	SX2MBM_Blocked: string = "";
	SX2MBM_CountryComments: string = "";
	SX2MBM_HasItemCard: string = "";
	SX2MBM_ManufacturerCode?: number;
	SX4MBM_ATA_STAR?: number;
	SX4MBM_ATA_SSFS?: number;
	SX4MBM_ATA_SERV?: number;
	SX4MBM_EC: string = "";
	SX4MBM_Royalty: string = "";
	SX4MBM_Retail?: number;
	SX4MBM_MinFlex?: number;
	SX4MBM_Cogs?: number;
	SX4MBM_NaabCode: string = "";
	SX4MBM_Category: string = "";
	SX4MBM_Quantity_On_BSO?: number;
	SX4MBM_EU_Saleable_Units?: number;
	SX4MBM_Non_EU_Saleable_Units?: number;
	SX4MBM_Unit_Price?: number;
	SX4MBM_Unit_Cost?: number;
	SX4MBM_ToBePriced: string = "";
	SX4MBM_Discount_Group: string = "";
	SX4MBM_Full_Ejac: string = "";
	SX4MBM_Allocation: string = "";
	SX4MBM_Blocked: string = "";
	SX4MBM_CountryComments: string = "";
	SX4MBM_HasItemCard: string = "";
	SX4MBM_ManufacturerCode?: number;

	//Fertility_DEU
	vzrtoe?: number;
	vzrher?: number;
	vzr?: number;
	si_vzr?: number;
	nrrtoe?: number;
	nrrher?: number;
	nrr?: number;
	si_nrr?: number;
	rz_toe?: number;
	rz_her?: number;
	rz?: number;
	si_rz?: number;
	nrktoe?: number;
	nrkher?: number;
	nrk?: number;
	si_nrk?: number;
	vzktoe?: number;
	vzkher?: number;
	vzk?: number;
	si_vzk?: number;
	gz_toe?: number;
	gz_her?: number;
	gz?: number;
	si_gz?: number;
	rzkon?: number;
	si_rzkon?: number;
	kvd_nk?: number;
	kvdher?: number;
	kvd?: number;
	si_kvd?: number;
	tgd_nk?: number;
	tgdher?: number;
	tgd?: number;
	si_tgd?: number;
	kvmtoe?: number;
	kvmher?: number;
	kvm?: number;
	si_kvm?: number;
	tgmtoe?: number;
	tgmher?: number;
	tgm?: number;
	si_tgm?: number;
	rzkd?: number;
	si_rzkd?: number;
	q_rzkd?: string = "";
	rzkm_2?: number;
	si_rzkm_2?: number;
	q_rzkm_2?: string = "";
	anzeb?: number;
	befru?: number;
	q_befru?: string = "";

	//Health_DEU
	rzeuterfittoe?: number;
	rzeuterfither?: number;
	rzeuterfit?: number;
	si_rzeuterfit?: number;
	q_rzeuterfit?: string = "";
	ddcontroltoe?: number;
	ddcontrolher?: number;
	ddcontrol?: number;
	si_ddcontrol?: number;
	q_ddcontrol?: string = "";
	rzklauentoe?: number;
	rzklauenher?: number;
	rzklauen?: number;
	si_rzklauen?: number;
	q_rzklauen?: string = "";
	rzmetaboltoe?: number;
	rzmetabolher?: number;
	rzmetabol?: number;
	si_rzmetabol?: number;
	q_rzmetabol?: string = "";
	rzreprotoe?: number;
	rzreproher?: number;
	rzrepro?: number;
	si_rzrepro?: number;
	q_rzrepro?: string = "";
	rzgesundtoe?: number;
	rzgesundher?: number;
	rzgesund?: number;
	si_rzgesund?: number;
	q_rzgesund?: string = "";
	rzkaelberfittoe?: number;
	rzkaelberfither?: number;
	rzkaelberfit?: number;
	si_rzkaelberfit?: number;
	q_rzkaelberfit?: string = "";

	//Management_DEU
	mvhtoe?: number;
	mvhher?: number;
	mvh?: number;
	si_mvh?: number;

	//Production_DEU
	toe_rzm?: number;
	herd_rzm?: number;
	pm1?: number;
	pm2?: number;
	pm3?: number;
	toe3pm?: number;
	zwmkg?: number;
	zwfpr?: number;
	zwfkg?: number;
	zwepr?: number;
	zwekg?: number;
	rzetoe?: number;
	rzeher?: number;
	dmgtoe?: number;
	dmgher?: number;
	dmg?: number;
	si_dmg?: number;
	mbktoe?: number;
	mbkher?: number;
	mbk?: number;
	si_mbk?: number;
	ndtoe?: number;
	ndher?: number;

	//TotalBreedingValue_DEU
	rzg?: number;
	si_rzg?: number;
	q_rzg?: string = "";
	rzeuro?: number;
	si_rzeuro?: number;
	q_rzeuro?: string = "";
	rzm?: number;
	si_rzm?: number;
	q_rzm?: string = "";
	rze?: number;
	si_rze?: number;
	q_rze?: string = "";
	mty?: number;
	kor?: number;
	fun?: number;
	eut?: number;
	rzs?: number;
	si_rzs?: number;
	q_rzs?: string = "";

	rzn?: number;
	si_rzn?: number;
	q_rzn?: string = "";
	rzr?: number;
	si_rzr?: number;
	q_rzr?: string = "";
	rzkm?: number;
	si_rzkm?: number;
	q_rzkm?: string = "";
	rzd?: number;
	si_rzd?: number;
	q_rzd?: string = "";
	rzrobot?: number;
	si_rzrobot?: number;
	rzfe1?: number;
	rzfe2?: number;
	rzfe3?: number;
	rzfe1toe?: number;
	rzfe2toe?: number;
	rzfe3toe?: number;
	rzfe1her?: number;
	rzfe2her?: number;
	rzfe3her?: number;
	rg_rzoeko_gj?: number;
	rg_rzoeko_vhg?: number;
	si_fe1?: number;
	si_fe2?: number;
	si_fe3?: number;
	si_rzoeko?: number;
	rzoeko?: number;

	//Type_DEU
	gro?: number;
	mch?: number;
	kti?: number;
	sta?: number;
	bne?: number;
	bbr?: number;
	hwi?: number;
	kwi?: number;
	spr?: number;
	hbs?: number;
	heu?: number;
	zba?: number;
	spv?: number;
	sph?: number;
	veu?: number;
	eti?: number;
	stl?: number;
	bew?: number;
	bcs?: number;
	vov?: number;
	gvov?: number;
	dpers?: number;
	gpers?: number;
	si_pers?: number;
	ris?: number;
	gris?: number;
	rzpersistenz?: number;
	eub?: number;
	geub?: number;

	//Braunvieh_DEU
	eiw_pct?: number;
	fet_pct?: number;
	aus?: number;
	bec?: number;
	bs_dmg?: number;
	egw?: number;
	eiw?: number;
	bs_eut?: number;
	ext?: number;
	ext_si?: number;
	fet?: number;
	ffr?: number;
	fit?: number;
	fit_si?: number;
	frw?: number;
	bs_fun?: number;
	fw?: number;
	fw_si?: number;
	gzw?: number;
	gzw_si?: number;
	hkl?: number;
	km?: number;
	kp?: number;
	mas?: number;
	mkg?: number;
	bs_mvh?: number;
	mw?: number;
	mw_si?: number;
	nd?: number;
	ntz?: number;
	özw?: number;
	özw_si?: number;
	pers?: number;
	rah?: number;
	bs_sph?: number;
	bs_spv?: number;
	viw?: number;
	bs_zba?: number;
	zys?: number;
	zz?: number;
	bb?: number;
	bl?: number;
	bm?: number;
	bn?: number;
	brb?: number;
	er?: number;
	et?: number;
	bs_eub?: number;
	bs_fe?: number;
	heb?: number;
	heh?: number;
	kh?: number;
	mif?: number;
	ol?: number;
	rt?: number;
	sd?: number;
	sl?: number;
	spa?: number;
	spw?: number;
	ss?: number;
	tr?: number;
	umd?: number;
	va?: number;
	vel?: number;

	//Meta_DEU
	verbleib: string = "";
	besitzer: string = "";
	stations_code: number;
	ranking_platz: number;
	informations_code: number;
	rang_rzg: number;
	aktiv: string = "";
	interbull_id: string = "";
	herdbook_num: number;

	// Indexes_AUS
	bpi: number;
	bpi_rel: number;
	asi: number;
	hwi_aus: number;
	hwi_aus_rel: number;
	si: number;
	si_rel: number;

	// Production_AUS
	prot_bv: number;
	protp_bv: number;
	milk_bv: number;
	fat_bv: number;
	fatp_bv: number;
	prot_rel: number;
	dtrs_no_obs: number;
	dtrs_no_herds: number;
	dtrs_no_h1: number;
	dtrs_no_h2: number;
	dtrs_rip: number;

	// Type_AUS
	otype_bv: number;
	mamm_bv: number;
	ofeet_legs: number;
	stat_bv: number;
	udtex_bv: number;
	bone_bv: number;
	angul_bv: number;
	muzw_bv: number;
	bodyl_bv: number;
	bodyd_bv: number;
	loin_bv: number;
	chestw_bv: number;
	rumpl_bv: number;
	pinw_bv: number;
	pinset_bv: number;
	foota_bv: number;
	heel_depth: number;
	rset_bv: number;
	rleg_bv: number;
	uddep_bv: number;
	forea_bv: number;
	rear_ah_bv: number;
	rear_aw_bv: number;
	cent_l_bv: number;
	teat_pf_bv: number;
	teat_pr_bv: number;
	teat_l_bv: number;
	condition_score: number;
	tgen_rel: number;
	tgen_no_obs: number;
	tgen_no_herds: number;
	rump_bv: number;
	dairy_s_bv: number;

	// Management_AUS
	mspeed_bv: number;
	temp_bv: number;
	like_bv: number;
	mspeed_rel: number;
	wgen_no_obs: number;
	wgen_no_herds: number;
	surv_bv: number;
	surv_rel: number;
	scc_bv: number;
	scc_rel: number;
	scc_no_obs: number;
	scc_no_herds: number;
	fert_bv: number;
	fert_rel: number;
	fert_no_obs: number;
	fert_no_herds: number;
	lwt_bv: number;
	lwt_rel: number;
	has_gen: number;
	ressur_bv: number;
	ressur_rel: number;
	feedef_bv: number;
	feedef_rel: number;
	heat_t_bv: number;
	heat_t_rel: number;
	heat_no_obs: number;
	heat_no_herds: number;
	mas_bv: number;
	mas_rel: number;
	maternal_ease_no_obs: number;
	maternal_ease_no_herds: number;

	// Calving_AUS
	ease_bv: number;
	ease_rel: number;
	ease_no_obs: number;
	ease_no_herds: number;
	gestation_l_bv: number;
	gestation_l_rel: number;
	gestation_ease_no_obs: number;
	gestation_ease_no_herds: number;

	alt_regid: string = "";
	animal_national_id: string = "";
	herdbook_num_aus: string = "";

	// CDDR_Production_GBR
	daughter_count: number;
	herds_with_daughters: number;
	pta_mfp_reliability_lac_all: number;
	pta_milk_lac_all: number;
	pta_fat_lac_all: number;
	pta_protein_lac_all: number;
	pta_fat_perc_lac_all: number;
	pta_protein_perc_lac_all: number;

	// CDDR_Management_GBR
	pta_lifespan_reliability: number;
	pta_lifespan: number;
	dairycarcaseindex: number;
	dairycarcaseindexreliability: number;
	lifespan: number;
	maintenance: number;
	gestationlength: number;
	gestationlengthreliability: number;
	envirocow: number;
	envirocowreliability: number;
	feedadvantage: number;
	feedadvantagereliability: number;
	temperament_gebv: number;
	temperament_gebv_rel: number;
	ease_of_milk_gebv: number;
	ease_of_milk_gebv_rel: number;

	// CDDR_Type_GBR
	daughters: number;
	herds: number;
	type_merit_gebv: number;
	type_merit_gebv_rel: number;
	mammary_gebv: number;
	mammary_gebv_rel: number;
	legs_feet_gebv: number;
	legs_feet_gebv_rel: number;
	stature_gebv: number;
	stature_gebv_rel: number;
	chest_width_gebv: number;
	chest_width_gebv_rel: number;
	body_depth_gebv: number;
	body_depth_gebv_rel: number;
	angularity_gebv: number;
	angularity_gebv_rel: number;
	rump_angle_gebv: number;
	rump_angle_gebv_rel: number;
	rump_width_gebv: number;
	rump_width_gebv_rel: number;
	rear_leg_side_gebv: number;
	rear_leg_side_gebv_rel: number;
	foot_angle_gebv: number;
	foot_angle_gebv_rel: number;
	fore_udd_att_gebv: number;
	fore_udd_att_gebv_rel: number;
	rear_udder_ht_gebv: number;
	rear_udder_ht_gebv_rel: number;
	udder_supp_gebv: number;
	udder_supp_gebv_rel: number;
	udder_depth_gebv: number;
	udder_depth_gebv_rel: number;
	front_teat_pl_gebv: number;
	front_teat_pl_gebv_rel: number;
	teat_length_gebv: number;
	teat_length_gebv_rel: number;
	rear_teat_pl_gebv: number;
	rear_teat_pl_gebv_rel: number;
	teat_pos_side_gebv: number;
	teat_pos_side_gebv_rel: number;
	locomotion_gebv: number;
	locomotion_gebv_rel: number;
	cond_score_gebv: number;
	cond_score_gebv_rel: number;

	// CDDR_Index_GBR
	calving_interval_index: number;
	non_return_index: number;
	tbadvantagereliability: number;
	tbadvantage: number;
	plireliability: number;
	pli: number;
	aci: number;

	// CDDR_IntSCI_GBR
	intsci_pta_milk_lac_all: number;
	intsci_pta_fat_lac_all: number;
	intsci_pta_protein_lac_all: number;
	intsci_pta_fat_perc_lac_all: number;
	intsci_pta_protein_perc_lac_all: number;
	intsci_pta_scc: number;
	intsci_lifespan: number;
	intsci_fertility_index: number;
	intsci_maintenance: number;
	sci: number;

	// CDDR_Health_GBR
	pta_scc_reliability: number;
	pta_scc: number;
	fertility_index_reliability: number;
	fertility_index: number;
	direct_ce_reliability: number;
	direct_ce: number;
	maternal_ce_reliability: number;
	maternal_ce: number;
	mastitisreliability: number;
	mastitis: number;
	calf_survival: number;
	calfsurvivalreliability: number;
	lamenessadvantage: number;
	lamenessreliability: number;
	digitaldermatitis: number;
	digitaldermatitisreliability: number;
	healthycow: number;
	healthycowreliability: number;

	// UK Official Health
	calf_survival_uk_official: number;
	calfsurvival_rel_uk_official: number;
	digitaldermatitis_uk_official: number;
	digitaldermatitis_rel_uk_official: number;
	fertility_index_uk_official: number;
	fertility_index_rel_uk_official: number;
	hc_uk_official: number;
	hc_rel_uk_official: number;
	lame_uk_official: number;
	lame_rel_uk_official: number;
	lifespan_uk_official: number;
	mastitis_uk_official: number;
	mastitis_rel_uk_official: number;
	pta_scc_uk_official: number;
	pta_scc_rel_uk_official: number;
	tb_adv_uk_official: number;
	tb_adv_rel_uk_official: number;

	// UK Official Production
	fhmd_dtrs_p_uk_official: number;
	shmd_dtrs_p_uk_official: number;
	avg_heifer_fat_yld_uk_official: number;
	avg_heifer_fat_yld_p_uk_official: number;
	dtr_count_uk_official: number;
	daughter_count_uk_official: number;
	herds_with_daughters_uk_official: number;
	lact_count_uk_official: number;
	avg_heifer_milk_yld_uk_official: number;
	avg_heifer_pro_yld_uk_official: number;
	avg_heifer_pro_yld_p_uk_official: number;
	pta_fat_lac_all_uk_official: number;
	pta_fat_perc_lac_all_uk_official: number;
	pta_mfp_rel_uk_official: number;
	pta_milk_lac_all_uk_official: number;
	pta_protein_lac_all_uk_official: number;
	pta_protein_perc_lac_all_uk_official: number;

	// UK Official Management
	calving_interval_index_uk_official: number;
	cc_uk_official: number;
	cc_rel_uk_official: number;
	dcg_rel_uk_official: number;
	dci_uk_official: number;
	dci_rel_uk_official: number;
	direct_ce_uk_official: number;
	direct_ce_rel_uk_official: number;
	envirocow_uk_official: number;
	envirocow_rel_uk_official: number;
	feedadvantage_uk_official: number;
	feedadvantage_rel_uk_official: number;
	gestationlength_uk_official: number;
	gestationlength_rel_uk_official: number;
	maintenance_uk_official: number;
	maternal_ce_uk_official: number;
	maternal_ce_rel_uk_official: number;
	non_return_index_uk_official: number;
	pta_persist_uk_official: number;

	// UK Official Type
	angularity_gebv_uk_official: number;
	body_conf_score_uk_official: number;
	body_depth_gebv_uk_official: number;
	chest_width_gebv_uk_official: number;
	cond_score_gebv_uk_official: number;
	daughters_uk_official: number;
	herds_uk_official: number;
	foot_angle_gebv_uk_official: number;
	fore_udd_att_gebv_uk_official: number;
	front_teat_pl_gebv_uk_official: number;
	legs_feet_gebv_uk_official: number;
	locomotion_gebv_uk_official: number;
	locomotion_gebv_rel_uk_official: number;
	mammary_gebv_uk_official: number;
	ease_of_milk_gebv_uk_official: number;
	rear_leg_side_gebv_uk_official: number;
	rear_teat_pl_gebv_uk_official: number;
	rear_udder_ht_gebv_uk_official: number;
	rump_angle_gebv_uk_official: number;
	rump_width_gebv_uk_official: number;
	stature_gebv_uk_official: number;
	teat_length_gebv_uk_official: number;
	teat_pos_side_gebv_uk_official: number;
	temperament_gebv_uk_official: number;
	type_merit_gebv_uk_official: number;
	type_merit_gebv_rel_uk_official: number;
	udder_depth_gebv_uk_official: number;
	udder_supp_gebv_uk_official: number;

	// UK Official Indexes
	pli_uk_official: number;
	pli_rel_uk_official: number;

	constructor(bullDataObj: BullResponse) {
		this.Id = bullDataObj.Id || "";
		this.RegName = bullDataObj.RegName || "";
		this.ShortName = bullDataObj.ShortName || "";
		this.RegId = bullDataObj.RegId || "";
		this.PrimaryNaabCode = bullDataObj.PrimaryNaabCode || "";
		this.Stud = bullDataObj.Stud;
		this.DateOfBirth = new Date(bullDataObj.DateOfBirth).toISOString().replace(/^([0-9\-]*)(T.*)/, "$1") || "";
		this.Breed = bullDataObj.Breed || "";
		this.BreedSort = bullDataObj.BreedSort || "";
		this.Haplotypes = bullDataObj.Haplotypes;
		if (bullDataObj.IsDead === true) {
			this.Dead = "Yes";
		}

		this.Pedigree = bullDataObj.SireStack;
		this.Recessives = bullDataObj.Recessives;
		// this.YoungSire = bullDataObj.YoungSire;
		this.Gender = bullDataObj.Gender;
		this.Country = bullDataObj.Country;
		this.Controller = bullDataObj.Controller;
		this.YoungSire = bullDataObj.YoungSire;
		this.ProvenReleaseDate = bullDataObj.ReleaseDate;
		this.Recessives = bullDataObj.Recessives;
	}
}

export const columnDefiner = {
	Fertility_DEU: {
		vzrtoe: 1,
		vzrher: 1,
		vzr: 1,
		si_vzr: 1,
		nrrtoe: 1,
		nrrher: 1,
		nrr: 1,
		si_nrr: 1,
		rz_toe: 1,
		rz_her: 1,
		rz: 1,
		si_rz: 1,
		nrktoe: 1,
		nrkher: 1,
		nrk: 1,
		si_nrk: 1,
		vzktoe: 1,
		vzkher: 1,
		vzk: 1,
		si_vzk: 1,
		gz_toe: 1,
		gz_her: 1,
		gz: 1,
		si_gz: 1,
		rzkon: 1,
		si_rzkon: 1,
		kvd_nk: 1,
		kvdher: 1,
		kvd: 1,
		si_kvd: 1,
		tgd_nk: 1,
		tgdher: 1,
		tgd: 1,
		si_tgd: 1,
		kvmtoe: 1,
		kvmher: 1,
		kvm: 1,
		si_kvm: 1,
		tgmtoe: 1,
		tgmher: 1,
		tgm: 1,
		si_tgm: 1,
		rzkd: 1,
		si_rzkd: 1,
		q_rzkd: "",
		rzkm_2: 1,
		si_rzkm_2: 1,
		q_rzkm_2: "",
		anzeb: 1,
		befru: 1,
		q_befru: "",
		rzr: 1,
		si_rzr: 1,
		q_rzr: "",
	},
	Meta_DEU: {
		verbleib: "",
		besitzer: "",
		stations_code: 1,
		ranking_platz: 1,
		aktiv: "",
		interbull_id: "",
		herdbook_num: 1,
		informations_code: 1,
		rang_rzg: 1,
	},
	Health_DEU: {
		rzeuterfittoe: 1,
		rzeuterfither: 1,
		rzeuterfit: 1,
		si_rzeuterfit: 1,
		q_rzeuterfit: "",
		ddcontroltoe: 1,
		ddcontrolher: 1,
		ddcontrol: 1,
		si_ddcontrol: 1,
		q_ddcontrol: "",
		rzklauentoe: 1,
		rzklauenher: 1,
		rzklauen: 1,
		si_rzklauen: 1,
		q_rzklauen: "",
		rzmetaboltoe: 1,
		rzmetabolher: 1,
		rzmetabol: 1,
		si_rzmetabol: 1,
		q_rzmetabol: "",
		rzreprotoe: 1,
		rzreproher: 1,
		rzrepro: 1,
		si_rzrepro: 1,
		q_rzrepro: "",
		rzgesundtoe: 1,
		rzgesundher: 1,
		rzgesund: 1,
		si_rzgesund: 1,
		q_rzgesund: "",
		rzkaelberfittoe: 1,
		rzkaelberfither: 1,
		rzkaelberfit: 1,
		si_rzkaelberfit: 1,
		q_rzkaelberfit: "",
	},
	Production_DEU: {
		rzm: 1,
		si_rzm: 1,
		q_rzm: "",
		rzs: 1,
		si_rzs: 1,
		q_rzs: "",
		rzd: 1,
		si_rzd: 1,
		q_rzd: "",
		dmgtoe: 1,
		dmgher: 1,
		dmg: 1,
		si_dmg: 1,
		ndtoe: 1,
		ndher: 1,
		mbktoe: 1,
		mbkher: 1,
		mbk: 1,
		si_mbk: 1,
		toe_rzm: 1,
		herd_rzm: 1,
		pm1: 1,
		pm2: 1,
		pm3: 1,
		toe3pm: 1,
		zwmkg: 1,
		zwfpr: 1,
		zwfkg: 1,
		zwepr: 1,
		zwekg: 1,
		mvhtoe: 1,
		mvhher: 1,
		mvh: 1,
		si_mvh: 1,
		rzrobot: 1,
		si_rzrobot: 1,
	},
	TotalBreedingValues_DEU: {
		rzg: 1,
		si_rzg: 1,
		q_rzg: "",
		rzeuro: 1,
		si_rzeuro: 1,
		q_rzeuro: "",
		rzn: 1,
		si_rzn: 1,
		q_rzn: "",
		rzkm: 1,
		si_rzkm: 1,
		q_rzkm: "",
		rzfe1: 1,
		rzfe2: 1,
		rzfe3: 1,
		rzfe1toe: 1,
		rzfe2toe: 1,
		rzfe3toe: 1,
		rzfe1her: 1,
		rzfe2her: 1,
		rzfe3her: 1,
		rg_rzoeko_gj: 1,
		rg_rzoeko_vhg: 1,
		si_fe1: 1,
		si_fe2: 1,
		si_fe3: 1,
		si_rzoeko: 1,
		rzoeko: 1
	},
	Type_DEU: {
		mty: 1,
		kor: 1,
		fun: 1,
		eut: 1,
		gro: 1,
		mch: 1,
		kti: 1,
		sta: 1,
		bne: 1,
		bbr: 1,
		hwi: 1,
		kwi: 1,
		spr: 1,
		hbs: 1,
		heu: 1,
		zba: 1,
		spv: 1,
		sph: 1,
		veu: 1,
		eti: 1,
		stl: 1,
		bew: 1,
		bcs: 1,
		rze: 1,
		si_rze: 1,
		q_rze: "",
		rzetoe: 1,
		rzeher: 1,
		vov: 1,
		gvov: 1,
		dpers: 1,
		gpers: 1,
		si_pers: 1,
		ris: 1,
		gris: 1,
		rzpersistenz: 1,
		eub: 1,
		geub: 1
	},
	Braunvieh_DEU: {
		eiw_pct: 1,
		fet_pct: 1,
		aus: 1,
		bec: 1,
		bs_dmg: 1,
		egw: 1,
		eiw: 1,
		bs_eut: 1,
		ext: 1,
		ext_si: 1,
		fet: 1,
		ffr: 1,
		fit: 1,
		fit_si: 1,
		frw: 1,
		bs_fun: 1,
		fw: 1,
		fw_si: 1,
		gzw: 1,
		gzw_si: 1,
		hkl: 1,
		km: 1,
		kp: 1,
		mas: 1,
		mkg: 1,
		bs_mvh: 1,
		mw: 1,
		mw_si: 1,
		nd: 1,
		ntz: 1,
		özw: 1,
		özw_si: 1,
		pers: 1,
		rah: 1,
		bs_sph: 1,
		bs_spv: 1,
		viw: 1,
		bs_zba: 1,
		zys: 1,
		zz: 1,
		bb: 1,
		bl: 1,
		bm: 1,
		bn: 1,
		brb: 1,
		er: 1,
		et: 1,
		bs_eub: 1,
		bs_fe: 1,
		heb: 1,
		heh: 1,
		kh: 1,
		mif: 1,
		ol: 1,
		rt: 1,
		sd: 1,
		sl: 1,
		spa: 1,
		spw: 1,
		ss: 1,
		tr: 1,
		umd: 1,
		va: 1,
		vel: 1,
	},
	Indexes_AUS: {
		bpi: 1,
		bpi_rel: 1,
		asi: 1,
		hwi_aus: 1,
		hwi_aus_rel: 1,
		si: 1,
		si_rel: 1,
	},
	Production_AUS: {
		prot_bv: 1,
		protp_bv: 1,
		milk_bv: 1,
		fat_bv: 1,
		fatp_bv: 1,
		prot_rel: 1,
		dtrs_no_obs: 1,
		dtrs_no_herds: 1,
		dtrs_no_h1: 1,
		dtrs_no_h2: 1,
		dtrs_rip: 1,
	},
	Management_AUS: {
		mspeed_bv: 1,
		temp_bv: 1,
		like_bv: 1,
		mspeed_rel: 1,
		wgen_no_obs: 1,
		wgen_no_herds: 1,
		surv_bv: 1,
		surv_rel: 1,
		scc_bv: 1,
		scc_rel: 1,
		scc_no_obs: 1,
		scc_no_herds: 1,
		fert_bv: 1,
		fert_rel: 1,
		fert_no_obs: 1,
		fert_no_herds: 1,
		lwt_bv: 1,
		lwt_rel: 1,
		has_gen: 1,
		ressur_bv: 1,
		ressur_rel: 1,
		feedef_bv: 1,
		feedef_rel: 1,
		heat_t_bv: 1,
		heat_t_rel: 1,
		heat_no_obs: 1,
		heat_no_herds: 1,
		mas_bv: 1,
		mas_rel: 1,
		maternal_ease_no_obs: 1,
		maternal_ease_no_herds: 1,
	},
	Type_AUS: {
		otype_bv: 1,
		mamm_bv: 1,
		ofeet_legs: 1,
		stat_bv: 1,
		udtex_bv: 1,
		bone_bv: 1,
		angul_bv: 1,
		muzw_bv: 1,
		bodyl_bv: 1,
		bodyd_bv: 1,
		loin_bv: 1,
		chestw_bv: 1,
		rumpl_bv: 1,
		pinw_bv: 1,
		pinset_bv: 1,
		foota_bv: 1,
		heel_depth: 1,
		rset_bv: 1,
		rleg_bv: 1,
		uddep_bv: 1,
		forea_bv: 1,
		rear_ah_bv: 1,
		rear_aw_bv: 1,
		cent_l_bv: 1,
		teat_pf_bv: 1,
		teat_pr_bv: 1,
		teat_l_bv: 1,
		condition_score: 1,
		tgen_rel: 1,
		tgen_no_obs: 1,
		tgen_no_herds: 1,
		rump_bv: 1,
		dairy_s_bv: 1,
	},
	Calving_AUS: {
		ease_bv: 1,
		ease_rel: 1,
		ease_no_obs: 1,
		ease_no_herds: 1,
		gestation_l_bv: 1,
		gestation_l_rel: 1,
		gestation_ease_no_obs: 1,
		gestation_ease_no_herds: 1,
	},
	Meta_AUS: {
		alt_regid: "",
		animal_national_id: "",
		herdbook_num_aus: "",
	},
	CDDR_Production_GBR: {
		daughter_count: 1,
		herds_with_daughters: 1,
		pta_mfp_reliability_lac_all: 1,
		pta_milk_lac_all: 1,
		pta_fat_lac_all: 1,
		pta_protein_lac_all: 1,
		pta_fat_perc_lac_all: 1,
		pta_protein_perc_lac_all: 1,
	},
	CDDR_Management_GBR: {
		pta_lifespan_reliability: 1,
		pta_lifespan: 1,
		dairycarcaseindex: 1,
		dairycarcaseindexreliability: 1,
		lifespan: 1,
		maintenance: 1,
		gestationlength: 1,
		gestationlengthreliability: 1,
		envirocow: 1,
		envirocowreliability: 1,
		feedadvantage: 1,
		feedadvantagereliability: 1,
		temperament_gebv: 1,
		temperament_gebv_rel: 1,
		ease_of_milk_gebv: 1,
		ease_of_milk_gebv_rel: 1,
	},
	CDDR_Type_GBR: {
		daughters: 1,
		herds: 1,
		type_merit_gebv: 1,
		type_merit_gebv_rel: 1,
		mammary_gebv: 1,
		mammary_gebv_rel: 1,
		legs_feet_gebv: 1,
		legs_feet_gebv_rel: 1,
		stature_gebv: 1,
		stature_gebv_rel: 1,
		chest_width_gebv: 1,
		chest_width_gebv_rel: 1,
		body_depth_gebv: 1,
		body_depth_gebv_rel: 1,
		angularity_gebv: 1,
		angularity_gebv_rel: 1,
		rump_angle_gebv: 1,
		rump_angle_gebv_rel: 1,
		rump_width_gebv: 1,
		rump_width_gebv_rel: 1,
		rear_leg_side_gebv: 1,
		rear_leg_side_gebv_rel: 1,
		foot_angle_gebv: 1,
		foot_angle_gebv_rel: 1,
		fore_udd_att_gebv: 1,
		fore_udd_att_gebv_rel: 1,
		rear_udder_ht_gebv: 1,
		rear_udder_ht_gebv_rel: 1,
		udder_supp_gebv: 1,
		udder_supp_gebv_rel: 1,
		udder_depth_gebv: 1,
		udder_depth_gebv_rel: 1,
		front_teat_pl_gebv: 1,
		front_teat_pl_gebv_rel: 1,
		teat_length_gebv: 1,
		teat_length_gebv_rel: 1,
		rear_teat_pl_gebv: 1,
		rear_teat_pl_gebv_rel: 1,
		teat_pos_side_gebv: 1,
		teat_pos_side_gebv_rel: 1,
		locomotion_gebv: 1,
		locomotion_gebv_rel: 1,
		cond_score_gebv: 1,
		cond_score_gebv_rel: 1,
	},
	CDDR_Index_GBR: {
		calving_interval_index: 1,
		non_return_index: 1,
		tbadvantagereliability: 1,
		tbadvantage: 1,
		plireliability: 1,
		pli: 1,
		aci: 1,
	},
	CDDR_IntSCI_GBR: {
		intsci_pta_milk_lac_all: 1,
		intsci_pta_fat_lac_all: 1,
		intsci_pta_protein_lac_all: 1,
		intsci_pta_fat_perc_lac_all: 1,
		intsci_pta_protein_perc_lac_all: 1,
		intsci_pta_scc: 1,
		intsci_lifespan: 1,
		intsci_fertility_index: 1,
		intsci_maintenance: 1,
		sci: 1,
	},
	CDDR_Health_GBR: {
		pta_scc_reliability: 1,
		pta_scc: 1,
		fertility_index_reliability: 1,
		fertility_index: 1,
		direct_ce_reliability: 1,
		direct_ce: 1,
		maternal_ce_reliability: 1,
		maternal_ce: 1,
		mastitisreliability: 1,
		mastitis: 1,
		calf_survival: 1,
		calfsurvivalreliability: 1,
		lamenessadvantage: 1,
		lamenessreliability: 1,
		digitaldermatitis: 1,
		digitaldermatitisreliability: 1,
		healthycow: 1,
		healthycowreliability: 1,
	},
	Health_GBR: {
		calf_survival_uk_official: 1,
		calfsurvival_rel_uk_official: 1,
		digitaldermatitis_uk_official: 1,
		digitaldermatitis_rel_uk_official: 1,
		fertility_index_uk_official: 1,
		fertility_index_rel_uk_official: 1,
		hc_uk_official: 1,
		hc_rel_uk_official: 1,
		lame_uk_official: 1,
		lame_rel_uk_official: 1,
		lifespan_uk_official: 1,
		mastitis_uk_official: 1,
		mastitis_rel_uk_official: 1,
		pta_scc_uk_official: 1,
		pta_scc_rel_uk_official: 1,
		tb_adv_uk_official: 1,
		tb_adv_rel_uk_official: 1,
	},
	Production_GBR: {
		fhmd_dtrs_p_uk_official: 1,
		shmd_dtrs_p_uk_official: 1,
		avg_heifer_fat_yld_uk_official: 1,
		avg_heifer_fat_yld_p_uk_official: 1,
		dtr_count_uk_official: 1,
		daughter_count_uk_official: 1,
		herds_with_daughters_uk_official: 1,
		lact_count_uk_official: 1,
		avg_heifer_milk_yld_uk_official: 1,
		avg_heifer_pro_yld_uk_official: 1,
		avg_heifer_pro_yld_p_uk_official: 1,
		pta_fat_lac_all_uk_official: 1,
		pta_fat_perc_lac_all_uk_official: 1,
		pta_mfp_rel_uk_official: 1,
		pta_milk_lac_all_uk_official: 1,
		pta_protein_lac_all_uk_official: 1,
		pta_protein_perc_lac_all_uk_official: 1,
	},
	Management_GBR: {
		calving_interval_index_uk_official: 1,
		cc_uk_official: 1,
		cc_rel_uk_official: 1,
		dcg_rel_uk_official: 1,
		dci_uk_official: 1,
		dci_rel_uk_official: 1,
		direct_ce_uk_official: 1,
		direct_ce_rel_uk_official: 1,
		envirocow_uk_official: 1,
		envirocow_rel_uk_official: 1,
		feedadvantage_uk_official: 1,
		feedadvantage_rel_uk_official: 1,
		gestationlength_uk_official: 1,
		gestationlength_rel_uk_official: 1,
		maintenance_uk_official: 1,
		maternal_ce_uk_official: 1,
		maternal_ce_rel_uk_official: 1,
		non_return_index_uk_official: 1,
		pta_persist_uk_official: 1,
	},
	Type_GBR: {
		angularity_gebv_uk_official: 1,
		body_conf_score_uk_official: 1,
		body_depth_gebv_uk_official: 1,
		chest_width_gebv_uk_official: 1,
		cond_score_gebv_uk_official: 1,
		daughters_uk_official: 1,
		herds_uk_official: 1,
		foot_angle_gebv_uk_official: 1,
		fore_udd_att_gebv_uk_official: 1,
		front_teat_pl_gebv_uk_official: 1,
		legs_feet_gebv_uk_official: 1,
		locomotion_gebv_uk_official: 1,
		locomotion_gebv_rel_uk_official: 1,
		mammary_gebv_uk_official: 1,
		ease_of_milk_gebv_uk_official: 1,
		rear_leg_side_gebv_uk_official: 1,
		rear_teat_pl_gebv_uk_official: 1,
		rear_udder_ht_gebv_uk_official: 1,
		rump_angle_gebv_uk_official: 1,
		rump_width_gebv_uk_official: 1,
		stature_gebv_uk_official: 1,
		teat_length_gebv_uk_official: 1,
		teat_pos_side_gebv_uk_official: 1,
		temperament_gebv_uk_official: 1,
		type_merit_gebv_uk_official: 1,
		type_merit_gebv_rel_uk_official: 1,
		udder_depth_gebv_uk_official: 1,
		udder_supp_gebv_uk_official: 1,
	},
	Indexes_GBR: {
		pli_uk_official: 1,
		pli_rel_uk_official: 1,
	},
	Inbreeding: {
		inbred_pct_gen: 1,
		inbred_efi: 1,
		inbred_dtr: 1,
		inbred_pct: 1,
		inbred_efi_gen: 1,
	},
	Meta: {
		AIStatus: "",
		BirthState: "",
		aAa: "",
		DMS: "",
		DnaStatus: "",
		SexedOnly: "",
		PercentBlack: 1,
		Country: "",
		CloneGen: "",
		CrossGen: "",
		RHA_Ind: "",
		RHA_Pct: 1,
		Link: "",
		ConvertedCanadianDaughterAverage: "",
		HousedInCountry: "",
		TopTPI: "",
		TopTPIGenomic: "",
		TopJPI: "",
		TopJPIGenomic: "",
		TopPPR: "",
		TopPPRGenomic: "",
		TopPTAM: "",
		TopPTAMGenomic: "",
		TopRedRCTPI: "",
		TopRedRCTPIGenomic: "",

	},
	Lineage: {
		DamRegName: "",
		DamRegId: "",

		SireRegName: "",
		SireShortName: "",
		SireNaabCode: "",
		SireRegId: "",

		PGSireRegName: "",
		PGSireShortName: "",
		PGSireNaabCode: "",
		PGSireRegId: "",

		PGDamRegName: "",
		PGDamRegId: "",

		PPGSireNaabCode: "",
		PPGSireRegName: "",
		PPGSireShortName: "",
		PPGSireRegId: "",

		PPGDamRegName: "",
		PPGDamRegId: "",

		PMGSireNaabCode: "",
		PMGSireRegName: "",
		PMGSireShortName: "",
		PMGSireRegId: "",

		PMGDamRegName: "",
		PMGDamRegId: "",

		MGDamRegName: "",
		MGDamRegId: "",

		MGSireNaabCode: "",
		MGSireShortName: "",
		MGSireRegName: "",
		MGSireRegId: "",

		MMGDamRegName: "",
		MMGDamRegId: "",

		MMGSireNaabCode: "",
		MMGSireRegName: "",
		MMGSireShortName: "",
		MMGSireRegId: "",

		MPGSireNaabCode: "",
		MPGSireRegName: "",
		MPGSireShortName: "",
		MPGSireRegId: "",

		MPGDamRegName: "",
		MPGDamRegId: "",
	},
	Location: {
		// Type: "",
		MoveDate: "",
		ResidencyDate: "",

		IsProduction: "",
		IsEU: "",
		IsIsolation: "",
		IsLayoff: "",
		BarnCode: "",
		BarnStatus: "",
		State: "",
		Location: "",
		ProductionStatus: "",
	},
	//Base call data
	Base: {
		// Id: "",
		RegId: "",
		YoungSire: "",
		ProvenReleaseDate: "",
		RegName: "",
		Recessives: "",
		Dead: "",
		PrimaryNaabCode: "",
		ShortName: "",
		Stud: 1,

		DateOfBirth: "",
		Breed: "",
		Haplotypes: "",
		BreedSort: "",
		Pedigree: "",
	},

	//Marketing Rights
	MarketingRight: {
		MarketingRightCustomer: "",
		MarketingRightAction: "",
		MarketingRightDate: "",
	},

	MarketingGroup: {
		Active: "",
		ActiveDate: "",
		New: "",
		NewDate: "",
		NewExpireDate: "",
		Japan: "",
		Saudi: "",
	},

	//Designations
	Designation: {
		RobotPro: "",
		GForcePlus: "",
		SexedUltraAccess: "",
		Showcase: "",
		FertilityPro: "",
		ProgenyProven: "",
		GForce: "",
		EliteSexedFertility: "",
		FeedPro: "",
		//GrazePro or something like that may be added to Designation in the future since it is both a Designation and an Index. Designation is a score, Index is a true or false.
		NxGen: "",
		MastitisResistantPro: "",
	},
	Index: {
		ProductionMaxx: "",
		Balanced: "",
		GrazingPro: "",
		ComponentMaxx: "",
	},

	// Health Tests
	HealthTest: {
		EHD: "",
		IBR: "",
		Johnes: "",
		JohnesFecal: "",
		LEU: "",
		BT: "",
		UDT: "",
	},
	Indexes: {
		fs: 1,
		fs_rel: 1,
		wt: 1,
		cw: 1,
		tpi: 1,
		dwp: 1,
		fe: 1,
		nm: 1,
		pa_nm: 1,
		cm_dol: 1,
		ca_dol: 1,
		fm_dol: 1,
		gm_dol: 1,
		hhp_dol: 1,
		GPro$: 1,
	},
	//Production
	Production: {
		source: "",
		pkey: "",
		ndtrs: 1,
		nhrds: 1,
		ptam: 1,
		ptaf: 1,
		ptaf_p: 1,
		ptap: 1,
		ptap_p: 1,
		yld_rel: 1,
		pa_milk: 1,
		pa_fat: 1,
	},

	//Type
	Type: {
		mob: 1,
		rls: 1,
		tsource: "",
		tkey: "",
		bd: 1,
		rlr: 1,
		rtp: 1,
		rtps: 1,
		ruh: 1,
		ra: 1,
		thrds: 1,
		ruw: 1,
		trel: 1,
		flc: 1,
		tdtrs: 1,
		fls: 1,
		ftp: 1,
		str: 1,
		fa: 1,
		udc: 1,
		bwc: 1,
		teatl: 1,
		fua: 1,
		ud: 1,
		thurlw: 1,
		df: 1,
		ptat: 1,
		dc: 1,
		stat: 1,
		uc: 1,
	},

	//Management
	Management: {
		ms: 1,
		ms_rel: 1,
		mt: 1,
		mt_rel: 1,
		hh: 1,
		hh_rel: 1,
		efc: 1,
		efc_rel: 1,
		pa_pl: 1,
		pa_pl_rel: 1,
		pl: 1,
		pl_rel: 1,
		sgl: 1,
		sgl_rel: 1,
		liv: 1,
		liv_rel: 1,
		scs: 1,
		scs_rel: 1,
		pa_scs: 1,
		pa_scs_rel: 1,
		dce: 1,
		dce_rel: 1,
		dce_obs: 1,
		sce: 1,
		sce_rel: 1,
		sce_obs: 1,
		pa_liv: 1,
		dsb_rel: 1,
		ssb_rel: 1,
		dsb_pct: 1,
		ssb_pct: 1,
		rfi: 1,
		rfi_rel: 1,
		ebv_bcs: 1,
		ebv_bcs_rel: 1,
	},

	//Daughter Fertility
	DaughterFertility: {
		fi: 1,
		ccr: 1,
		ccr_rel: 1,
		hcr: 1,
		hcr_rel: 1,
		dpr: 1,
		dpr_rel: 1,
		pa_ccr: 1,
		pa_dpr: 1,
		pa_hcr_rel: 1,
		pa_ccr_rel: 1,
		pa_dpr_rel: 1,
	},

	//Wellness Traits
	WellnessTraits: {
		hlv: 1,
		hlv_rel: 1,
		da: 1,
		da_rel: 1,
		met: 1,
		met_rel: 1,
		mast: 1,
		mast_rel: 1,
		mf: 1,
		mf_rel: 1,
		rp: 1,
		rp_rel: 1,
		ket: 1,
		ket_rel: 1,
		z_calf_liv: 1,
		z_calf_liv_rel: 1,
		z_calf_scours: 1,
		z_calf_scours_rel: 1,
		z_cystic_ovary: 1,
		z_cystic_ovary_rel: 1,
		z_ket: 1,
		z_ket_rel: 1,
		z_mast: 1,
		z_mast_rel: 1,
		z_mf: 1,
		z_mf_rel: 1,
		z_twins: 1,
		z_twins_rel: 1,
		z_calf_resp: 1,
		z_calf_resp_rel: 1,
		z_abort: 1,
		z_abort_rel: 1,
		z_cow_resp: 1,
		z_cow_resp_rel: 1,
		z_da: 1,
		z_da_rel: 1,
		z_lame: 1,
		z_lame_rel: 1,
		z_met: 1,
		z_met_rel: 1,
		z_rp: 1,
		z_rp_rel: 1,
	},

	//Genotypes
	Genotypes: {
		BCN: "",
		BLG: "",
		KC: "",
	},
	//Sire Fertility
	SireFertility: {
		scr: 1,
		scr_rel: 1,
		serv_scr: 1,
		cfi: 1,
		cfi_serv: 1
	},

	//Uncategorized
	Uncategorized: {
		pa_da: 1,
		pa_ket: 1,
		pa_met: 1,
		pa_mf: 1,
		pa_rp: 1,
	},

	Lactation: {
		fat_lb: 1,
		fat_kg: 1,
		protm: 1,
		pro_p: 1,
		milk_kg: 1,
		milk_lb: 1,
		pro_lb: 1,
		pro_kg: 1,
		fat_p: 1,
		dam_age: 1,
		dam_class: 1,
		dam_dim: 1,
		// dam_fat:1,
		dam_fat_lb: 1,
		dam_fat_kg: 1,
		dam_fat_p: 1,
		// dam_milk:1,
		dam_milk_lb: 1,
		dam_milk_kg: 1,
		// dam_pro:1,
		dam_pro_lb: 1,
		dam_pro_kg: 1,
		dam_pro_p: 1,
		dam_times: 1,
		MGdam_age: 1,
		MGdam_class: 1,
		MGdam_dim: 1,
		// dam_fat:1,
		MGdam_fat_lb: 1,
		MGdam_fat_kg: 1,
		MGdam_fat_p: 1,
		// dam_milk:1,
		MGdam_milk_lb: 1,
		MGdam_milk_kg: 1,
		// dam_pro:1,
		MGdam_pro_lb: 1,
		MGdam_pro_kg: 1,
		MGdam_pro_p: 1,
		MGdam_times: 1,
	},

	// NAV Price info
	BeefMale: {
		SX2MBM_NaabCode: "",
		SX2MBM_ATA_STAR: 1,
		SX2MBM_ATA_SSFS: 1,
		SX2MBM_ATA_SERV: 1,
		SX2MBM_CFI: 1,
		SX2MBM_CFI_SERV: 1,
		SX2MBM_EC: "",
		SX2MBM_Royalty: "",
		SX2MBM_Retail: 1,
		SX2MBM_MinFlex: 1,
		SX2MBM_Cogs: 1,
		SX2MBM_EU_Saleable_Units: 1,
		SX2MBM_Non_EU_Saleable_Units: 1,
		SX2MBM_ToBePriced: "",
		SX2MBM_Discount_Group: "",
		SX2MBM_Unit_Price: 1,
		SX2MBM_Unit_Cost: 1,
		SX2MBM_Full_Ejac: "",
		SX2MBM_Allocation: "",
		SX2MBM_Blocked: "",
		SX2MBM_CountryComments: "",
		SX2MBM_HasItemCard: "",
		SX2MBM_ManufacturerCode: 1,
		SX4MBM_NaabCode: "",
		SX4MBM_ATA_STAR: 1,
		SX4MBM_ATA_SSFS: 1,
		SX4MBM_ATA_SERV: 1,
		SX4MBM_CFI: 1,
		SX4MBM_CFI_SERV: 1,
		SX4MBM_EC: "",
		SX4MBM_Royalty: "",
		SX4MBM_Retail: 1,
		SX4MBM_MinFlex: 1,
		SX4MBM_Cogs: 1,
		SX4MBM_EU_Saleable_Units: 1,
		SX4MBM_Non_EU_Saleable_Units: 1,
		SX4MBM_ToBePriced: "",
		SX4MBM_Discount_Group: "",
		SX4MBM_Unit_Price: 1,
		SX4MBM_Unit_Cost: 1,
		SX4MBM_Full_Ejac: "",
		SX4MBM_Allocation: "",
		SX4MBM_Blocked: "",
		SX4MBM_CountryComments: "",
		SX4MBM_HasItemCard: "",
		SX4MBM_ManufacturerCode: 1,
	},
	Prices: {
		ATA_STAR: 1,
		ATA_SSFS: 1,
		ATA_SERV: 1,
		CFI: 1,
		CFI_SERV: 1,
		EC: "",
		EU_Saleable_Units: 1,
		Non_EU_Saleable_Units: 1,
		Unit_Price: 1,
		ToBePriced: "",
		Discount_Group: "",
		Unit_Cost: 1,
		Royalty: "",
		Retail: 1,
		MinFlex: 1,
		Cogs: 1,
		Full_Ejac: "",
		Allocation: "",
		Blocked: "",
		CountryComments: "",
		HasItemCard: "",
		ManufacturerCode: 1,
		Surcharge: 1,
		SX2M_NaabCode: "",
		SX2M_ATA_STAR: 1,
		SX2M_ATA_SSFS: 1,
		SX2M_ATA_SERV: 1,
		SX2M_CFI: 1,
		SX2M_CFI_SERV: 1,
		SX2M_EC: "",
		SX2M_Royalty: "",
		SX2M_Retail: 1,
		SX2M_MinFlex: 1,
		SX2M_Cogs: 1,
		SX2M_EU_Saleable_Units: 1,
		SX2M_Non_EU_Saleable_Units: 1,
		SX2M_ToBePriced: "",
		SX2M_Discount_Group: "",
		SX2M_Unit_Price: 1,
		SX2M_Unit_Cost: 1,
		SX2M_Full_Ejac: "",
		SX2M_Allocation: "",
		SX2M_Blocked: "",
		SX2M_CountryComments: "",
		SX2M_HasItemCard: "",
		SX2M_ManufacturerCode: 1,
		SX2M_Surcharge: 1,
		SX4M_ATA_STAR: 1,
		SX4M_ATA_SSFS: 1,
		SX4M_ATA_SERV: 1,
		SX4M_CFI: 1,
		SX4M_CFI_SERV: 1,
		SX4M_NaabCode: "",
		SX4M_EC: "",
		SX4M_Royalty: "",
		SX4M_Retail: 1,
		SX4M_MinFlex: 1,
		SX4M_Cogs: 1,
		SX4M_EU_Saleable_Units: 1,
		SX4M_Non_EU_Saleable_Units: 1,
		SX4M_Unit_Price: 1,
		SX4M_Unit_Cost: 1,
		SX4M_ToBePriced: "",
		SX4M_Discount_Group: "",
		SX4M_Full_Ejac: "",
		SX4M_Allocation: "",
		SX4M_Blocked: "",
		SX4M_CountryComments: "",
		SX4M_HasItemCard: "",
		SX4M_ManufacturerCode: 1,
		SX4M_Surcharge: 1,
	},
};
