import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, UrlSegment, CanLoad } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

class UserToken {}
@Injectable({ providedIn: "root" })
class Permissions {
	constructor(private auth: AuthService) {}
	async canLoadChildren(): Promise<boolean> {
		if ((await this.auth.isLoggedIn()) === true) {
			return true;
		} else {
			window.location.href = "//ct.wwsires.intranet/login";
		}
	}
}
@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {
	constructor(private permissions: Permissions, private authService: AuthService, private router: Router) {}
	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		let url: string = state.url;
		let activate = this.authService.isLoggedIn();
		return await this.authService.isLoggedIn();
	}
	//  async canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
	async canLoad(): Promise<boolean> {
		// return this.permissions.canLoadChildren()
		return await this.authService.isLoggedIn();
	}
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authService.isLoggedIn();
	}
}
