import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { BullListResolver } from "./bull-list/bull-list-resolver.service";
import { BullListComponent } from "./bull-list/bull-list.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./auth/auth.guard";
import { LoginPageComponent } from "./login/login-page.component";
import { HomeResolver } from "./home/home-resolver.service";

import { DocumentUploadComponent } from "./document-upload/document-upload.component";

const routes: Routes = [
	{
		path: "",
		component: HomeComponent,
		resolve: { user: HomeResolver },
		// canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		runGuardsAndResolvers: "always",
	},
	{
		path: "login",
		component: LoginPageComponent,
		// canLoad: [AuthGuard],
		// canActivate: [AuthGuard],
		runGuardsAndResolvers: "always",
	},
	{
		path: "home",
		component: HomeComponent,
		resolve: { user: HomeResolver },
		// canLoad: [AuthGuard],
		canActivate: [AuthGuard],
		runGuardsAndResolvers: "always",
	},
	{
		path: "bulls",
		component: BullListComponent,
		resolve: { data: BullListResolver },
		canActivate: [AuthGuard],
		// canLoad: [AuthGuard],
		runGuardsAndResolvers: "always",
	},
	{
		path: "document-upload",
		component: DocumentUploadComponent,
		// resolve: { data: BullListResolver },
		canActivate: [AuthGuard],
		// canLoad: [AuthGuard],
		runGuardsAndResolvers: "always",
	}
];
@NgModule({
	imports: [
		RouterModule.forRoot(
			routes, 
			{
    			onSameUrlNavigation: "reload",
    			preloadingStrategy: PreloadAllModules,
    			relativeLinkResolution: "legacy",
			}
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
