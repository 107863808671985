// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
};

export const API_URL = "https://apidev.wwsires.com";
export const CT_URL = "https://ctstaging.wwsires.com";
export const NODE_API_URL = location.hostname === "localhost" ? "http://localhost:3333" : "https://apidev.wwsires.com/v5"

export const StaticAssetsUrlBase = "https://sharedstaticassets.blob.core.windows.net/static-assets"

export const AG_GRID_LIC = "CompanyName=World Wide Sires,LicensedApplication=Bovine Anayltics for Business,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-021995,ExpiryDate=8_November_2022_[v2]_MTY2Nzg2NTYwMDAwMA==97e55bd7f4bed46cc6c4124be37df038";
export const LogRocketLicense = "h0hmjy/bab-staging";
export const ENDPOINT = {
	animalList: "/animal/list",
	login: "/login",
	updateProfile: "/updateprofile",
	validateToken: "/validateToken",
	userSavedLists: "/getUserCustomLists",
	animalDetail: "/animal/detail/", //requires id after detail/{animalId}
	animalProof: "/animal/proof/", //requires id after detail/{animalId}
	mixin: "/animal/listmixin",
	permission: "/authorize/", //requires permission after authorize/{permission}
	products: "/ProductLines",
	productSales: "/Product/SalesData/",
	generateToken: "/generateAccessToken",
	meta: "/metadata/admintools/", // GET retrieves Meta object POST retreives NaabCodeIndex. POST requires metaListName: "NaabCodeIndex" in body
	globalIds: "/animal/identify/xxxx",
	globalId: "/animal/identify/", // requires animal id at the end
	gvc: "/gvc/reports",
	shareList: "/admin/customList/share",
	animalEdit: "/admin/animal/edit",
	documentUpload: "/admin/animaldocuments/upload", //Post request
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
