import { Injectable, ApplicationInitStatus } from "@angular/core";
import { Bull, columnDefiner } from "./bull.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { AlertService } from "../utils/alerts/alert.service";
import { ProgressBarService } from "../utils/loading/progress-bar.service";
import { Router } from "@angular/router";
import { User, UserManagementService } from "../login/user-management.service";
import { BullListView, BullResponse, LactationField } from "../bull-list/bull-list.model";
import { HealthTest, MarketingGroup } from "../bull-list/bull-form/bull-form.model";
import { BullListRecallService } from "../bull-list/bull-list-recall.service";
import { PriceService } from "../price/price.service";
import { Price, IPrice } from "../price/price.model";
import { GVCTemplateResponse } from "./gvc.model";
import { ApplicationMetaObject } from "./meta.model";
import { NgForage } from "ngforage";
import { API_URL, ENDPOINT } from "../../environments/environment";
import { BullListOptionsService } from "../bull-list-options/bull-list-options.service";
import { BullListModule } from "../bull-list/bull-list.module";
import { AppService } from "../app.service";
import { NaabCodeIndexBull } from "../bull-list/bull-form/bull-form-data.service";

export interface MetaObjectStorage {
	metaObj: ApplicationMetaObject;
	timeSet: number;
}

export interface IGlobalIDResponse {
	ShortName?: string;
	GlobalId?: string;
	RegId?: string;
	RegName?: string;
	NaabCodes?: string[];
	Error?: string;
	Identifier?: string;
}
@Injectable({
	providedIn: "root",
})

// Primary data retrieving service
export class BullService {
	apiUrl = API_URL;

	applicationMeta: ApplicationMetaObject;
	metaRightCountries: string[] = [];
	uniqueCountries: string[] = [];

	constructor(
		private priceService: PriceService,
		private http: HttpClient,
		private alert: AlertService,
		private progress: ProgressBarService,

		private recall: BullListRecallService,
		private listOptions: BullListOptionsService,
		private router: Router,
		private ngf: NgForage,
		private appService: AppService
	) {}

	// checks if the user is in storage, if not it retrieves the user from server

	// gets the list of templates from the server
	async getBullListViews() {
		return await this.http.get<{ TemplateViewSource: BullListView[] }>(API_URL + ENDPOINT.animalList).toPromise();
	}
	// used to get a global Id from user input or import. identifiers is what the user is giving as a naab code or reg id. Aaron said he would make the endpoint take an array in future iterations
	async getAnimalGlobalId(identifier: string) {
		let globalIDResponse: any = await this.http.get(API_URL + ENDPOINT.globalId + identifier).toPromise();
		let globalID: any = globalIDResponse.GlobalId;
		// let globalID: any = globalIDResponse;
		if (globalID) {
			return globalID;
		} else {
			return globalIDResponse;
		}
	}

	/**
	 * Fetches an animal's identity object from the Rest API.
	 * @param identifier Used to identify the animal on the API side.
	 * @returns Returns a thin animal object containing only the necessary base data.
	 */
	async getAnimalIdentity(identifier: string) {
		// URI Encode the identifier, sometimes there are characters in RegIds, prevent errors now.
		identifier = encodeURIComponent(identifier);
		let response: any = await this.http.get(API_URL + ENDPOINT.globalId + identifier).toPromise();
		return response;
	}


	/**
	 * Calls the API for an array of animal identifier objects (which are very thin animal objects with just the base data).
	 * The payload will have error entries, "gidErrors: string[]" for mismatched animals.
	 * The "Identifier" field in the payload will always be the raw identifier value provided from the client (from here).
	 * @param identifiers An array of raw animal identifiers.
	 * @returns an object of animal identity objects matching identifiers: NaabCodeIndexBull[] and errors: string[].
	 */
	async getAnimalGlobalIds(identifiers: string[]) {
		let globalIDResponse: IGlobalIDResponse[] = await this.http.post<IGlobalIDResponse[]>(API_URL + ENDPOINT.globalIds, { animalIds: identifiers }).toPromise();
		let globalIDs: NaabCodeIndexBull[] = [];
		let globalIDsErrors: string[] = [];
		globalIDResponse.forEach((gid) => {
			gid.GlobalId ? globalIDs.push(gid) : globalIDsErrors.push(gid.Identifier);
		});
		return { gids: globalIDs, gidErrors: globalIDsErrors };
	}


	// Formats our title case property names to have spaces at the point where a lower case letter meets an upper case letter. Works for everything I have used it on, but may have holes that I am unaware of.
	formatTitleCase(stringToFormat: string) {
		if (stringToFormat.match(/.*EU.*/)) {
			let formattedString = stringToFormat.replace(/([a-z])([A-Z]{1})/g, "$1 $2");
			let withEU = formattedString.replace(/(EU)/g, "$1 ");
			return withEU;
		} else {
			let formattedString = stringToFormat.replace(/([a-z])([A-Z]{1})/g, "$1 $2");
			return formattedString;
		}
	}
	// assigns Traits from Proof categories their traits dynamically
	assignTraitValue(bull, trait, value) {
		bull[`${trait}`] = value;
	}
	// changes rel_ properties to have _rel at the end.
	assignRel(bull, trait, value) {
		bull[trait.replace(/_rel$|^rel_/, "").concat("_rel")] = value;
	}

	// calls for the basic information from the animal list endpoint. This is called as a Base to all mixins
	async retrieveBullList(listName: string, bullsToImport?: string[]): Promise<BullResponse[]> {
		if (bullsToImport) {
			return this.http.post<BullResponse[]>(API_URL + ENDPOINT.animalList, { options: { includeTheseIds: bullsToImport }, listName: listName }).toPromise();
		} else {
			return this.http.post<BullResponse[]>(API_URL + ENDPOINT.animalList, { options: {}, listName: listName }).toPromise();
		}
	}

	// grabs the GVC from the server. baseListName is what determins which GVC you grab from the available GVCs
	async retrieveGVC(base: string) {
		return this.http.post<GVCTemplateResponse>(API_URL + ENDPOINT.gvc, { baseListName: base }).toPromise();
	}

	// add filterToProduct
	// grabs the GVC with baseListName ActiveLineup and defines the options object to retrieve imported bull ids
	async retrieveGVCWithExtraIds(bullsToInclude: string[], base: string, productsToFilter?: string[]) {
		return this.http.post<GVCTemplateResponse>(API_URL + ENDPOINT.gvc, { baseListName: base, options: { includeTheseIds: bullsToInclude, filterToProducts: productsToFilter } }).toPromise();
	}
	// Used to retrieve Trait meta data that is used in providing decimal place formats
	async getTraitMetaData() {
		return this.http.get<ApplicationMetaObject>(API_URL + ENDPOINT.meta).toPromise();
	}
	// Used by the application to ensure the presence of a meta object and updating that object every 24 hours
	async getMetaObject() {
		let traitMetaData: ApplicationMetaObject;
		let metaInStorage: MetaObjectStorage = await this.ngf.getItem("Meta");
		if (metaInStorage) {
			if (metaInStorage && metaInStorage.metaObj) {
				this.applicationMeta = metaInStorage.metaObj;
				if (metaInStorage.timeSet) {
					// meta object has a timeSet property that stores the last time the object was grabbed from the server
					let timeLastSet = metaInStorage.timeSet;
					let timeDifference = Date.now() - timeLastSet;
					// 86400000 = 24hours
					if (timeDifference > 86400000) {
						traitMetaData = await this.getTraitMetaData();
						this.applicationMeta = traitMetaData;
						await this.ngf.setItem("Meta", { metaObj: this.applicationMeta, timeSet: Date.now() });
					} else {
						this.applicationMeta = metaInStorage.metaObj;
					}
				} else {
					traitMetaData = await this.getTraitMetaData();
					this.applicationMeta = traitMetaData;
					await this.ngf.setItem("Meta", { metaObj: this.applicationMeta, timeSet: Date.now() });
				}
			} else {
				traitMetaData = await this.getTraitMetaData();
				this.applicationMeta = traitMetaData;
				await this.ngf.setItem("Meta", { metaObj: this.applicationMeta, timeSet: Date.now() });
			}
		} else {
			traitMetaData = await this.getTraitMetaData();
			this.applicationMeta = traitMetaData;
			await this.ngf.setItem("Meta", { metaObj: this.applicationMeta, timeSet: Date.now() });
		}

		// Parsing unique countries for marketing rights dropdown in the List Builder, then sorting by Country.
		this.uniqueCountries = this.applicationMeta.MarketingRightCountry.map(mk => {
			return mk.Country;
		} );
		this.uniqueCountries.sort();
	}

	// Retrieves mixins for custom lists. listName is HomeGrownList, can probably be hard coded, tells the server that the list should only include the ids provided in includeTheseIds.
	async retreiveHomeGrownMixin(listName: string, mixin: string, bullsToInclude: string[]) {
		if (mixin === "BeefMale") {
			return null;
		} else {
			return this.http.post<BullResponse[]>(API_URL + ENDPOINT.mixin, { mixinName: mixin, listName: listName, options: { includeTheseIds: bullsToInclude } }).toPromise();
		}
	}
	// retrieves mixin data for templates. There is no options field defined on this function. Might be able to combine these 2 if the

	async retreiveMixinData(listName: string, mixin: string, bullsToInclude?: string[]) {

		// console.log("List Name: ", listName, " Mixin: ", mixin, " Bulls to Include: ", bullsToInclude);

		if (mixin === "BeefMale") {
			return null;
		} else {
			return this.http.post<BullResponse[]>(API_URL + ENDPOINT.mixin, { mixinName: mixin, listName: listName, options: { includeTheseIds: bullsToInclude } }).toPromise();
		}
	}

	lowestDisplayProductLine(prices: IPrice[]) {
		let sortedPrice = prices.sort((a, b) => {
			return a.DisplayOrder - b.DisplayOrder;
		});

		return sortedPrice[0];
	}


	/**
	 * Represents a runtime constant to be mapped to field names and header labels in ApplyProductBase()
	 * Key is the field name in the API payload, value is the bull's field name and subsequent AG Grid header label.
	 */
	ProductBaseLabelMap = {
		LegacyECStatus: "EC",
		EUSaleableUnits: "EU_Saleable_Units",
		NonEUSaleableUnits: "Non_EU_Saleable_Units",
		UnitPrice: "Unit_Price",
		DiscountGroup: "Discount_Group",
		IsFullBatchOnly: "Full_Ejac",
		IsAllocated: "Allocation",
		UnitCost: "Unit_Cost",
		IsBlocked: "Blocked"
	};

	/**
	 * Another runtime constant to prevent repeated magic strings.
	 * Used in the setProductValues() method below.
	 */
	SexedProductLabelMap = {
		sex: "SX2M_",
		sex4m: "SX4M_",
		sexMale: "SX2MBM_",
		sexMale4m: "SX4MBM_"
	};


	/**
	 * Assigns the base product fields to a bull (ag grid row) object.
	 * @param bull the bull object
	 * @param prod the product containing a Fertility array of objects.
	 * @param sexedPrefix A string representing the header label, either "SX2M_" or "SX4M_".
	 */
	private ApplyProductBase(bull: Bull, prod?: IPrice, sexedPrefix: string = ""): void {
		const fieldLabelMap = this.ProductBaseLabelMap;
		Object.entries(prod).forEach(entry => {
			const key = entry[0],
				value = (typeof entry[1] == "boolean" && entry[1] ? "Yes" : entry[1]);
			let headerLabel = fieldLabelMap.hasOwnProperty(key) ? sexedPrefix.concat(fieldLabelMap[key]) : sexedPrefix.concat(key);
			bull[headerLabel] = value || null;
		});
	}


	/**
	 * Assigns the various product fertility fields to the bull object.
	 * @param bull the bull object
	 * @param prod the product containing a Fertility array of objects.
	 * @param sexedPrefix A string representing the header label, either "SX2M_" or "SX4M_" and the sexed male varieties.
	 */
	private ApplyFertilityToBull(bull: Bull, prod: IPrice, sexedPrefix: string = ""): void {
		// If there's no Fertility object in the prod object, we're done.
		if (!prod.hasOwnProperty("Fertility") || prod.Fertility.length === 0)
			return;

		// Now, finally, assign those fields a value.
		prod.Fertility.forEach( fert => {
			// This is where we flatten the fertility data into the bull object.
			const headerLabel: string = sexedPrefix.concat(fert.LegacyName).toUpperCase();
			bull[headerLabel] = fert.Value;
		} );
		delete prod.Fertility;
	}


	/**
	 * Method underwent major refactoring.
	 * @param bull The bull object, duh.
	 * @param conv A conventional product object.
	 * @param sex A 2M product object.
	 * @param sex4m A 4M product object.
	 * @param sexMale A beef 2M sexed male product object.
	 * @param sexMale4m A beef 4M sexed male product object.
	 */
	setProductValues(bull: Bull, conv?: IPrice, sex?: IPrice, sex4m?: IPrice, sexMale?: IPrice, sexMale4m?: IPrice): void {
		if (this.listOptions.productPerRow) {
			this.ApplyProductPerRowValues(bull, conv, sex, sex4m, sexMale, sexMale4m);
			return;
		}

		// No processing product/row, proceed assigning the conventional and sexed header labels and values to the "bull" object.
		if (conv) {
			this.ApplyFertilityToBull(bull, conv);
			this.ApplyProductBase(bull, conv);
		}
		if (sex) {
			this.ApplyFertilityToBull(bull, sex, this.SexedProductLabelMap["sex"]);
			this.ApplyProductBase(bull, sex, this.SexedProductLabelMap["sex"]);
		}
		if (sex4m) {
			this.ApplyFertilityToBull(bull, sex4m, this.SexedProductLabelMap["sexMale"]);
			this.ApplyProductBase(bull, sex4m, this.SexedProductLabelMap["sex4m"]);
		}
		if (sexMale) {
			this.ApplyFertilityToBull(bull, sexMale, this.SexedProductLabelMap["sexMale"]);
			this.ApplyProductBase(bull, sexMale, this.SexedProductLabelMap["sexMale"]);
		}
		if (sexMale4m) {
			this.ApplyFertilityToBull(bull, sexMale4m, this.SexedProductLabelMap["sexMale4m"]);
			this.ApplyProductBase(bull, sexMale4m, this.SexedProductLabelMap["sexMale4m"]);
		}
	}


	/**
	 * Refactored out of setProductValues(), handles assigning product/row using the same objects.
	 */
	private ApplyProductPerRowValues(bull: Bull, conv?: IPrice, sex?: IPrice, sex4m?: IPrice, sexMale?: IPrice, sexMale4m?: IPrice): void {
		let productCounter = 0;
		let productsArray = [];
		conv ? productsArray.push(conv) : null;
		sex ? productsArray.push(sex) : null;
		sex4m ? productsArray.push(sex4m) : null;
		sexMale ? productsArray.push(sexMale) : null;
		sexMale4m ? productsArray.push(sexMale4m) : null;
		productsArray.forEach((prod: IPrice) => {
			// When hiding addditional product data, either DiscountGroup or FullBatch must be present.
			if (!this.listOptions.showAdditionalProductData && !prod.DiscountGroup && !prod.IsFullBatchOnly)
				return;

			if (productCounter > 0) {
				// Append a copied "bull" object to the currentBullsList, we do not bother applying 2M/4M header labels here.
				let newBull = this.CreateCleanBaseRow(bull);
				this.ApplyFertilityToBull(newBull, prod);
				this.ApplyProductBase(newBull, prod);
				newBull.PrimaryNaabCode = prod.NaabCode;
				this.recall.currentBullsList.push(newBull);
			}
			else {
				// This will be the 1st (and possibly only product/row record) and will be the product assigned the Primary Naab Code.
				this.ApplyFertilityToBull(bull, prod);
				this.ApplyProductBase(bull, prod);
			}
			productCounter++;
		});
	}


	/**
	 * Returns a copy of the previous bull for assignment in Product/Row view.
	 * It nullifies all price related fields as to notfalsely display values that many look repeated in the sexed row.
	 * @param bull The bull object to be copied.
	 */
	private CreateCleanBaseRow(bull: Bull): Bull {
		// This deep copy will contain all product line data from the prior product.
		let newBull = Object.assign({}, bull);

		// Now that we've copied everything, we'll have to rebase the price related fields in the new bull object.
		Object.keys(columnDefiner.Prices).forEach(field => {
			newBull[field] = null;
		});
		return newBull;
	}


	setBullPriceValues(bull: Bull, conv?: IPrice, sex?: IPrice, sex4m?: IPrice, sexMale?: IPrice, sexMale4m?: IPrice): void {
		if (!conv || (conv && !conv.HasValidItemCard)) {
			if (sex) {
				if (sex.DisplayOrder === 0) {
					bull.NaabCode = sex.NaabCode;
				}
			}
			if (sex4m) {
				if (sex4m.DisplayOrder === 0) {
					bull.NaabCode = sex4m.NaabCode;
				}
			}
		}
		this.setProductValues(bull, conv, sex, sex4m, sexMale, sexMale4m);
	}

	private async createBaseBullObj(listName: string, bullsToImport?: string[], homeGrown?: boolean) {
		let YoungSireData,
			recessivesData,
			sireStack;
		const baseTemplate: BullListView = await this.appService.GetSystemTemplate(listName);
		try {
			if (homeGrown) {
				YoungSireData = await this.retreiveHomeGrownMixin(listName, "YoungSire", bullsToImport);
				recessivesData = await this.retreiveHomeGrownMixin(listName, "Recessives", bullsToImport);
				sireStack = await this.retreiveHomeGrownMixin(listName, "SireStack", bullsToImport);
			} else if (bullsToImport) {
				YoungSireData = await this.retreiveMixinData(listName, "YoungSire", bullsToImport);
				recessivesData = await this.retreiveMixinData(listName, "Recessives", bullsToImport);
				sireStack = await this.retreiveMixinData(listName, "SireStack", bullsToImport);
			}

			// Modified to exclude GVC else throw an error.
			else if (!baseTemplate.IsGvc) {
				YoungSireData = await this.retreiveMixinData(listName, "YoungSire");
				recessivesData = await this.retreiveMixinData(listName, "Recessives");
				sireStack = await this.retreiveMixinData(listName, "SireStack");
			}

			let bullListResponse: BullResponse[] = [];
			if (bullsToImport) {
				bullListResponse = await this.retrieveBullList(listName, bullsToImport);
			}

			// Modified to exclude GVC else throw an error.
			else if (!baseTemplate.IsGvc)
				bullListResponse = await this.retrieveBullList(listName);

			let bullList: Bull[] = bullListResponse.map((resp) => {
				for (let bull of YoungSireData.filter((ys) => {
					return ys.RegId === resp.RegId;
				})) {
					switch (bull.YoungSire) {
						case "Genomic":
							resp.YoungSire = "G";
							break;
						case "Proven":
							resp.YoungSire = "";
							break;
						case "NotTested":
							resp.YoungSire = "NT";
							break;
					}
					resp.ReleaseDate = bull.ReleaseDate;
				}
				for (let bullRecessive of recessivesData.filter((rc) => {
					return rc.RegId === resp.RegId;
				})) {
					resp.Recessives = bullRecessive.Recessives;
				}
				for (let bullStack of sireStack.filter((ss) => {
					return ss.RegId === resp.RegId;
				})) {
					resp.SireStack = bullStack.SireStack;
				}
				let newBull: Bull = new Bull(resp);
				return newBull;
			});
			this.recall.currentBullsList = bullList;
		} catch (error) {
			this.alert.alerts.next({ message: `Something Went Wrong Fetching Basic Information: ${error.message}` });
			this.progress.loading.next({ load: false, source: "create base bull object end" });
			this.router.navigateByUrl("home");
		}
	}
	async getPrices() {
		const naabCodes = this.recall.currentBullsList.map((bull) => bull.PrimaryNaabCode);
		let prices: Price[] = await this.priceService.getPrices({ bullIds: naabCodes });
		return prices;
	}


	/**
	 * Marked for Deprecation, it doesn't seem to be used anywhere.
	 */
	async addColumnsToBullList(mixins: string[], listName: string, bullsToImport?: string[], addingColumns?: boolean, productPerRow: boolean = false) {
		await this.createBullList(mixins, listName, bullsToImport, addingColumns);
	}

	/**
	 * Marked for Deprecation, it doesn't seem to be used anywhere.
	 */
	async loadBullList(mixins: string[], listName: string, bullsToImport?: string[], addingColumns?: boolean) {
		await this.createBullList(mixins, listName, bullsToImport, addingColumns);
	}

	async homeGrownList(mixins: string[], bullsToImport?: string[], addingColumns?: boolean) {
		await this.createBullList(mixins, "HomeGrownList", bullsToImport, addingColumns);
	}


	/**
	 * Asynchronously creates a list of bulls with specified mixins and additional data.
	 * This method flattens the bull object for the grid.
	 * @param mixins - An array of mixins to apply to the bull list.
	 * @param listName - The name of the list being created.
	 * @param bullsToImport - Optional array of bull IDs to import.
	 * @param addingColumns - Flag indicating whether columns are being added.
	 * @returns A Promise that resolves once the bull list is created.
	 */
	async createBullList(mixins: string[], listName: string, bullsToImport?: string[], addingColumns: boolean = false) {
		if (!addingColumns) {
			if (bullsToImport && bullsToImport.length > 0) {
				if (listName === "HomeGrownList") {
					await this.createBaseBullObj(listName, bullsToImport, true);
				} else {
					await this.createBaseBullObj(listName, bullsToImport);
				}
			} else {
				await this.createBaseBullObj(listName);
			}
		}
		if (this.listOptions.productPerRow && !this.recall.currentMixins.includes("Prices")) {
			this.recall.currentDisplayMixins.push("Prices");
			this.recall.currentMixins.push("Prices");
		}
		for (let mix of mixins) {
			if (mix === "Base") {
				continue;
			}

			if (mix === "Prices") {
				await this.ApplyPricesMixin();
				continue;
			}

			try {
				await this.retreiveMixinData(listName, mix, bullsToImport).then((bullLists: BullResponse[]) => {

					if (mix === "Lactation") {
						this.ApplyLactationMixin(bullLists);
					}

					if (mix === "Meta_DEU") {
						this.ApplyMetaDEUMixin(bullLists);
					}
					if (mix === "Meta_AUS") {
						this.ApplyMetaAUSMixin(bullLists);
					}
					if (mix === "Meta") {
						this.ApplyMetaUSAMixin(bullLists);
					}
					if (mix === "Genotypes") {
						this.ApplyGenotypeMixin(bullLists);
					}
					if (mix === "MarketingRight") {
						this.ApplyMRMixin(bullLists);
					}

					if (mix === "MarketingGroup") {
						this.ApplyMarketingGroupMixin(bullLists);
					}
					if (mix === "Location") {
						this.ApplyLocationMixin(bullLists);
					}

					if (mix === "Lineage") {
						this.ApplyLineageMixin(bullLists);
					}
					if (mix === "Designation") {
						this.ApplyDesignationMixin(bullLists);
					}
					if (mix === "Index") {
						this.ApplyIndexMixin(bullLists);
					}
					if (mix === "HealthTest") {
						this.ApplyHealthTestMixin(bullLists);
					}

					if (
						mix === "Production" ||
						mix === "Management" ||
						mix === "Type" ||
						mix === "Daughter Fertility" ||
						mix === "Indexes" ||
						mix === "Wellness Traits" ||
						mix === "Sire Fertility" ||
						mix === "Inbreeding" ||
						mix === "Total Breeding Values_DEU" ||
						mix === "Fertility_DEU" ||
						mix === "Health_DEU" ||
						mix === "Production_DEU" ||
						mix === "Type_DEU" ||
						mix === "Braunvieh_DEU"
					) {
						for (let responseBull of bullLists) {
							let obj;
							let bull = this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)[0];
							if (bull) {
								// let category = responseBull.Proof.Categories.filter((cat) => cat.Name === mix)[0];
								let category = responseBull.Proof.Categories[0] || responseBull.Meta;
								if (category) {
									for (let trait of category.Traits) {
										switch (mix) {
											case "Production":
												obj = columnDefiner.Production;
												if (category.Source) {
													bull.pkey = category.Source.Abbreviation;
													bull.source = category.Source.Label;
												}

												break;
											case "Management":
												obj = columnDefiner.Management;
												break;
											case "Type":
												obj = columnDefiner.Type;
												if (category.Source) {
													bull.tkey = category.Source.Abbreviation;
													bull.tsource = category.Source.Label;
												}
												break;
											case "Daughter Fertility":
												obj = columnDefiner.DaughterFertility;
												break;
											case "Indexes":
												obj = columnDefiner.Indexes;
												break;
											case "Wellness Traits":
												obj = columnDefiner.WellnessTraits;
												break;
											case "Sire Fertility":
												obj = columnDefiner.SireFertility;
												break;
											case "Inbreeding":
												obj = columnDefiner.Inbreeding;
												break;
											case "Total Breeding Values_DEU":
												obj = columnDefiner.TotalBreedingValues_DEU;
												if (category.Source) {
													bull.q_rzg = category.Source.Abbreviation;
													bull.q_rzeuro = category.Source.Abbreviation;
													bull.q_rzn = category.Source.Abbreviation;
													bull.q_rzkm = category.Source.Abbreviation;
												}

												break;
											case "Fertility_DEU":
												obj = columnDefiner.Fertility_DEU;
												if (category.Source) {
													bull.q_rzr = category.Source.Abbreviation;
													bull.q_rzkd = category.Source.Abbreviation;
													bull.q_rzkm_2 = category.Source.Abbreviation;
												}
												break;
											case "Health_DEU":
												obj = columnDefiner.Health_DEU;
												if (category.Source) {
													bull.q_rzeuterfit = category.Source.Abbreviation;
													bull.q_ddcontrol = category.Source.Abbreviation;
													bull.q_rzklauen = category.Source.Abbreviation;
													bull.q_rzmetabol = category.Source.Abbreviation;
													bull.q_rzrepro = category.Source.Abbreviation;
													bull.q_rzgesund = category.Source.Abbreviation;
													bull.q_rzkaelberfit = category.Source.Abbreviation;
												}
												break;
											case "Production_DEU":
												obj = columnDefiner.Production_DEU;
												if (category.Source) {
													bull.q_rzm = category.Source.Abbreviation;
													bull.q_rzs = category.Source.Abbreviation;
													bull.q_rzd = category.Source.Abbreviation;
												}

												break;
											case "Type_DEU":
												obj = columnDefiner.Type_DEU;
												if (category.Source) {
													bull.q_rze = category.Source.Abbreviation;
												}
												break;
											case "Braunvieh_DEU":
												obj = columnDefiner.Braunvieh_DEU;
										}

										if (trait.LegacyName.match(/_rel$|^rel_/)) {
											this.assignRel(bull, trait.LegacyName, trait.Value);
										}
										if (Object.keys(obj).includes(trait.LegacyName)) {
											this.assignTraitValue(bull, trait.LegacyName, trait.Value);
										}
									}
								}
							}
						}
					}

					if (
						mix === "Production" ||
						mix === "Management" ||
						mix === "Type" ||
						mix === "Daughter Fertility" ||
						mix === "Indexes" ||
						mix === "Wellness Traits" ||
						mix === "Sire Fertility" ||
						mix === "Inbreeding" ||
						mix === "Indexes_AUS" ||
						mix === "Production_AUS" ||
						mix === "Type_AUS" ||
						mix === "Management_AUS" ||
						mix === "Calving_AUS"
					) {
						for (let responseBull of bullLists) {
							let obj;
							let bull = this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)[0];
							if (bull) {
								// let category = responseBull.Proof.Categories.filter((cat) => cat.Name === mix)[0];
								let category = responseBull.Proof.Categories[0] || responseBull.Meta;
								if (category) {
									for (let trait of category.Traits) {
										switch (mix) {
											case "Production":
												obj = columnDefiner.Production;
												if (category.Source) {
													bull.pkey = category.Source.Abbreviation;
													bull.source = category.Source.Label;
												}

												break;
											case "Management":
												obj = columnDefiner.Management;
												break;
											case "Type":
												obj = columnDefiner.Type;
												if (category.Source) {
													bull.tkey = category.Source.Abbreviation;
													bull.tsource = category.Source.Label;
												}

												break;
											case "Daughter Fertility":
												obj = columnDefiner.DaughterFertility;
												break;
											case "Indexes":
												obj = columnDefiner.Indexes;
												break;
											case "Wellness Traits":
												obj = columnDefiner.WellnessTraits;
												break;
											case "Sire Fertility":
												obj = columnDefiner.SireFertility;
												break;
											case "Inbreeding":
												obj = columnDefiner.Inbreeding;
												break;
											case "Indexes_AUS":
												obj = columnDefiner.Indexes_AUS;
												if (category.Source) {
													bull.bpi = category.Source.Abbreviation;
													bull.bpi_rel = category.Source.Abbreviation;
													bull.asi = category.Source.Abbreviation;
													bull.hwi_aus = category.Source.Abbreviation;
													bull.hwi_aus_rel = category.Source.Abbreviation;
													bull.si = category.Source.Abbreviation;
													bull.si_rel = category.Source.Abbreviation;
												}
												break;
											case "Production_AUS":
												obj = columnDefiner.Production_AUS;
												if (category.Source) {
													bull.prot_bv = category.Source.Abbreviation;
													bull.protp_bv = category.Source.Abbreviation;
													bull.milk_bv = category.Source.Abbreviation;
													bull.fat_bv = category.Source.Abbreviation;
													bull.fatp_bv = category.Source.Abbreviation;
													bull.prot_rel = category.Source.Abbreviation;
													bull.dtrs_no_obs = category.Source.Abbreviation;
													bull.dtrs_no_herds = category.Source.Abbreviation;
													bull.dtrs_no_h1 = category.Source.Abbreviation;
													bull.dtrs_no_h2 = category.Source.Abbreviation;
													bull.dtrs_rip = category.Source.Abbreviation;
												}
												break;
											case "Management_AUS":
												obj = columnDefiner.Management_AUS;
												if (category.Source) {
													bull.mspeed_bv = category.Source.Abbreviation;
													bull.temp_bv = category.Source.Abbreviation;
													bull.like_bv = category.Source.Abbreviation;
													bull.mspeed_rel = category.Source.Abbreviation;
													bull.wgen_no_obs = category.Source.Abbreviation;
													bull.wgen_no_herds = category.Source.Abbreviation;
													bull.surv_bv = category.Source.Abbreviation;
													bull.surv_rel = category.Source.Abbreviation;
													bull.scc_bv = category.Source.Abbreviation;
													bull.scc_rel = category.Source.Abbreviation;
													bull.scc_no_obs = category.Source.Abbreviation;
													bull.scc_no_herds = category.Source.Abbreviation;
													bull.fert_bv = category.Source.Abbreviation;
													bull.fert_rel = category.Source.Abbreviation;
													bull.fert_no_obs = category.Source.Abbreviation;
													bull.fert_no_herds = category.Source.Abbreviation;
													bull.lwt_bv = category.Source.Abbreviation;
													bull.lwt_rel = category.Source.Abbreviation;
													bull.has_gen = category.Source.Abbreviation;
													bull.ressur_bv = category.Source.Abbreviation;
													bull.ressur_rel = category.Source.Abbreviation;
													bull.feedef_bv = category.Source.Abbreviation;
													bull.feedef_rel = category.Source.Abbreviation;
													bull.heat_t_bv = category.Source.Abbreviation;
													bull.heat_t_rel = category.Source.Abbreviation;
													bull.heat_no_obs = category.Source.Abbreviation;
													bull.heat_no_herds = category.Source.Abbreviation;
													bull.mas_bv = category.Source.Abbreviation;
													bull.mas_rel = category.Source.Abbreviation;
													bull.maternal_ease_no_obs = category.Source.Abbreviation;
													bull.maternal_ease_no_herds = category.Source.Abbreviation;
												}
												break;
											case "Type_AUS":
												obj = columnDefiner.Type_AUS;
												if (category.Source) {
													bull.otype_bv = category.Source.Abbreviation;
													bull.mamm_bv = category.Source.Abbreviation;
													bull.stat_bv = category.Source.Abbreviation;
													bull.udtex_bv = category.Source.Abbreviation;
													bull.bone_bv = category.Source.Abbreviation;
													bull.angul_bv = category.Source.Abbreviation;
													bull.muzw_bv = category.Source.Abbreviation;
													bull.bodyl_bv = category.Source.Abbreviation;
													bull.bodyd_bv = category.Source.Abbreviation;
													bull.loin_bv = category.Source.Abbreviation;
													bull.chestw_bv = category.Source.Abbreviation;
													bull.rumpl_bv = category.Source.Abbreviation;
													bull.pinw_bv = category.Source.Abbreviation;
													bull.pinset_bv = category.Source.Abbreviation;
													bull.foota_bv = category.Source.Abbreviation;
													bull.heel_depth = category.Source.Abbreviation;
													bull.rset_bv = category.Source.Abbreviation;
													bull.rleg_bv = category.Source.Abbreviation;
													bull.uddep_bv = category.Source.Abbreviation;
													bull.forea_bv = category.Source.Abbreviation;
													bull.rear_ah_bv = category.Source.Abbreviation;
													bull.rear_aw_bv = category.Source.Abbreviation;
													bull.cent_l_bv = category.Source.Abbreviation;
													bull.teat_pf_bv = category.Source.Abbreviation;
													bull.teat_pr_bv = category.Source.Abbreviation;
													bull.teat_l_bv = category.Source.Abbreviation;
													bull.condition_score = category.Source.Abbreviation;
													bull.tgen_rel = category.Source.Abbreviation;
													bull.tgen_no_obs = category.Source.Abbreviation;
													bull.tgen_no_herds = category.Source.Abbreviation;
													bull.rump_bv = category.Source.Abbreviation;
													bull.dairy_s_bv = category.Source.Abbreviation;
												}
												break;
											case "Calving_AUS":
												obj = columnDefiner.Calving_AUS;
												if (category.Source) {
													bull.ease_bv = category.Source.Abbreviation;
													bull.ease_rel = category.Source.Abbreviation;
													bull.ease_no_obs = category.Source.Abbreviation;
													bull.ease_no_herds = category.Source.Abbreviation;
													bull.gestation_l_bv = category.Source.Abbreviation;
													bull.gestation_l_rel = category.Source.Abbreviation;
													bull.gestation_ease_no_obs = category.Source.Abbreviation;
													bull.gestation_ease_no_herds = category.Source.Abbreviation;
												}
												break;
										}

										if (trait.LegacyName.match(/_rel$|^rel_/)) {
											this.assignRel(bull, trait.LegacyName, trait.Value);
										}
										if (Object.keys(obj).includes(trait.LegacyName)) {
											this.assignTraitValue(bull, trait.LegacyName, trait.Value);
										}
									}
								}
							}
						}
					}

					if (
						mix === "Production" ||
						mix === "Management" ||
						mix === "Type" ||
						mix === "Daughter Fertility" ||
						mix === "Indexes" ||
						mix === "Wellness Traits" ||
						mix === "Sire Fertility" ||
						mix === "Inbreeding" ||
						mix === "CDDR_Production_GBR" ||
						mix === "CDDR_Management_GBR" ||
						mix === "CDDR_Type_GBR" ||
						mix === "CDDR_Index_GBR" ||
						mix === "CDDR_IntSCI_GBR" ||
						mix === "CDDR_Health_GBR" ||
						mix === "Health_GBR" ||
						mix === "Production_GBR" ||
						mix === "Type_GBR" ||
						mix === "Management_GBR" ||
						mix === "Indexes_GBR"
					) {
						for (let responseBull of bullLists) {
							let obj = {};
							let bull = this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)[0];
							if (bull) {

								let category = responseBull.Proof.Categories[0] || responseBull.Meta;
								if (category) {
									for (let trait of category.Traits) {
										switch (mix) {
											case "Production":
												obj = columnDefiner.Production;
												if (category.Source) {
													bull.pkey = category.Source.Abbreviation;
													bull.source = category.Source.Label;
												}
												break;
											case "Management":
												obj = columnDefiner.Management;
												break;
											case "Type":
												obj = columnDefiner.Type;
												if (category.Source) {
													bull.tkey = category.Source.Abbreviation;
													bull.tsource = category.Source.Label;
												}
												break;
											case "Daughter Fertility":
												obj = columnDefiner.DaughterFertility;
												break;
											case "Indexes":
												obj = columnDefiner.Indexes;
												break;
											case "Wellness Traits":
												obj = columnDefiner.WellnessTraits;
												break;
											case "Sire Fertility":
												obj = columnDefiner.SireFertility;
												break;
											case "Inbreeding":
												obj = columnDefiner.Inbreeding;
												break;
											case "CDDR_Production_GBR":
												obj = columnDefiner.CDDR_Production_GBR;
												break;
											case "CDDR_Management_GBR":
												obj = columnDefiner.CDDR_Management_GBR;
												break;
											case "CDDR_Type_GBR":
												obj = columnDefiner.CDDR_Type_GBR;
												break;
											case "CDDR_Index_GBR":
												obj = columnDefiner.CDDR_Index_GBR;
												break;
											case "CDDR_IntSCI_GBR":
												obj = columnDefiner.CDDR_IntSCI_GBR;
												break;
											case "CDDR_Health_GBR":
												obj = columnDefiner.CDDR_Health_GBR;
												break;
											case "Health_GBR":
												obj = columnDefiner.Health_GBR;
												break;
											case "Production_GBR":
												obj = columnDefiner.Production_GBR;
												break;
											case "Type_GBR":
												obj = columnDefiner.Type_GBR;
												break;
											case "Management_GBR":
												obj = columnDefiner.Management_GBR;
												break;
											case "Indexes_GBR":
												obj = columnDefiner.Indexes_GBR;
												break;
										}

										if (trait.LegacyName.match(/_rel$|^rel_/)) {
											this.assignRel(bull, trait.LegacyName, trait.Value);
										}
										if (Object.keys(obj).includes(trait.LegacyName)) {
											this.assignTraitValue(bull, trait.LegacyName, trait.Value);
										}
									}
								}
							}
						}
					}
				});
			} catch (error) {
				this.alert.alerts.next({ message: `Something Went Wrong Contact IT: ${error.message}` });
				this.progress.loading.next({ load: false, source: "regular list mixin fetching end" });
			}
		}
	}


	/**
	 * Applies health test data to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing health test information.
	 */
	private ApplyHealthTestMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					let displayedHT: HealthTest[] = [];
					for (let ht of responseBull.HealthTests) {
						let displayedHTNames = displayedHT.map((disp) => disp.LegacyName);
						if (displayedHTNames.includes(ht.LegacyName)) {
							let dup = displayedHT.filter((health: HealthTest) => {
								return health.LegacyName === ht.LegacyName;
							})[0];

							let htDate = Date.parse(ht.TestDate);
							let dupTestDate = Date.parse(dup.TestDate);
							if (htDate !== dupTestDate) {
							}
							if (htDate > dupTestDate) {
								displayedHT.splice(displayedHT.indexOf(dup), 1);
								displayedHT.push(ht);
							}
						} else {
							displayedHT.push(ht);
						}
					}

					for (let healthTest of displayedHT) {
						switch (healthTest.LegacyName) {
							case "EHD":
								bull.EHD = healthTest.TestResult;
								break;
							case "IBR":
								bull.IBR = healthTest.TestResult;
								break;
							case "JOHNES":
								bull.Johnes = healthTest.TestResult;
								break;
							case "JOHNES-FECAL":
								bull.JohnesFecal = healthTest.TestResult;
								break;
							case "LEU":
								bull.LEU = healthTest.TestResult;
								break;
							case "BT":
								bull.BT = healthTest.TestResult;
								break;
							case "UDT":
								bull.UDT = healthTest.TestResult;
								break;
						}
					}
				}
			}
		}
	}

	/**
	 * Applies index and marketing campaign data to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing index and marketing campaign information.
	 */
	private ApplyIndexMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					for (let mc of responseBull.MarketingCampaigns) {
						bull[mc.Name] = "Yes";
					}
					for (let mktcampaign of responseBull.MarketingCampaigns.map((mkt) => mkt.Name)) {
						if (!responseBull.MarketingCampaigns.map((mkt) => mkt.Name).includes(mktcampaign)) {
							bull[`${mktcampaign}`] = "No";
						}
					}
				}
			}
		}
	}


	/**
	 * Applies designation and marketing campaign data to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing designation and marketing campaign information.
	 */
	private ApplyDesignationMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					for (let mc of responseBull.MarketingCampaigns) {
						if (mc.Name === "NxGen") {
							bull.NxGen = "Yes";
							bull.Active = "NXGen";
							bull.ActiveDate = mc.ActivateDate;
							break;
						}
						bull[mc.Name] = "Yes";
					}
					for (let mktcampaign of responseBull.MarketingCampaigns.map((mkt) => mkt.Name)) {
						if (!responseBull.MarketingCampaigns.map((mkt) => mkt.Name).includes(mktcampaign)) {
							bull[`${mktcampaign}`] = "No";
						}
					}
				}
			}
		}
	}


	/**
	 * Applies lineage information to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing lineage information.
	 */
	private ApplyLineageMixin(bullLists: BullResponse[]) {
		let relationships = [];
		for (let responseBull of bullLists) {
			let bull = this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)[0];
			if (bull) {
				for (let line of responseBull.Lineage) {
					relationships.push(line.Relationship);
					switch (line.Relationship) {
						case "Dam":
							bull.DamRegName = line.RegName;
							bull.DamRegId = line.RegId;
							break;
						case "MGDam":
							bull.MGDamRegName = line.RegName;
							bull.MGDamRegId = line.RegId;
							break;
						case "MPGDam":
							bull.MPGDamRegName = line.RegName;
							bull.MPGDamRegId = line.RegId;
							break;
						case "MMGDam":
							bull.MMGDamRegName = line.RegName;
							bull.MMGDamRegId = line.RegId;
							break;
						case "Sire":
							bull.SireRegName = line.RegName;
							bull.SireNaabCode = line.PrimaryNaabCode;
							bull.SireShortName = line.ShortName;
							bull.SireRegId = line.RegId;
							break;
						case "PGSire":
							bull.PGSireRegName = line.RegName;
							bull.PGSireRegId = line.RegId;
							bull.PGSireShortName = line.ShortName;
							bull.PGSireNaabCode = line.PrimaryNaabCode;
							break;
						case "PMGSire":
							bull.PMGSireRegName = line.RegName;
							bull.PMGSireRegId = line.RegId;
							bull.PMGSireShortName = line.ShortName;
							bull.PMGSireNaabCode = line.PrimaryNaabCode;
							break;
						case "PPGSire":
							bull.PPGSireRegName = line.RegName;
							bull.PPGSireRegId = line.RegId;
							bull.PPGSireShortName = line.ShortName;
							bull.PPGSireNaabCode = line.PrimaryNaabCode;
							break;
						case "PPGDam":
							bull.PPGDamRegName = line.RegName;
							bull.PPGDamRegId = line.RegId;

							break;
						case "PMGDam":
							bull.PMGDamRegName = line.RegName;
							bull.PMGDamRegId = line.RegId;

							break;
						case "PGDam":
							bull.PGDamRegName = line.RegName;
							bull.PGDamRegId = line.RegId;
							break;
						case "MGSire":
							bull.MGSireRegName = line.RegName;
							bull.MGSireNaabCode = line.PrimaryNaabCode;
							bull.MGSireShortName = line.ShortName;
							bull.MGSireRegId = line.RegId;
							break;
						case "MPGSire":
							bull.MPGSireRegName = line.RegName;
							bull.MPGSireNaabCode = line.PrimaryNaabCode;
							bull.MPGSireShortName = line.ShortName;
							bull.MPGSireRegId = line.RegId;
							break;
						case "MMGSire":
							bull.MMGSireRegName = line.RegName;
							bull.MMGSireNaabCode = line.PrimaryNaabCode;
							bull.MMGSireShortName = line.ShortName;
							bull.MMGSireRegId = line.RegId;
							break;
					}
				}
			}
		}
	}

	/**
	 * Applies location and production status data to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing location and production status information.
	 */
	private ApplyLocationMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			let bull = this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)[0];
			if (bull) {
				bull.MoveDate = responseBull.ProductionStatus.MoveDate;
				bull.IsProduction = responseBull.ProductionStatus.IsProduction ? "Yes" : null;
				bull.IsEU = responseBull.ProductionStatus.IsEU ? "Yes" : null;
				bull.IsIsolation = responseBull.ProductionStatus.IsIsolation ? "Yes" : null;
				bull.IsLayoff = responseBull.ProductionStatus.IsLayoff ? "Yes" : null;
				bull.BarnStatus = responseBull.ProductionStatus.BarnStatus;
				bull.State = responseBull.ProductionStatus.State;
				bull.Location = responseBull.ProductionStatus.Location;
				bull.BarnCode = responseBull.ProductionStatus.BarnCode;
				bull.ResidencyDate = responseBull.ProductionStatus.ResidencyDate;
				let status: string = "";
				bull.IsEU ? (status = status + "EU-") : (status = status + "NON-EU-");
				bull.IsIsolation ? (status = status + "Isolation") : null;
				bull.IsProduction ? (status = status + "Production") : null;
				bull.IsLayoff ? (status = status + "Layoff") : null;
				bull.ProductionStatus = status;
			}
		}
	}


	/**
	 * Applies marketing group information to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing marketing group information.
	 */
	private ApplyMarketingGroupMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					if (responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "ActiveLineup")[0] &&
						responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "LowPriced")[0]) {
						bull.Active = "Active / Low Price";
					} else {
						if (responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "ActiveLineup")[0]) {
							let active = responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "ActiveLineup")[0];
							bull.Active = "Active";
							bull.ActiveDate = active.ActivateDate;
						} else if (responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "LowPriced")[0]) {
							let lp = responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "LowPriced")[0];
							bull.Active = "Low Price";
							bull.ActiveDate = lp.ActivateDate;
						}
					}
					let interim = responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "Interim")[0];
					interim ? (bull.New = "Interim") && (bull.NewDate = interim.ActivateDate) && (bull.NewExpireDate = interim.ExpireDate) : null;
					let newToLineup = responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "NewToLineup")[0];
					newToLineup ? (bull.New = "New") && (bull.NewDate = newToLineup.ActivateDate) && (bull.NewExpireDate = newToLineup.ExpireDate) : null;
					let japan = responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "Japan")[0];
					japan ? (bull.Japan = "Japan") : null;
					let saudi = responseBull.MarketingGroups.filter((mkgrp) => mkgrp.Name === "Saudi")[0];
					saudi ? (bull.Saudi = "Saudi") : null;
				}
			}
		}
	}


	/**
	 * Applies marketing rights information to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing marketing rights information.
	 */
	private ApplyMRMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					bull.MarketingRights = responseBull.MarketingGroups.filter((mktgrp) => mktgrp.Type === "MarketingRight");
				}
			}
		}
	}


	/**
	 * Applies genotype information to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing genotype information.
	 */
	private ApplyGenotypeMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					bull.BCN = responseBull.Genotypes.BCN;
					bull.BLG = responseBull.Genotypes.BLG;
					bull.KC = responseBull.Genotypes.KC;
				}
			}
		}
	}


	/**
	 * Applies USA-specific metadata to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing USA-specific metadata.
	 */
	private ApplyMetaUSAMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					bull.AIStatus = responseBull.Meta.AIStatus;
					bull.HousedInCountry = responseBull.Meta.HousedInCountry;
					bull.BirthState = responseBull.Meta.BirthState;
					bull.aAa = responseBull.Meta.aAa;
					bull.DMS = responseBull.Meta.DMS;

					bull.TopTPI = responseBull.Meta.TopTPI ? responseBull.Meta.TopTPI : "";
					bull.TopTPIGenomic = responseBull.Meta.TopTPIGenomic ? responseBull.Meta.TopTPIGenomic : "";
					bull.TopJPI = responseBull.Meta.TopJPI ? responseBull.Meta.TopJPI : "";
					bull.TopJPIGenomic = responseBull.Meta.TopJPIGenomic ? responseBull.Meta.TopJPIGenomic : "";
					bull.TopPPR = responseBull.Meta.TopPPR ? responseBull.Meta.TopPPR : "";
					bull.TopPPRGenomic = responseBull.Meta.TopPPRGenomic ? responseBull.Meta.TopPPRGenomic : "";
					bull.TopPTAM = responseBull.Meta.TopPTAM ? responseBull.Meta.TopPTAM : "";
					bull.TopPTAMGenomic = responseBull.Meta.TopPTAMGenomic ? responseBull.Meta.TopPTAMGenomic : "";
					bull.TopRedRCTPI = responseBull.Meta.TopRedRCTPI ? responseBull.Meta.TopRedRCTPI : "";
					bull.TopRedRCTPIGenomic = responseBull.Meta.TopRedRCTPIGenomic ? responseBull.Meta.TopRedRCTPIGenomic : "";

					bull.DnaStatus = responseBull.Meta.DnaStatus;
					responseBull.Meta.CrossGen ? (bull.CrossGen = responseBull.Meta.CrossGen.toString()) : null;
					bull.PercentBlack = responseBull.Meta.PercentBlack;
					bull.RHA_Ind = responseBull.Meta.RHA_Ind;
					if (typeof responseBull.Meta.CloneGen === "number") {
						bull.CloneGen = `${responseBull.Meta.CloneGen}`;
					}
					bull.RHA_Pct = responseBull.Meta.RHA_Pct;
					if (responseBull.Meta.ConvertedCanadianDaughterAvg) {
						bull.ConvertedCanadianDaughterAverage = "C";
					} else {
						bull.ConvertedCanadianDaughterAverage = "";
					}
					bull.Link = `=HYPERLINK("http://ct.wwsires.com/bull/${bull.PrimaryNaabCode.trim().replace(/^[0]*/, "")}/EN","${bull.RegName.trim().replace(/^[0]*/, "")}")`;
					if (responseBull.Meta.SexedOnly) {
						bull.SexedOnly = "Yes";
					} else if (!responseBull.Meta.SexedOnly) {
						bull.SexedOnly = "NO";
					}
				}
			}
		}
	}


	/**
	 * Applies Australia-specific metadata to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing Australia-specific metadata.
	 */
	private ApplyMetaAUSMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					bull.alt_regid = responseBull.Meta.AltRegId ? responseBull.Meta.AltRegId : "";
					bull.animal_national_id = responseBull.Meta.AnimalNationalId ? responseBull.Meta.AnimalNationalId : "";
					bull.herdbook_num_aus = responseBull.Meta.HerdbookNum ? responseBull.Meta.HerdbookNum : "";
				}
			}
		}
	}


	/**
	 * Applies Germany-specific metadata to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing Germany-specific metadata.
	 */
	private ApplyMetaDEUMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					bull.aktiv = responseBull.Meta.Aktiv ? responseBull.Meta.Aktiv : "";
					bull.verbleib = responseBull.Meta.Verbleib ? responseBull.Meta.Verbleib : "";
					bull.besitzer = responseBull.Meta.Besitzer ? responseBull.Meta.Besitzer : "";
					bull.stations_code = responseBull.Meta.StationsCode ? responseBull.Meta.StationsCode : null;
					bull.ranking_platz = responseBull.Meta.RankingPlatz ? responseBull.Meta.RankingPlatz : null;
					bull.interbull_id = responseBull.Meta.InterbullId ? responseBull.Meta.InterbullId : "";
					bull.herdbook_num = responseBull.Meta.HerdbookNum ? responseBull.Meta.HerdbookNum : null;
					bull.rang_rzg = responseBull.Meta.RangRZG ? responseBull.Meta.RangRZG : null;
					bull.informations_code = responseBull.Meta.InformationsCode ? responseBull.Meta.InformationsCode : null;
				}
			}
		}
	}


	/**
	 * Applies lactation data to bulls in the current list based on the provided BullResponse data.
	 * Refactored out of createBullList
	 * @param bullLists - An array of BullResponse objects containing lactation data.
	 */
	private ApplyLactationMixin(bullLists: BullResponse[]) {
		for (let responseBull of bullLists) {
			for (let bull of this.recall.currentBullsList.filter((currentBull) => currentBull.RegId === responseBull.RegId)) {
				if (bull) {
					if (responseBull.Lactation.Dam) {
						responseBull.Lactation.Dam.forEach((field: LactationField) => {
							switch (field.LegacyName) {
								case "dam_fat":
									bull.dam_fat_kg = field.ValueKg;
									bull.dam_fat_lb = field.ValueLb;
									break;
								case "dam_pro":
									bull.dam_pro_kg = field.ValueKg;
									bull.dam_pro_lb = field.ValueLb;
									break;
								case "dam_milk":
									bull.dam_milk_kg = field.ValueKg;
									bull.dam_milk_lb = field.ValueLb;
									break;
							}
							bull[field.LegacyName] = field.Value;
						});
					}
					if (responseBull.Lactation.MGDam) {
						responseBull.Lactation.MGDam.forEach((field: LactationField) => {
							switch (field.LegacyName) {
								case "dam_fat":
									bull.MGdam_fat_kg = field.ValueKg;
									bull.MGdam_fat_lb = field.ValueLb;
									break;
								case "dam_pro":
									bull.MGdam_pro_kg = field.ValueKg;
									bull.MGdam_pro_lb = field.ValueLb;
									break;
								case "dam_milk":
									bull.MGdam_milk_kg = field.ValueKg;
									bull.MGdam_milk_lb = field.ValueLb;
									break;
							}
							bull[`MG${field.LegacyName}`] = field.Value;
						});
					}
					if (responseBull.Lactation.DaughterAvg) {
						responseBull.Lactation.DaughterAvg.forEach((field: LactationField) => {
							switch (field.LegacyName) {
								case "fat":
									bull.fat_kg = field.ValueKg;
									bull.fat_lb = field.ValueLb;
									break;
								case "pro":
									bull.pro_kg = field.ValueKg;
									bull.pro_lb = field.ValueLb;
									break;
								case "milk":
									bull.milk_kg = field.ValueKg;
									bull.milk_lb = field.ValueLb;
									break;
							}
							bull[field.LegacyName] = field.Value;
						});
					}
				}
			}
		}
	}


	/**
	 * Asynchronously applies pricing information to bulls in the current list based on retrieved product data.
	 * Refactored out of createBullList
	 * @returns A Promise that resolves once pricing information is applied.
	 */
	private async ApplyPricesMixin() {
		try {
			let products: Price[] = await this.getPrices();
			if (products && products.length > 0) {
				for (let product of products) {
					let bull = this.recall.currentBullsList.filter((currentBull) => currentBull.Id === product.Id)[0];

					if (bull && product.ProductLines.length > 0) {
						let conventional = product.ProductLines.filter((prod) => {
							return prod.Category ? prod.Category.includes("Conventional") : null;
						});
						let conventionalToUse = conventional.length > 1 ? this.lowestDisplayProductLine(conventional) : conventional[0];

						let sexed = product.ProductLines.filter((prod) => {
							return prod.Category ? prod.Category.includes("Sexed") && !prod.Category.includes("4M") && !prod.Category.includes("Male") : null;
						});
						let sexedToUse = sexed.length > 1 ? this.lowestDisplayProductLine(sexed) : sexed[0];

						let sexed4m = product.ProductLines.filter((prod) => {
							return prod.Category ? prod.Category.includes("4M") && !prod.Category.includes("Male") : null;
						});
						let sexed4mToUse = sexed4m.length > 1 ? this.lowestDisplayProductLine(sexed4m) : sexed4m[0];

						let sexedMale = product.ProductLines.filter((prod) => {
							return prod.Category ? prod.Category.includes("SexedMale") : null;
						});
						let sexedMaleToUse = sexedMale.length > 1 ? this.lowestDisplayProductLine(sexedMale) : sexedMale[0];

						let sexedMale4M = product.ProductLines.filter((prod) => {
							return prod.Category ? prod.Category.includes("SexedMale4M") : null;
						});
						let sexedMale4MToUse = sexedMale4M.length > 1 ? this.lowestDisplayProductLine(sexedMale4M) : sexedMale4M[0];

						this.setBullPriceValues(bull, conventionalToUse, sexedToUse, sexed4mToUse, sexedMaleToUse, sexedMale4MToUse);
					}
				}
				//something going on that's causing an error in the client. Investigate
				if (this.listOptions.productPerRow) {
					this.recall.currentBullsList.sort((a, b) => a.ShortName.localeCompare(b.ShortName));
				}
			}
		} catch (error) {
			this.alert.alerts.next({ message: `Something went wrong contact IT: ${error.message}!` });
		}
	}
}
