import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "mixinChipFormat" })
export class MixinChipFormatPipe implements PipeTransform {
	transform(value: any): string {
		if (value.includes("_DEU")) {
			if (value === "Total Breeding Values_DEU") {
				return value.replace(/^([A-Za-z]+\s{1}[A-Za-z]+\s{1}[A-Za-z]+)_([DEU]{3})/, "$1");
			} else {
				return value.replace(/^([A-Za-z]*)_([a-zA-Z]{3})/, "$1");
			}
		} else if(value.includes("_AUS")) {
			return value.replace(/^([A-Za-z]*)_([a-zA-Z]{3})/, "$1");
		} else if (value.includes("_GBR")) {
			return value.replace(/_+/g, " ");
		} else {
			return value;
		}
	}
}
