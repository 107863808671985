import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "../../utils/alerts/alert.service";
import { BullForm } from "./bull-form.model";
import { ProgressBarService } from "../../utils/loading/progress-bar.service";
import { Subject } from "rxjs";
import { NgForage } from "ngforage";
import { API_URL, ENDPOINT } from "../../../environments/environment";
import { AuthService } from "../../auth/auth.service";

export interface NaabCodeIndexBull {
	ShortName?: string;
	RegId?: string;
	Breed?: string;
	BreedGroup?: string;
	Id?: string;
	displayNaabCode?: string;
	NaabCodes?: string[];
	PrimaryNaabCode?: string;
	Priority?: number;
	Identifier?: string;
}

export interface NaabIndexStorage {
	naabIndex: NaabCodeIndexBull[];
	timeSet: number;
}
@Injectable({
	providedIn: "root",
})
export class BullFormDataService {
	public naabCodeIndex: NaabCodeIndexBull[];
	changesSaved: Subject<boolean> = new Subject();
	openDocumentModal: Subject<string> = new Subject();
	saveChanges: Subject<boolean> = new Subject();
	openSaveChangesModal: Subject<boolean> = new Subject();
	constructor(private http: HttpClient, private alert: AlertService, private progress: ProgressBarService, private ngf: NgForage, private authService: AuthService) {}

	async getNaabCodeIndex() {
		let user = await this.authService.getUserFromStorage();

		let naabCodeIndex: NaabIndexStorage = await this.checkForNaabCodeIndex();
		if (naabCodeIndex) {
			if (naabCodeIndex && naabCodeIndex.naabIndex) {
				this.naabCodeIndex = naabCodeIndex.naabIndex;
				if (naabCodeIndex.timeSet) {
					let timeLastSet = naabCodeIndex.timeSet;
					let timeDifference = Date.now() - timeLastSet;
					if (timeDifference > 43200000) {
						this.naabCodeIndex = await this.callNaabCodeIndex();
						this.ngf.setItem("NaabCodeIndex", { naabIndex: this.naabCodeIndex, timeSet: Date.now() });
					} else {
						this.naabCodeIndex = naabCodeIndex.naabIndex;
					}
				} else {
					this.naabCodeIndex = await this.callNaabCodeIndex();
					this.ngf.setItem("NaabCodeIndex", { naabIndex: this.naabCodeIndex, timeSet: Date.now() });
				}
			} else {
				this.naabCodeIndex = await this.callNaabCodeIndex();

				this.ngf.setItem("NaabCodeIndex", { naabIndex: this.naabCodeIndex, timeSet: Date.now() });
			}
		} else {
			this.naabCodeIndex = await this.callNaabCodeIndex();

			this.ngf.setItem("NaabCodeIndex", { naabIndex: this.naabCodeIndex, timeSet: Date.now() });
		}
	}


	/**
	 * Fetches the animal record and all the related bull form data, except proof data, from the API.
	 * Future TODO: make this not await, the bull form is "almost" flexible enough to handle model assignement async.
	 * @param animalId the animal's identifier (Global Id, Reg Id, Naab Code).
	 * @returns A BullForm object with the requested bull's data.
	 */
	async getBullFormData(animalId: string, body?: object): Promise<BullForm> {
		try {
			this.progress.loading.next({ load: true, source: "get bull form data start" });

			let bullFormData: BullForm;

			await this.http
				.post(API_URL + ENDPOINT.animalDetail + animalId, body)
				.toPromise()
				.then(async (response: BullForm) => {
					bullFormData = response;
					return await this.FetchProofData(bullFormData.Id, new Date().toISOString().split('T')[0]).then(data => {
						bullFormData.Proof = data.Proof;
					});
				});

			this.progress.loading.next({ load: false, source: "get bull form data end" });
			return bullFormData;
		} catch (error) {
			setTimeout(() => {
				this.progress.loading.next({ load: false, source: "bull form data" });
			}, 1500);
			this.alert.alerts.next({ message: `Something Went Wrong Opening The Bull Form Contact IT: ${error.message}` });
		}
	}


	/**
	 * For the bull form, fetches an animal's product lines independent of the initial fetch.
	 * Eventually, the initial bull form promise won't require an await, that's twhen this method will be ready.
	 * @param animalId the animal's identifier (Global Id, Reg Id, Naab Code).
	 * @returns a promise containing the the animal's product lines.
	 */
	private async FetchProductLineData(animalId: string): Promise<BullForm> {
		try {
			// this.progress.loading.next({ load: true, source: "get bull form data start" });
			return this.http
				.post(API_URL.concat(ENDPOINT.products), { bullIds: [animalId]})
				.toPromise()
				.then((response: BullForm) => {
					let bullFormData: BullForm = response;
					// this.progress.loading.next({ load: false, source: "get bull form data end" });
					return bullFormData;
				});
		} catch (error) {
			setTimeout(() => {
				this.progress.loading.next({ load: false, source: "bull form data" });
			}, 1500);
			this.alert.alerts.next({ message: `Something Went Wrong Fetching Product Line Data, Contact IT: ${error.message}` });
		}
	}


	/**
	 * For the bull form, fetches an animal's proof data and only its proof data.
	 * @param animalId the animal's identifier (Global Id, Reg Id, Naab Code).
	 * @param proofDate the requested proof date (default is current)
	 * @returns a promise containing the the animal's proof data for the requested proof date.
	 */
	private async FetchProofData(animalId: string, proofDate: string): Promise<BullForm> {
		try {
			// this.progress.loading.next({ load: true, source: "get bull form data start" });
			return this.http
				.post(API_URL.concat(ENDPOINT.animalProof, animalId), { proofDate: proofDate })
				.toPromise()
				.then((response: BullForm) => {
					let bullFormData: BullForm = response;
					// this.progress.loading.next({ load: false, source: "get bull form data end" });
					return bullFormData;
				});
		} catch (error) {
			setTimeout(() => {
				this.progress.loading.next({ load: false, source: "bull form data" });
			}, 1500);
			this.alert.alerts.next({ message: `Something Went Wrong Fetching Proof Data, Contact IT: ${error.message}` });
		}
	}


	async editBullFormData(animalObject: BullForm) {
		try {
			this.progress.loading.next({ load: true, source: "bull form edit data start" });

			let body = {
				animalData: animalObject,
			};
			let bullFormData: BullForm = {};

			await this.http
				.post(API_URL + ENDPOINT.animalEdit, body)
				.toPromise()
				.then((response: BullForm) => {
					bullFormData = response;
				});

			this.progress.loading.next({ load: false, source: "bull form edit data end" });
			return bullFormData;
		} catch (error) {
			setTimeout(() => {
				this.progress.loading.next({ load: false, source: "bull form edit end" });
			}, 1500);
			this.alert.alerts.next({ message: `Something Went Wrong Editing The Bull Form Contact IT: ${error.message}` });
		}
	}

	async callNaabCodeIndex() {
		return await this.http.post<NaabCodeIndexBull[]>(API_URL + ENDPOINT.meta, { metaListName: "NaabCodeIndex" }).toPromise();
	}

	async checkForNaabCodeIndex() {
		let naabIndex: NaabIndexStorage = await this.ngf.getItem("NaabCodeIndex");
		return naabIndex;
	}
}
