/** The intent is to eventually make this metadata driven **/
export const GvcType = {

	/**
	 * Represents the Lineup and only the Lineup in the GVC view.
	 */
	ActiveLineup: {
		Name: "ActiveLineup",
		Title: "GVC Active Lineup",
		Description: "GVC Formatted Product-Per-Row Active Lineup",
		Hidden: false
	},

	/**
	 * Represents the A, F, and G AI status bulls/products in addition to the usual Lineup in the GVC view.
	 */
	Industry: {
		Name: "Industry",
		Title: "GVC All Industry",
		Description: "GVC Formatted Product-Per-Row Industry Status of A,F,G",
		Hidden: false
	},

	/**
	 * Represents the Germany GVC view, which is the same as the usual Lineup but with a couple of German traits swapped in.
	 */
	Germany: {
		Name: "Germany",
		Title: "GVC Germany Lineup",
		Description: "GVC Formatted Product-Per-Row Active Lineup with Germany Traits",
		Hidden: false
	}
}